import {SelectInput, TextInput} from '@sabre/spark-react-core';
import styles from './DefaultTableInputProps.module.css';
import {default as React, FocusEventHandler} from 'react';
import {cssClasses} from '../../../../../../common/helpers/browser/cssClasses';

export type DefaultTableInputProps = {
    type: 'text' | 'select';
    name: string;
    onChange?: (event: React.ChangeEvent<HTMLElement>, value: string) => void;
    onBlur?: FocusEventHandler;
    onBlurCapture?: FocusEventHandler;
    options?: {
        value: string;
        label: string;
    }[];
    value?: string;
    defaultValue?: string;
    label?: string;
    ariaLabel?: string;
    isTypeahead?: boolean;
    typeaheadFormat?: string;
    placeHolder?: string;
    status?: "success" | "warning" | "error" | "info";
    statusMessage?: string;
    className?: string;
    disabled?: boolean;
};

export default function DefaultTableInput(props: DefaultTableInputProps) {
    if (props.type === 'text') {
        return (
            <TextInput
                className={props.className || cssClasses(
                    'aat-table-input',
                    styles.TextInput
                )}
                name={props.name}
                onBlurCapture={props.onBlurCapture}
                defaultValue={props.defaultValue}
                value={props.value}
                onChange={props.onChange}
                onBlur={props.onBlur}
                label={props.label}
                status={props.status}
                statusMessage={props.statusMessage}
                disabled={props.disabled}
                aria-label={props.ariaLabel}
                isTypeahead={props.isTypeahead}
                typeaheadFormat={props.typeaheadFormat}
                placeHolder={props.placeHolder ?? ``} // required for typeahead
            />
        );
    } else {
        return (
            <SelectInput
                className={props.className || cssClasses(
                    'aat-table-input',
                    styles.SelectInput
                )}
                name={props.name}
                onChange={props.onChange}
                onBlur={props.onBlur}
                value={props.value}
                label={props.label}
                options={props.options}
                status={props.status}
                statusMessage={props.statusMessage}
                disabled={props.disabled}
                defaultValue={props.defaultValue}
                ariaLabel={props.ariaLabel}
            />
        );
    }
}
