import {RxMiscData} from '../../../model/db/types/RxMiscData';
import {useControllers} from './useControllers';
import {useMemo} from 'react';

export type MiscDataObject = RxMiscData['data'] | null | undefined;

type MiscDataHelpers = [
    MiscDataObject,
    (data: RxMiscData['data']) => Promise<void>,
    () => Promise<void>
];

export function useMiscData(id: string): MiscDataHelpers {
    const settings = useControllers().settings;

    const setData = useMemo(() => function setData(data: RxMiscData['data']): Promise<void> {
        return settings.setMiscData(id, data);
    }, []);

    const deleteData = useMemo(() => function deleteData(): Promise<void> {
        return settings.deleteMiscData(id);
    }, []);

    const data$ = useMemo(() => {
        return settings.getMiscData$(id);
    }, []);

    const data = data$.asState();

    return [data, setData, deleteData];
}
