import {WitcherStep} from "./WitcherStep";
import {WitcherValidationResult} from "./WitcherValidationResult";
import {BehaviorSubject, Observable, switchMap} from "rxjs";
import {DataSource} from "../decorators/rxdb/DataSource";
import {WitcherValidationDetails} from "./WitcherValidationDetails";
import {isWitcherValidationError} from "./isWitcherValidationError";
import {asString} from "../helpers/converters/asString";
import {isInstanceOf} from "../types/guards/isInstanceOf";
import {ValidationError} from "yup";
import {isArrayOf} from "../types/guards/isArrayOf";
import {isDefined} from "../types/guards/isDefined";
import {asFakeRecord} from "../helpers/converters/asFakeRecord";
import {tAsString} from "../helpers/react/text/tAsString";
import {Once} from "../decorators/methods/Once";
import {NotifiedActionConfig} from "../../controllers/dialog/NotifiedActionConfig";
import {MessageStatus} from "@sabre/spark-react-core/types";
import {DialogController} from "../../controllers/dialog/DialogController";

export class Witcher<TData> {
    static FORM_RESULT_NAME = '';

    #currentStep: string;
    #data$: BehaviorSubject<Partial<TData>>;
    #isStrictValidation: boolean = false;

    constructor(
        data: Partial<TData>,
        private steps: WitcherStep<Partial<TData>>[] = [],
        private validator?: (data: Partial<TData>) => Promise<void>
    ) {
        this.#currentStep = steps[0]?.name ?? Witcher.FORM_RESULT_NAME;
        this.#data$ = new BehaviorSubject<Partial<TData>>(data);
    }

    getCurrentStep(): WitcherStep<TData> {
        const step = this.steps.find(it => it.name === this.#currentStep);

        if (!step) {
            throw new Error('Witcher misconfigured!')
        }

        return step;
    }

    getCurrentStepIndex(offset: number = 0): number {
        return this.steps.findIndex(it => it.name === this.#currentStep) + offset;
    }

    getAllSteps(): WitcherStep<TData>[] {
        return this.steps;
    }

    @DataSource()
    validate$(): Observable<WitcherValidationResult> {
        return this.#data$
            .pipe(
                switchMap(data => {
                    return this.#validate(data);
                })
            )
    }

    @DataSource()
    getData$(): Observable<Partial<TData>> {
        return this.#data$;
    }

    setData(data: Partial<TData>): void {
        this.#data$.next(data);
    }

    updateData(data: Partial<TData>): void {
        this.setData({
            ...this.#data$.value,
            ...data
        })
    }

    async validate(): Promise<WitcherValidationResult> {
        this.setStrictValidation(true);
        const result = await this.#validate(this.#data$.value);
        this.setStrictValidation(false);
        return result;
    }

    setStrictValidation(isStrictValidation: boolean = true): void {
        this.#isStrictValidation = isStrictValidation;
        this.setData(this.#data$.value);
    }

    async takeNotifiedAction(dialog: DialogController, configFn: (data: Partial<TData>, validationResult: WitcherValidationResult) => NotifiedActionConfig): Promise<void> {
        const validationResult = await this.validate();

        if (validationResult?.message.step) {
            await dialog.showNotification({
                status: MessageStatus.ERROR,
                content: validationResult?.message.step,
                title: tAsString('ERROR')
            })
        }

        if (validationResult?.success) {
            await dialog.takeNotifiedAction(configFn(this.#data$.value, validationResult));
        } else {
            this.setStrictValidation(true);
        }
    }

    async #validate(data: Partial<TData>): Promise<WitcherValidationResult> {
        async function stepValidator(it: Partial<WitcherStep<TData>>): Promise<WitcherValidationDetails> {
            const stepName = it.name
                ?? Witcher.FORM_RESULT_NAME;

            try {
                await it.validator?.(data);
            } catch (e) {
                return {
                    success: false,
                    stepName,
                    errors: isArrayOf(isWitcherValidationError)(e)
                        ? e.map(it => {
                            return {
                                ...it,
                                stepName
                            }
                        })
                        : [
                            isInstanceOf(ValidationError)(e)
                                ? {
                                    message: e.message,
                                    field: e.path,
                                    stepName
                                }
                                : isWitcherValidationError(e)
                                    ? {
                                        ...e,
                                        stepName
                                    }
                                    : {
                                        message: asString(e),
                                        stepName
                                    }
                        ]
                }
            }

            return {
                success: true,
                stepName
            }
        }

        const formResult = await stepValidator({validator: this.validator});
        const stepResults = await Promise.all(this.steps.map(stepValidator));

        const details = [
            formResult,
            ...stepResults
        ];

        return {
            success: details.every(it => it.success),
            details,
            fieldErrors: this.#getFieldErrors(details, data),
            stepErrors: this.#getStepErrors(details, data),
            message: {
                full: this.#getFullErrorMessage(details),
                step: this.#getStepErrorMessage(details)
            }
        };
    }

    #getFieldErrors(details: WitcherValidationDetails[], data: Partial<TData>): Record<string, string> {
        return Object.fromEntries(
            details
                .flatMap(it => it.errors)
                .filter(it => !!it?.field)
                .filter(it => isDefined(asFakeRecord(data)[it?.field!]) || this.#isStrictValidation)
                .map(it => [it?.field, it?.message])
        )
    }

    #getStepErrors(details: WitcherValidationDetails[], data: Partial<TData>): Record<string, string[]> {
        return Object.fromEntries(
            details
                .map(it => [
                    it.stepName,
                    it.errors
                        ?.filter(it => !it.field)
                        .map(it => it.message)
                    ?? []
                ])
        );
    }

    #getFullErrorMessage(details: WitcherValidationDetails[]) {
        const stepLabels = this.getStepLabels();

        return details
            .flatMap(it => it.errors || [])
            .map(it => {
                const translationKey = it.field
                    ? 'X_STEP_AND_FIELD_VALIDATION_ERROR'
                    : 'X_STEP_VALIDATION_ERROR';

                const prefix = it.stepName === Witcher.FORM_RESULT_NAME
                    ? ''
                    : `${tAsString(translationKey, {
                        step: stepLabels[it.stepName || Witcher.FORM_RESULT_NAME]?.toTitleCase(),
                        field: it.field
                    })}: `;

                return `${prefix}${it.message}`;
            })
            .join('. ')
            .replace(/\.{2}/, '.')
    }

    #getStepErrorMessage(details: WitcherValidationDetails[]) {
        const stepLabels = this.getStepLabels();
        const fieldErrors: string[] = [];

        return details
            .flatMap(it => it.errors || [])
            .filter(it => {
                if (it.field) {
                    if (it.stepName !== this.#currentStep) {
                        fieldErrors.push(asString(it.stepName));
                    }

                    return false;
                }

                return true;
            })
            .map(it => {
                const prefix = (it.stepName === Witcher.FORM_RESULT_NAME || it.stepName === this.#currentStep)
                    ? ''
                    : `${tAsString('X_STEP_VALIDATION_ERROR', {
                        step: stepLabels[it.stepName || Witcher.FORM_RESULT_NAME]?.toTitleCase()
                    })}: `;

                const suffix = fieldErrors.length
                    ? `. ${tAsString('ERRORS_IN_FORM_FIELDS_IN_STEP_X', {
                        stepName: fieldErrors.uniq().join(', '),
                        count: fieldErrors.uniq().length
                    })}`
                    : ``;

                return `${prefix}${it.message}${suffix}`;
            })
            .join('. ')
            .replace(/\.{2}/, '.')
    }

    @Once()
    private getStepLabels() {
        return Object.fromEntries(
            this.steps.map(it => [it.name, it.label])
        );
    }
}
