import {Config_core} from '../common/core/entry-point/Config_core';
import {HttpService} from '../core/services/http/HttpService';
import {GroupsService} from '../core/services/groups/GroupsService';
import {AatApiService} from '../core/rest-client/aat';
import {CookiesService} from '../core/services/cookies/CookiesService';
import {SessionStorageService} from '../core/services/sessionStorage/SessionStorageService';
import {DatabaseService} from '../core/services/database/DatabaseService';
import {ConfigurationsService} from '../core/services/configurations/ConfigurationsService';
import {RedAppsService} from '../core/services/redApps/RedAppsService';
import {RolesService} from "../core/services/roles/RolesService";

export const services: Partial<Config_core['services']> = {
    http: new HttpService(),
    groups: new GroupsService(),
    configurations: new ConfigurationsService(),
    aat: new AatApiService(),
    cookies: new CookiesService(),
    database: new DatabaseService(),
    sessionStorage: new SessionStorageService(),
    redApps: new RedAppsService(),
    roles: new RolesService()
};
