import {ArrayApiExtension} from '../../types/api-extensions/ArrayApiExtension';

declare global {
    interface Array<T> extends ArrayApiExtension<T> {
    }
}

// eslint-disable-next-line no-extend-native
Array.prototype.tap = function <T>(
    this: T[],
    fn: (self: T[]) => void
): T[] {
    fn(this);
    return this;
};

// eslint-disable-next-line no-extend-native
Array.prototype.thru = function <T, R>(
    this: T[],
    fn: (self: T[]) => R
): R {
    return fn(this);
};

// eslint-disable-next-line no-extend-native
Array.prototype.first = function <T>(
    this: T[]
): T | undefined {
    return this[0];
};

// eslint-disable-next-line no-extend-native
Array.prototype.last = function <T>(
    this: T[]
): T | undefined {
    return this.length > 0
        ? this[this.length - 1]
        : undefined;
};

// eslint-disable-next-line no-extend-native
Array.prototype.head = function <T>(
    this: T[]
): T[] {
    return this.slice(1);
};

// eslint-disable-next-line no-extend-native
Array.prototype.tail = function <T>(
    this: T[]
): T[] {
    return this.length > 1
        ? this.slice(1, this.length - 1)
        : [];
};

// eslint-disable-next-line no-extend-native
Array.prototype.uniq = function <T>(
    this: T[],
    keyFn?: (o: T) => unknown
): T[] {
    const keys = keyFn
        ? this.map(keyFn)
        : this;

    return this.filter((it, idx) => {
        const key = keyFn
            ? keyFn(it)
            : it;

        return keys.indexOf(key) === idx;
    });
};

// eslint-disable-next-line no-extend-native
Array.prototype.mapAsync = function <T, U>(
    this: T[],
    callbackFn: (value: T, index: number, array: T[]) => U | Promise<U>,
    thisArg?: any
): Promise<U[]> {
    return Promise.all(this.map(callbackFn, thisArg));
};
