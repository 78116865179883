import {KEY} from '../id/KEY';
import {FunctionComponent} from 'react';
import {cssClasses} from '../browser/cssClasses';
import {isFunction} from '../../types/guards/isFunction';
import {isPlainObject} from '../../types/guards/isPlainObject';

// DO NOT TOUCH

type Config<TProps> = Partial<TProps | Record<keyof TProps, unknown>>;

export function renderMany<TProps>(
    _component: FunctionComponent<TProps>,
    configs: Config<TProps>[] = [],
    defaults: TProps | ((config: Config<TProps>, idx: number) => TProps),
    overwrites?: Partial<TProps> | ((config: Config<TProps>, idx: number, props: TProps) => Partial<TProps>)
): JSX.Element {
    return <>
        {configs.map((config, idx) => {
            let props: TProps = {
                key: KEY(config),
                ...asProps<TProps>(isFunction(defaults) ? defaults(config, idx) : defaults),
                ...(asProps(config) as TProps),
                className: cssClasses((defaults as any)?.className, (config as any).className)
            };

            props = {
                ...props,
                ...getAriaAttributes(props),
                ...asProps<TProps>(isFunction(overwrites) ? overwrites(config, idx, props) : overwrites)
            };

            return <_component {...props as any} />;
        })}
    </>;
}

function asProps<TProps>(o?: Config<TProps>): Config<TProps> {
    return isPlainObject(o) ? o : {};
}

function getAriaAttributes(props: any = {}): any {
    const result: any = {};

    for (let name in props) {
        if (name.startsWith('aria')) {
            const camelName = name.toCamelCase();
            const kebabName = name.toKebabCase();

            result[camelName] = props[camelName] ?? props[kebabName];
            result[kebabName] = props[camelName] ?? props[kebabName];
        }
    }

    return result;
}
