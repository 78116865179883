import {StateInfo} from './StateInfo';
import {useMemo, useState} from 'react';
import {StateStatus} from './StateStatus';
import {ID} from '../../../id/ID';

const PROMISE_ID = Symbol('usePromisedState.PROMISE_ID');

interface Promise_private {
    [PROMISE_ID]?: number;
}

const pendingStateInfo: StateInfo<any> = {
    status: StateStatus.PENDING
};

export function usePromisedState<T>(
    promise: Promise<T>
): StateInfo<T> {
    const [stateInfo, setStateInfo] = useState<StateInfo<T>>(pendingStateInfo);

    const privatePromise = promise as Promise_private;
    privatePromise[PROMISE_ID] ??= ID();

    useMemo(() => {
        setStateInfo(pendingStateInfo);

        promise
            .then(it => {
                setStateInfo({
                    status: StateStatus.SUCCESS,
                    value: it
                });
            })
            .catch(err => {
                setStateInfo({
                    status: StateStatus.ERROR,
                    error: err
                });
            });
    }, [privatePromise[PROMISE_ID]]);

    return stateInfo;
}
