/* tslint:disable */
/* eslint-disable */
/**
 * SR AAT specification
 * Description
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  AuthorizationStatus,
  GroupConfigurationRequest,
  GroupParticipantsRequest,
  GroupParticipantsResponse,
  GroupRequest,
  GroupResponse,
  GroupSettingsIdsReponse,
  GroupSettingsResponse,
  GroupsAssigneesResponse,
  GroupsResponse,
  Status,
  UpdateGroupRequest,
} from '../models';
import {
    AuthorizationStatusFromJSON,
    AuthorizationStatusToJSON,
    GroupConfigurationRequestFromJSON,
    GroupConfigurationRequestToJSON,
    GroupParticipantsRequestFromJSON,
    GroupParticipantsRequestToJSON,
    GroupParticipantsResponseFromJSON,
    GroupParticipantsResponseToJSON,
    GroupRequestFromJSON,
    GroupRequestToJSON,
    GroupResponseFromJSON,
    GroupResponseToJSON,
    GroupSettingsIdsReponseFromJSON,
    GroupSettingsIdsReponseToJSON,
    GroupSettingsResponseFromJSON,
    GroupSettingsResponseToJSON,
    GroupsAssigneesResponseFromJSON,
    GroupsAssigneesResponseToJSON,
    GroupsResponseFromJSON,
    GroupsResponseToJSON,
    StatusFromJSON,
    StatusToJSON,
    UpdateGroupRequestFromJSON,
    UpdateGroupRequestToJSON,
} from '../models';

export interface CreateGroupRequest {
    groupRequest: GroupRequest;
    authorization?: string;
    onBehalfOf?: string;
}

export interface DeleteGroupsByIdsRequest {
    requestBody: Array<number>;
    authorization?: string;
    onBehalfOf?: string;
}

export interface GetGroupAssigneesByIdRequest {
    id: number;
    authorization?: string;
    onBehalfOf?: string;
}

export interface GetGroupConfigurationByIdsRequest {
    groupConfigurationRequest: GroupConfigurationRequest;
    authorization?: string;
    onBehalfOf?: string;
}

export interface GetGroupConfigurationByIds0Request {
    id: number;
    groupConfigurationRequest: GroupConfigurationRequest;
    authorization?: string;
    onBehalfOf?: string;
}

export interface GetGroupsRequest {
    authorization?: string;
    onBehalfOf?: string;
}

export interface GetParticipantsRequest {
    groupParticipantsRequest: GroupParticipantsRequest;
    authorization?: string;
    onBehalfOf?: string;
}

export interface UpdateGroupOperationRequest {
    id: number;
    updateGroupRequest: UpdateGroupRequest;
    authorization?: string;
    onBehalfOf?: string;
}

/**
 * 
 */
export class GroupsApi extends runtime.BaseAPI {

    /**
     * Creates a group.
     */
    async createGroupRaw(requestParameters: CreateGroupRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GroupResponse>> {
        if (requestParameters.groupRequest === null || requestParameters.groupRequest === undefined) {
            throw new runtime.RequiredError('groupRequest','Required parameter requestParameters.groupRequest was null or undefined when calling createGroup.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (requestParameters.onBehalfOf !== undefined && requestParameters.onBehalfOf !== null) {
            headerParameters['On-Behalf-Of'] = String(requestParameters.onBehalfOf);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/groups`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: GroupRequestToJSON(requestParameters.groupRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GroupResponseFromJSON(jsonValue));
    }

    /**
     * Creates a group.
     */
    async createGroup(requestParameters: CreateGroupRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GroupResponse> {
        const response = await this.createGroupRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Delete multiple groups
     * Delete multiple groups
     */
    async deleteGroupsByIdsRaw(requestParameters: DeleteGroupsByIdsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Status>> {
        if (requestParameters.requestBody === null || requestParameters.requestBody === undefined) {
            throw new runtime.RequiredError('requestBody','Required parameter requestParameters.requestBody was null or undefined when calling deleteGroupsByIds.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (requestParameters.onBehalfOf !== undefined && requestParameters.onBehalfOf !== null) {
            headerParameters['On-Behalf-Of'] = String(requestParameters.onBehalfOf);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/groups:batchDelete`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.requestBody,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => StatusFromJSON(jsonValue));
    }

    /**
     * Delete multiple groups
     * Delete multiple groups
     */
    async deleteGroupsByIds(requestParameters: DeleteGroupsByIdsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Status> {
        const response = await this.deleteGroupsByIdsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * get group agents
     * get group agents
     */
    async getGroupAssigneesByIdRaw(requestParameters: GetGroupAssigneesByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GroupsAssigneesResponse>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getGroupAssigneesById.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (requestParameters.onBehalfOf !== undefined && requestParameters.onBehalfOf !== null) {
            headerParameters['On-Behalf-Of'] = String(requestParameters.onBehalfOf);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/groups/{id}/assignees`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GroupsAssigneesResponseFromJSON(jsonValue));
    }

    /**
     * get group agents
     * get group agents
     */
    async getGroupAssigneesById(requestParameters: GetGroupAssigneesByIdRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GroupsAssigneesResponse> {
        const response = await this.getGroupAssigneesByIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * get group configuration / settings based on cm group ids
     * get group configuration / settings based on cm group ids
     */
    async getGroupConfigurationByIdsRaw(requestParameters: GetGroupConfigurationByIdsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GroupSettingsResponse>> {
        if (requestParameters.groupConfigurationRequest === null || requestParameters.groupConfigurationRequest === undefined) {
            throw new runtime.RequiredError('groupConfigurationRequest','Required parameter requestParameters.groupConfigurationRequest was null or undefined when calling getGroupConfigurationByIds.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (requestParameters.onBehalfOf !== undefined && requestParameters.onBehalfOf !== null) {
            headerParameters['On-Behalf-Of'] = String(requestParameters.onBehalfOf);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/groups:configuration`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: GroupConfigurationRequestToJSON(requestParameters.groupConfigurationRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GroupSettingsResponseFromJSON(jsonValue));
    }

    /**
     * get group configuration / settings based on cm group ids
     * get group configuration / settings based on cm group ids
     */
    async getGroupConfigurationByIds(requestParameters: GetGroupConfigurationByIdsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GroupSettingsResponse> {
        const response = await this.getGroupConfigurationByIdsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * get group configuration / settings based on cm group ids
     * get cm group configuration members based
     */
    async getGroupConfigurationByIds_1Raw(requestParameters: GetGroupConfigurationByIds0Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GroupSettingsIdsReponse>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getGroupConfigurationByIds_1.');
        }

        if (requestParameters.groupConfigurationRequest === null || requestParameters.groupConfigurationRequest === undefined) {
            throw new runtime.RequiredError('groupConfigurationRequest','Required parameter requestParameters.groupConfigurationRequest was null or undefined when calling getGroupConfigurationByIds_1.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (requestParameters.onBehalfOf !== undefined && requestParameters.onBehalfOf !== null) {
            headerParameters['On-Behalf-Of'] = String(requestParameters.onBehalfOf);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/groups:configuration/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: GroupConfigurationRequestToJSON(requestParameters.groupConfigurationRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GroupSettingsIdsReponseFromJSON(jsonValue));
    }

    /**
     * get group configuration / settings based on cm group ids
     * get cm group configuration members based
     */
    async getGroupConfigurationByIds_1(requestParameters: GetGroupConfigurationByIds0Request, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GroupSettingsIdsReponse> {
        const response = await this.getGroupConfigurationByIds_1Raw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Returns all groups related to given user
     * Groups
     */
    async getGroupsRaw(requestParameters: GetGroupsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GroupsResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (requestParameters.onBehalfOf !== undefined && requestParameters.onBehalfOf !== null) {
            headerParameters['On-Behalf-Of'] = String(requestParameters.onBehalfOf);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/groups`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GroupsResponseFromJSON(jsonValue));
    }

    /**
     * Returns all groups related to given user
     * Groups
     */
    async getGroups(requestParameters: GetGroupsRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GroupsResponse> {
        const response = await this.getGroupsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * get participants for a group.
     */
    async getParticipantsRaw(requestParameters: GetParticipantsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GroupParticipantsResponse>> {
        if (requestParameters.groupParticipantsRequest === null || requestParameters.groupParticipantsRequest === undefined) {
            throw new runtime.RequiredError('groupParticipantsRequest','Required parameter requestParameters.groupParticipantsRequest was null or undefined when calling getParticipants.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (requestParameters.onBehalfOf !== undefined && requestParameters.onBehalfOf !== null) {
            headerParameters['On-Behalf-Of'] = String(requestParameters.onBehalfOf);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/groups/participants`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: GroupParticipantsRequestToJSON(requestParameters.groupParticipantsRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GroupParticipantsResponseFromJSON(jsonValue));
    }

    /**
     * get participants for a group.
     */
    async getParticipants(requestParameters: GetParticipantsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GroupParticipantsResponse> {
        const response = await this.getParticipantsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update a group.
     */
    async updateGroupRaw(requestParameters: UpdateGroupOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GroupResponse>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateGroup.');
        }

        if (requestParameters.updateGroupRequest === null || requestParameters.updateGroupRequest === undefined) {
            throw new runtime.RequiredError('updateGroupRequest','Required parameter requestParameters.updateGroupRequest was null or undefined when calling updateGroup.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (requestParameters.onBehalfOf !== undefined && requestParameters.onBehalfOf !== null) {
            headerParameters['On-Behalf-Of'] = String(requestParameters.onBehalfOf);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/groups/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateGroupRequestToJSON(requestParameters.updateGroupRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GroupResponseFromJSON(jsonValue));
    }

    /**
     * Update a group.
     */
    async updateGroup(requestParameters: UpdateGroupOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GroupResponse> {
        const response = await this.updateGroupRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
