import './common/global';
import {main} from './config/ENV';

main().then(() => {
    const root = document.getElementById('root')!;
    const rootPreloader = document.getElementById('root-preloader')!;

    root.style.opacity = '1';
    rootPreloader.style.opacity = '0';
    rootPreloader.className = '';

    setTimeout(() => {
        rootPreloader.remove();
    }, 5000);
});
