import {BehaviorSubject, Observable} from 'rxjs';
import {StateInfo} from './StateInfo';
import {StateStatus} from './StateStatus';
import {useObservableEagerState, useObservableState} from 'observable-hooks';

const OBSERVABLE: unique symbol = Symbol('Observable.OBSERVABLE');

interface Observable_private<T> extends Observable<T> {
    [OBSERVABLE]: Observable<StateInfo<T>>;
}

export function useObservedState<T>(
    $: Observable<T>
): StateInfo<T> {
    const self = $ as Observable_private<T>;

    self[OBSERVABLE] ??= $.pipe(
        ($): Observable<StateInfo<T>> => {
            return new Observable((subscriber) => {
                return $.subscribe({
                    next(it) {
                        subscriber.next({
                            status: StateStatus.SUCCESS,
                            value: it
                        });
                    },
                    complete() {
                        subscriber.next({
                            status: StateStatus.COMPLETE
                        });
                    },
                    error(err) {
                        console.warn(err);

                        subscriber.next({
                            status: StateStatus.ERROR,
                            error: err
                        });
                    }
                });
            });
        }
    );

    if (self instanceof BehaviorSubject) {
        // eslint-disable-next-line react-hooks/rules-of-hooks
        return useObservableEagerState(self[OBSERVABLE]) || {
            status: StateStatus.SUCCESS,
            value: self.value
        };
    } else {
        try {
            // eslint-disable-next-line react-hooks/rules-of-hooks
            return useObservableEagerState(self[OBSERVABLE]) || {
                status: StateStatus.PENDING
            };
        } catch (e) {
            // eslint-disable-next-line react-hooks/rules-of-hooks
            return useObservableState(self[OBSERVABLE]) || {
                status: StateStatus.PENDING
            };
        }
    }
}
