/* tslint:disable */
/* eslint-disable */
/**
 * SR AAT specification
 * Description
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { PartialParentRole } from './PartialParentRole';
import {
    PartialParentRoleFromJSON,
    PartialParentRoleFromJSONTyped,
    PartialParentRoleToJSON,
} from './PartialParentRole';

/**
 * 
 * @export
 * @interface UpdateRoleRequest
 */
export interface UpdateRoleRequest {
    /**
     * 
     * @type {Array<PartialParentRole>}
     * @memberof UpdateRoleRequest
     */
    parentRoles: Array<PartialParentRole>;
}

/**
 * Check if a given object implements the UpdateRoleRequest interface.
 */
export function instanceOfUpdateRoleRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "parentRoles" in value;

    return isInstance;
}

export function UpdateRoleRequestFromJSON(json: any): UpdateRoleRequest {
    return UpdateRoleRequestFromJSONTyped(json, false);
}

export function UpdateRoleRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateRoleRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'parentRoles': ((json['parentRoles'] as Array<any>).map(PartialParentRoleFromJSON)),
    };
}

export function UpdateRoleRequestToJSON(value?: UpdateRoleRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'parentRoles': ((value.parentRoles as Array<any>).map(PartialParentRoleToJSON)),
    };
}

