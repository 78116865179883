/* tslint:disable */
/* eslint-disable */
/**
 * SR AAT specification
 * Description
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ProductEnablement } from './ProductEnablement';
import {
    ProductEnablementFromJSON,
    ProductEnablementFromJSONTyped,
    ProductEnablementToJSON,
} from './ProductEnablement';

/**
 * 
 * @export
 * @interface WorkspaceEnablement
 */
export interface WorkspaceEnablement {
    /**
     * 
     * @type {number}
     * @memberof WorkspaceEnablement
     */
    productId?: number;
    /**
     * 
     * @type {ProductEnablement}
     * @memberof WorkspaceEnablement
     */
    productEnablement: ProductEnablement;
}

/**
 * Check if a given object implements the WorkspaceEnablement interface.
 */
export function instanceOfWorkspaceEnablement(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "productEnablement" in value;

    return isInstance;
}

export function WorkspaceEnablementFromJSON(json: any): WorkspaceEnablement {
    return WorkspaceEnablementFromJSONTyped(json, false);
}

export function WorkspaceEnablementFromJSONTyped(json: any, ignoreDiscriminator: boolean): WorkspaceEnablement {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'productId': !exists(json, 'productId') ? undefined : json['productId'],
        'productEnablement': ProductEnablementFromJSON(json['productEnablement']),
    };
}

export function WorkspaceEnablementToJSON(value?: WorkspaceEnablement | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'productId': value.productId,
        'productEnablement': ProductEnablementToJSON(value.productEnablement),
    };
}

