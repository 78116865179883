import {cssClasses} from '../../../../../common/helpers/browser/cssClasses';
import {KeyboardEvent, MouseEvent} from 'react';

export type PopoverMenuOptionProps<T = unknown> = {
    caption: string
    onClick?: (e?: MouseEvent | KeyboardEvent) => void,
    className?: string,
    ariaLabel?: string
}

export default function PopoverMenuOption<T>(props: PopoverMenuOptionProps<T>) {
    return <a
        aria-label={props.ariaLabel ?? props.caption}
        role={'button'}
        tabIndex={0}
        className={cssClasses(
            'spark-popover__list-link',
            props.className
        )}
        onClick={e => {
            props.onClick?.(e);
        }}
    >{props.caption}</a>;
}
