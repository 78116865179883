import {identity} from '../functions/identity';
import {getDbId} from '../db/getDbId';

export function groupByIds<T, U = T>(
    data: T[],
    keyFn: (it: T) => string = getDbId,
    mapFn: (it: T) => U = identity
): Record<string, U> {
    const result: Record<string, U> = {};

    for (let i = 0; i < data.length; i++) {
        const key = keyFn(data[i]!);

        result[key] ??= mapFn
            ? mapFn(data[i]!)
            : data[i]! as U;
    }

    return result;
}
