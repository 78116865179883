import {tAsString} from '../text/tAsString';
import {tWithHtml} from '../text/tWithHtml';
import {tWithoutHtml} from '../text/tWithoutHtml';
import {useOnce} from './useOnce';
import {useState} from 'react';
import i18next from 'i18next';

export function useI18Next() {
    const [lang, setLang] = useState({lang: i18next.language});

    const reTranslate = () => translate(lang.lang);
    const translate = (lang: string) => setLang({lang});

    useOnce(() => {
        i18next.on('initialized', reTranslate);
        i18next.on('loaded', reTranslate);
        i18next.on('added', reTranslate);
        i18next.on('removed', reTranslate);
        i18next.on('languageChanged', translate);
    });

    return {
        ...lang,
        t: tAsString,
        tAsString,
        tWithHtml,
        tWithoutHtml
    };
}
