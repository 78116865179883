import {Icon, Table, TableBody, TableHead, TableRow} from '@sabre/spark-react-core';
import {KEY} from '../../../../../common/helpers/id/KEY';
import React, {CSSProperties, Dispatch, useEffect, useMemo, useState} from 'react';
import {ColumnDescription} from './ColumnDescription';
import LoadingTableRow from './misc/LoadingTableRow';
import NoDataTableRow from './misc/NoDataTableRow';
import {StateStatus} from '../../../../../common/helpers/react/hooks/state-info/StateStatus';
import {TableRowProps} from '@sabre/spark-react-core/table/table.types';
import {getSignature} from '../../../../../common/helpers/signature/getSignature';
import DefaultTableHeaderCell from './DefaultTableHeaderCell';
import {useNormalizedColumns} from './useNormalizedColumns';
import {isString} from '../../../../../common/types/guards/isString';
import DefaultTableCell from './DefaultTableCell';
import {isNumber} from '../../../../../common/types/guards/isNumber';

export type DefaultTableProps<T> = Partial<DefaultTableCheckboxModeProps> & {
    columns?: ColumnDescription<T>[],
    data?: T[],
    isBusy?: boolean,
    idGetter?: (row: T) => string,
    className?: string,
    style?: CSSProperties,
    rowProps?: (row: T) => Partial<TableRowProps>,
    headerRowProps?: () => Partial<TableRowProps>,
    noDataLabel?: [string?, string?],
    loadingLabel?: [string?, string?],
    onBeforeLoaded?: () => Promise<void>,
    forceSize?: boolean,
    onClick?: (rowId: string) => void
}

export type DefaultTableCheckboxModeProps = {
    checkedRows: string[],
    setCheckedRows: Dispatch<string[]>
}

function hasId(o?: any): o is { id: string } {
    return isString(o?.id) || isNumber(o?.id);
}

export default function DefaultTable<T extends NonNullable<unknown>>(props: DefaultTableProps<T>) {
    const columns = useNormalizedColumns(props);
    const [renderAlertDiv, setRenderAlertDiv] = useState(false);
    useEffect(() => {
        const timer = setTimeout(() => {
            setRenderAlertDiv(true);
        }, 2000);
        return () => clearTimeout(timer);
    }, []);

    const idGetter = useMemo(() => {
        return props.idGetter
            ?? ((o: T) => {
                return hasId(o)
                    ? o.id
                    : getSignature(o);
            });
    }, [props.idGetter]);

    const loadedPromise = useMemo(() => {
        return props.onBeforeLoaded?.() ?? Promise.resolve();
    }, [props.onBeforeLoaded]);

    const isLoaded = loadedPromise.asStateInfo().status === StateStatus.SUCCESS;

    const data = isLoaded ? props.data : undefined;

    const isBusy = props.isBusy || !data;

    return (
        <>
            {renderAlertDiv ?
                <div
                    aria-live={'polite'}
                    role={'alert'}
                    aria-label={isBusy && (data?.length === 0 || !data) ? 'LOADING DATA' : 'DATA LOADED'}
                /> : null}
            <Table className={props.className} style={props.style}>
                <TableHead>
                    <TableRow {...props.headerRowProps?.()}>
                        {columns.map(c => <DefaultTableHeaderCell key={KEY(c)} column={c}/>)}
                    </TableRow>
                </TableHead>
                <TableBody className={!props.onClick ? 'non-clickable-table-row' : ''}>
                    {
                        data?.length
                            ? data.map((it) => {
                                return <TableRow key={KEY(it)}{...props.rowProps?.(it)} onClick={() => props.onClick?.(idGetter(it))}>
                                    {
                                        columns.map((c, idx) => {
                                            return <DefaultTableCell
                                                key={KEY([c, it, idx])}
                                                idGetter={idGetter}
                                                column={c}
                                                columnIdx={idx}
                                                data={it}
                                                setCheckedRows={props.setCheckedRows}
                                                checkedRows={props.checkedRows}
                                            />;
                                        })
                                    }
                                </TableRow>;
                            })
                            : isBusy
                                ? <LoadingTableRow
                                    colspan={columns.length}
                                    loadingLabel={props.loadingLabel}
                                />
                                : <NoDataTableRow
                                    colspan={columns.length}
                                    noDataLabel={props.noDataLabel}
                                />
                    }
                </TableBody>
            </Table>
        </>);
}
