import {RxNotification} from '../../../../common/model/db/types/RxNotification';
import styles from './TooltipNotification.module.css';
import {SECOND} from '../../../../common/CONST';

export default function TooltipNotification(props: RxNotification) {
    const transitionTime = 0.25;

    return (
        <div
            className={styles.TooltipNotification}
            id={props.stick_to}
            style={{
                opacity: Date.now() - props.ts > transitionTime * SECOND ? 0.9 : 0,
                transition: `opacity ${transitionTime}s ease-out`
            }}
            role={'alert'}
        >
            {props.title ? <header>{props.title}</header> : <></>}
            <p>{props.content}</p>
        </div>
    );
}
