import {Button} from '@sabre/spark-react-core';
import {ButtonSize} from '@sabre/spark-react-core/types';
import {default as React} from 'react';
import {useControllers} from '../../../../../../../../common/helpers/react/hooks/useControllers';
import {tAsString} from '../../../../../../../../common/helpers/react/text/tAsString';
import {RxGroupProperty} from '../../../../../../../../common/model/db/types/RxGroupProperty';
import {ColumnDescription} from '../../../../../../common/components/table/ColumnDescription';
import DefaultTableInput from '../../../../../../common/components/table/inputs/DefaultTableInput';
import {getPropertyInputCellHelpers} from '../misc/getPropertyInputCellHelpers';
import {isWorkflowProperty} from '../misc/isWorkflowProperty';
import {getWorkflowPropertyName} from '../misc/getWorkflowPropertyName';
import {getWorkflowPropertyDefaultValue} from '../misc/getWorkflowPropertyDefaultValue';
import DefaultListTable from '../../../../../../common/components/table/DefaultListTable';
import style from './ChangeWorkflowOrder.module.css';
import {ModalButtons} from '../../../../../../../../controllers/dialog/ModalButtons';
import {
    DefaultWizardHelpers
} from '../../../../../../common/components/wizards/DefaultWizardHelpers';
import {useHashWizard} from '../../../../../../common/components/wizards/useHashWizard';
import {useI18Next} from '../../../../../../../../common/helpers/react/hooks/useI18Next';
import Comparators from 'comparators';
import {asInt} from '../../../../../../../../common/helpers/converters/asInt';
import {getOverrideValue} from '../misc/getOverrideValue';
import {sleep} from '../../../../../../../../common/helpers/misc/sleep';

export type ChangeWorkflowOrderProps = {
    properties?: RxGroupProperty[]
}

export default function ChangeWorkflowOrder(props: ChangeWorkflowOrderProps) {
    const wh = useHashWizard();

    const controllers = useControllers();
    const dialog = controllers.dialog;

    const table = usePropertiesTable(wh, props.properties);

    return (
        <>
            <p className={style.Text}>
                {tAsString('CHANGE_WORKFLOW_ORDER_TEXT')}
            </p>
            <p>
                <Button
                    size={ButtonSize.EXTRA_SMALL}
                    secondary={true}
                    onClick={async () => {
                        await wh.setStepMember(['change-order'], {});

                        await dialog.showModal({
                            title: tAsString('CHANGE_WORKFLOW_ORDER_TITLE'),
                            children: table,
                            buttons: [
                                ModalButtons.CANCEL(dialog),
                                {
                                    children: tAsString('UPDATE'),
                                    className: 'qa-modal-update-button',
                                    onClick: async () => {
                                        await sleep(1);
                                        const stepData = await wh.stepData$;

                                        await wh.setStepData({
                                            ...stepData,
                                            properties: {
                                                ...(stepData?.['properties'] ?? {}),
                                                ...(stepData?.['change-order'] ?? {})
                                            },
                                            'change-order': {}
                                        });

                                        await dialog.closeModal();
                                    }
                                }
                            ]
                        });
                    }}
                >{tAsString('CHANGE_WORKFLOW_ORDER_BUTTON')}</Button>
            </p>
        </>
    );
}

function usePropertiesTable(wh: DefaultWizardHelpers, groupProperties?: RxGroupProperty[]) {
    const {tAsString} = useI18Next();

    const data = groupProperties
        ?.filter(isWorkflowProperty)
        ?.sort(Comparators.comparing(getWorkflowPropertyName));

    const columns: ColumnDescription<RxGroupProperty>[] = [
        {
            header: tAsString('FEATURE'),
            size: 1,
            render: row => <>{getWorkflowPropertyName(row)}</>
        },
        {
            header: tAsString('CURRENT_ORDER'),
            size: 1,
            align: 'center',
            render: row => {
                const value = asInt(row.override_value);

                return isNaN(value)
                    ? <>{getWorkflowPropertyDefaultValue(row)}</>
                    : <>{value}</>;
            }
        },
        {
            header: tAsString('NEW_ORDER'),
            size: 1,
            align: 'center',
            render: row => {
                const {
                    propertyId,
                    updateWizardData
                } = getPropertyInputCellHelpers(row, wh);

                const onBlurCapture = async (e: React.FocusEvent<HTMLInputElement>) => {
                    await updateWizardData(e.target.value, 'change-order');
                };

                const initialValue = getOverrideValue({
                    property_ref: row.property_ref
                }, wh);

                return <DefaultTableInput
                    type={'text'}
                    name={`override_value::${propertyId}`}
                    onBlurCapture={onBlurCapture}
                    defaultValue={initialValue}
                    ariaLabel={tAsString('OVERRIDE_VALUE_OF_X', {
                        name: getWorkflowPropertyName(row)
                    })}
                    typeaheadFormat={`\\d\\d\\d\\d\\d`}
                    isTypeahead={true}
                    placeHolder={`     `}
                />;
            }
        }
    ];

    return (
        <>
            <p>{tAsString('CHANGE_WORKFLOW_ORDER_DESCRIPTION')}</p>
            <DefaultListTable
                forceSize={true}
                columns={columns}
                data={data ?? []}
                isBusy={!data}
            />
        </>
    );
}
