/* tslint:disable */
/* eslint-disable */
/**
 * SR AAT specification
 * Description
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { GroupParticipantsSearchCriteria } from './GroupParticipantsSearchCriteria';
import {
    GroupParticipantsSearchCriteriaFromJSON,
    GroupParticipantsSearchCriteriaFromJSONTyped,
    GroupParticipantsSearchCriteriaToJSON,
} from './GroupParticipantsSearchCriteria';

/**
 * 
 * @export
 * @interface GroupParticipantsRequest
 */
export interface GroupParticipantsRequest {
    /**
     * 
     * @type {number}
     * @memberof GroupParticipantsRequest
     */
    id?: number;
    /**
     * 
     * @type {GroupParticipantsSearchCriteria}
     * @memberof GroupParticipantsRequest
     */
    searchCriteria?: GroupParticipantsSearchCriteria;
}

/**
 * Check if a given object implements the GroupParticipantsRequest interface.
 */
export function instanceOfGroupParticipantsRequest(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function GroupParticipantsRequestFromJSON(json: any): GroupParticipantsRequest {
    return GroupParticipantsRequestFromJSONTyped(json, false);
}

export function GroupParticipantsRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): GroupParticipantsRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'searchCriteria': !exists(json, 'searchCriteria') ? undefined : GroupParticipantsSearchCriteriaFromJSON(json['searchCriteria']),
    };
}

export function GroupParticipantsRequestToJSON(value?: GroupParticipantsRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'searchCriteria': GroupParticipantsSearchCriteriaToJSON(value.searchCriteria),
    };
}

