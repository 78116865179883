import {ProgressIndicator} from '@sabre/spark-react-core';
import {tAsString} from '../../../../../../common/helpers/react/text/tAsString';
import styles from './LoadingTableRow.module.css';
import {cssClasses} from '../../../../../../common/helpers/browser/cssClasses';

export type LoadingTableRowProps = {
    loadingLabel?: [string?, string?];
    colspan?: number;
}

export default function LoadingTableRow(props: LoadingTableRowProps) {
    const text = props.loadingLabel?.join(' ')
        ?? `${tAsString('LOADING')}...`;

    return <tr className={styles.LoadingTableRow}>
        <td colSpan={props.colspan ?? 1000} className={styles.LoadingTableCell}>
            <ProgressIndicator
                className={cssClasses(
                    'qa-loading-progress',
                    styles.ProgressIndicator
                )}
                size={'xs'}
                type={'indeterminate'}
                progressText={`${text}`}
            />
        </td>
    </tr>;
}
