import {isDefined} from '../../types/guards/isDefined';

/**
 * Creates associated ID generator
 *
 * Associated ID generator takes one value as argument.
 * If it is null or undefined, new ID is always generated.
 * If it is of object type, value will be mapped to ID using WeakMap.
 * If it is of other type, value will be mapped to ID using Map.
 *
 * Because WeakMaps are used, there is no warranty
 * that ID associated to object will remain the same forever.
 *
 * @param fn ID of any type generating function
 * @returns Associated ID generator
 */
export function createAssociatedIdGenerator<T>(fn: () => T): (o?: any) => T {
    const objectIds: WeakMap<object, T> = new WeakMap();
    const otherIds: Map<any, T> = new Map();

    return function associatedIdGenerator(o: any): T {
        if (isDefined(o)) {
            const ids = typeof o === 'object'
                ? objectIds
                : otherIds;

            if (!ids.has(o)) {
                ids.set(o, fn());
            }

            return ids.get(o) || fn();
        }

        return fn();
    };
}