import {DependencyList, useMemo} from 'react';
import {StateInfo} from './StateInfo';

const pendingPromise = new Promise<any>(() => {
    // always pending
});

export function usePromisedStateConditionally<T>(
    fn: () => Promise<T>,
    condition: () => Promise<boolean> = async () => true,
    deps: DependencyList = []
): StateInfo<T> {
    const promise = useMemo(async () => {
        const cond = await condition();

        if (cond) {
            return await fn();
        } else {
            return await pendingPromise;
        }
    }, [...deps, fn.toString()]);

    return promise.asStateInfo();
}
