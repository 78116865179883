import {Helmet} from 'react-helmet';
import {useControllers} from '../../../common/helpers/react/hooks/useControllers';

export default function HtmlHeadPresenter() {
    const title = useControllers().settings.get$('title').asState() ?? '';
    const subtitle = useControllers().settings.get$('subtitle').asState();

    const htmlTitle = subtitle
        ? `${subtitle} - ${title}`
        : title;

    return (
        <Helmet>
            <title>{htmlTitle}</title>
        </Helmet>
    );
}
