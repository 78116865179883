/* tslint:disable */
/* eslint-disable */
/**
 * SR AAT specification
 * Description
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  AuthorizationStatus,
  RedAppResponse,
} from '../models';
import {
    AuthorizationStatusFromJSON,
    AuthorizationStatusToJSON,
    RedAppResponseFromJSON,
    RedAppResponseToJSON,
} from '../models';

export interface GetAllRedAppsNamesRequest {
    authorization?: string;
    onBehalfOf?: string;
}

/**
 * 
 */
export class RedAppsApi extends runtime.BaseAPI {

    /**
     * Returns all red apps names and iu names
     * RedApps
     */
    async getAllRedAppsNamesRaw(requestParameters: GetAllRedAppsNamesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<RedAppResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (requestParameters.onBehalfOf !== undefined && requestParameters.onBehalfOf !== null) {
            headerParameters['On-Behalf-Of'] = String(requestParameters.onBehalfOf);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/redapps`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RedAppResponseFromJSON(jsonValue));
    }

    /**
     * Returns all red apps names and iu names
     * RedApps
     */
    async getAllRedAppsNames(requestParameters: GetAllRedAppsNamesRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<RedAppResponse> {
        const response = await this.getAllRedAppsNamesRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
