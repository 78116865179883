import React, {useMemo} from 'react';
import Toolbar from '../../../../common/components/toolbar/Toolbar';
import {useControllers} from '../../../../../../common/helpers/react/hooks/useControllers';
import {useI18Next} from '../../../../../../common/helpers/react/hooks/useI18Next';
import {ButtonConfig} from '../../../../../../controllers/dialog/ButtonConfig';
import {tAsString} from '../../../../../../common/helpers/react/text/tAsString';

export default function GroupsToolbar() {
    const {lang} = useI18Next();

    const controllers = useControllers();
    const groupsController = controllers.groups;
    const http = controllers.http;

    const groupsCount = groupsController.getGroupsCount$().asState() ?? 0;

    const toolbarButtons: ButtonConfig[] = useMemo(() => [
        {
            id: 'qa-create-button',
            onClick: () => http.navigateTo('groups/create/details.new'),
            children: tAsString('CREATE'),
            textOnly: true
        },
        {
            id: 'qa-delete-button',
            onClick: () => http.navigateTo('groups/delete'),
            children: tAsString('DELETE'),
            textOnly: true
        }
    ], [lang]);

    const toolbarContent = `${tAsString('TOTAL_GROUPS')}: ${groupsCount}`;

    return <Toolbar
        content={toolbarContent}
        buttons={toolbarButtons}
    />;
}
