import {
    ConsoleApiExtension,
    LoggingLevel,
    LoggingMethod,
    LogLine,
    PerfDoc
} from '../../types/api-extensions/ConsoleApiExtension';
import {MINUTE} from '../../CONST';
import {isDefined} from '../../types/guards/isDefined';
import Comparators from 'comparators';
import {mapEntries} from '../../helpers/objects/mapEntries';
import {floorTo} from '../../helpers/math/floorTo';
import {asString5} from '../../helpers/converters/asString5';

const SHOULD_LOG: unique symbol = Symbol('console.SHOULD_LOG');
const LOGGING_METHODS: unique symbol = Symbol('console.LOGGING_METHODS');
const LOGGING_LEVEL: unique symbol = Symbol('console.LOGGING_LEVEL');
const TEST_MODE: unique symbol = Symbol('console.TEST_MODE');
const PERF_MODE: unique symbol = Symbol('console.PERF_MODE');
const LOGS: unique symbol = Symbol('console.LOGS');
const MAX_LINES: unique symbol = Symbol('console.MAX_LINES');
const MIN_LINES: unique symbol = Symbol('console.MIN_LINES');

declare global {
    interface Console extends ConsoleApiExtension {
        [LOGGING_METHODS]: [LoggingMethod, LoggingMethod, LoggingMethod, LoggingMethod, LoggingMethod],
        [LOGGING_LEVEL]: LoggingLevel;
        [SHOULD_LOG]: (loggingLevel: LoggingLevel) => boolean;
        [TEST_MODE]?: boolean;
        [PERF_MODE]?: boolean;
        [LOGS]: LogLine[];
        [MAX_LINES]: number;
        [MIN_LINES]: number;
    }
}

console[LOGGING_LEVEL] ??= 0;
console[LOGGING_METHODS] ??= ['debug', 'info', 'log', 'warn', 'error'];

console[LOGS] = [];
console[MIN_LINES] = 300;
console[MAX_LINES] = console[MIN_LINES] + 100;

console[SHOULD_LOG] ??= function (this: Console, loggingLevel: LoggingLevel): boolean {
    return this[LOGGING_LEVEL] <= loggingLevel || this.isTestMode();
};

function decorateConsoleMethod(fnName: LoggingMethod, origFn: typeof console[LoggingMethod]) {
    return function (this: Console, ...data: any[]): void {
        const message = data
            .map(it => asString5(it))
            .join(' | ')
            .replace(/(Bearer [^/]+)\/.+'/g, `$1'`)
            .replace(/^[0-9\- ]{12,24}$/g, ' <CC> ');

        const loggingLevel = this.getLoggingLevel(fnName);

        this[LOGS].push({
            message: loggingLevel < 3 && message.length > 1000
                ? `${message.substring(0, 999)} ...`
                : message,
            method: fnName,
            level: loggingLevel,
            timestamp: Date.now()
        });

        const logsLength = this[LOGS].length;

        if (logsLength > this[MAX_LINES]) {
            this[LOGS].splice(0, logsLength - this[MIN_LINES]);
        }

        if (this[SHOULD_LOG](loggingLevel)) {
            origFn?.apply?.(this, data);
        }
    }.bind(console);
}

console[LOGGING_METHODS].forEach(fnName => {
    console[fnName] = decorateConsoleMethod(fnName, console[fnName]);
});

console.table = decorateConsoleMethod('info', console.table);

console.setLoggingLevel = function (loggingLevel: LoggingMethod | LoggingLevel): LoggingLevel {
    if (typeof loggingLevel === 'string') {
        loggingLevel = this.getLoggingLevel(loggingLevel);
    }

    return this[LOGGING_LEVEL] = loggingLevel;
};

console.getLoggingLevel = function (loggingMethod?: LoggingMethod): LoggingLevel {
    if (loggingMethod) {
        const loggingLevel = this[LOGGING_METHODS].indexOf(loggingMethod);

        if (this.isLoggingLevel(loggingLevel)) {
            return loggingLevel;
        }
    } else if (this.isTestMode()) {
        return 0;
    } else {
        return this[LOGGING_LEVEL];
    }

    return 0;
};

console.getLoggingMethod = function (loggingLevel?: LoggingLevel): LoggingMethod {
    return this[LOGGING_METHODS][loggingLevel ?? this[LOGGING_LEVEL]];
};

console.isLoggingLevel = function (o?: any): o is LoggingLevel {
    return typeof o === 'number'
        && o >= 0
        && o <= this[LOGGING_METHODS].length;
};

console.isLoggingMethod = function (o?: any): o is LoggingMethod {
    return typeof o === 'string'
        && (this[LOGGING_METHODS] as string[]).includes(o);
};

console.setTestMode = function (this: Console, isTestMode: boolean, minLines: number = this[MIN_LINES]): void {
    this[TEST_MODE] = isTestMode;
    this[MIN_LINES] = minLines;
    this[MAX_LINES] = minLines * 2;
};

console.isTestMode = function (this: Console): boolean {
    return !!this[TEST_MODE];
};

console.isDevMode = function (this: Console): boolean {
    return this.getLoggingLevel() === 0 && !this.isTestMode();
};

console.getLogs = function (this: Console): string[] {
    return this.getLogsAsObjects().map(it => {
        const dateAsString = new Date(it.timestamp)
            .toISOString()
            .replace(/[A-Z]+/g, ' ')
            .trim();

        return `${it.method[0]?.toUpperCase()} ${dateAsString} | ${it.message}`;
    });
};

console.getLogsAsObjects = function (this: Console): LogLine[] {
    return this[LOGS];
};

console.setPerfMode = function (this: Console, isPerfMode: boolean): void {
    this[PERF_MODE] = isPerfMode;
};

console.isPerfMode = function (this: Console): boolean {
    return !!this[PERF_MODE];
};

console.perf = function (this: Console, doc: PerfDoc): void {
    if (!this.isPerfMode() || !performance?.mark) {
        return;
    }

    if (doc.countOnly) {
        const resetDoc = {
            ...doc,
            countOnly: false,
            reset: true
        };

        console.perf(resetDoc);
        console.perf({...resetDoc, reset: false});
        return;
    }

    const compoundKey = doc.keys.join('__');
    const resetKey = `${compoundKey}-reset`;

    if (doc.reset) {
        performance.mark(resetKey, {
            detail: doc.data
        });
    } else {
        performance.mark(compoundKey, {
            detail: doc.data
        });

        try {
            performance.measure(compoundKey, {
                detail: doc.data,
                start: resetKey,
                end: compoundKey
            });
        } catch (e) {
            // pass
        }
    }
};

console.getPerfReport = function (this: Console, attr?: string): Record<string, any> {
    if (!performance?.mark) {
        return {};
    }

    const docs: any = {};

    performance.getEntriesByType('measure').forEach(it => {
        if (it instanceof PerformanceMeasure) {
            const json = {
                ...it.toJSON(),
                detail: it.detail
            };

            [...it.name.split('__'), it.name].forEach(key => {
                docs[key] ??= [];
                docs[key].push({...json});
            });
        }
    });

    const report: any = {};
    const floorTo2 = floorTo(2);

    for (let key in docs) {
        const probes: PerformanceMeasure[] = docs[key]!;
        const doc: any = {};

        doc.sum = floorTo2(probes.reduce((a, b) => a + (b.duration ?? 0), 0));
        doc.count = probes.length;
        doc.median = floorTo2(probes[Math.floor(doc.count / 2)]?.duration ?? 0);

        doc.avg = doc.count
            ? floorTo2(doc.sum / doc.count)
            : 0;

        doc.min = floorTo2(Math.min(...probes.map(it => it.duration).filter(isDefined)));
        doc.max = floorTo2(Math.max(...probes.map(it => it.duration).filter(isDefined)));

        doc.probes = probes;

        report[key] = doc;
    }

    if (attr) {
        return mapEntries<any>(
            report,
            pair => [pair[0], pair[1][attr]],
            undefined,
            Comparators.comparing('1', {reversed: true})
        );
    }

    return report;
};

setInterval(function () {
    console.log('[PERF] Report', console.getPerfReport());
}, 10 * MINUTE);

const searchParams = new URL(window.location.href).searchParams;

if (searchParams.get('test_mode') === 'true') {
    console.setTestMode(true);
    console.warn(`Turing test mode ON`);
}
