import {useHashWizard} from '../../../../common/components/wizards/useHashWizard';
import LoadingContent from '../../../../common/placeholders/loading/LoadingContent';
import {useGroupWizardTitle} from './useGroupWizardTitle';

export default function GroupWizardLoadingPlaceholder() {
    const wh = useHashWizard();
    const title = useGroupWizardTitle(wh.isEditing);

    return (
        <LoadingContent title={title}/>
    );
}
