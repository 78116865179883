/* tslint:disable */
/* eslint-disable */
/**
 * SR AAT specification
 * Description
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const AuthorizationStatusCodes = {
    Authorized: 'AUTHORIZED',
    AuthorizedOnBehalfOf: 'AUTHORIZED_ON_BEHALF_OF',
    Unauthorized: 'UNAUTHORIZED',
    Forbidden: 'FORBIDDEN'
} as const;
export type AuthorizationStatusCodes = typeof AuthorizationStatusCodes[keyof typeof AuthorizationStatusCodes];


export function AuthorizationStatusCodesFromJSON(json: any): AuthorizationStatusCodes {
    return AuthorizationStatusCodesFromJSONTyped(json, false);
}

export function AuthorizationStatusCodesFromJSONTyped(json: any, ignoreDiscriminator: boolean): AuthorizationStatusCodes {
    return json as AuthorizationStatusCodes;
}

export function AuthorizationStatusCodesToJSON(value?: AuthorizationStatusCodes | null): any {
    return value as any;
}

