const hashRegexp = /^#+/;
const slashRegexp = /\/+/g;
const noSlashRegexp = /^([^\/\.])/;

export function removeHash(target: string = '') {
    return target
        .replace(hashRegexp, '/')
        .replace(slashRegexp, '/')
        .replace(noSlashRegexp, '/$1');
}
