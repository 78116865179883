import {isRegularNumber} from '../../types/guards/isRegularNumber';
import {asNumber} from '../converters/asNumber';

export function floorTo(precision: number = 0): (value: number) => number {
    if (precision <= 0) {
        return Math.floor;
    }

    return (value: number): number => {
        if (!isRegularNumber(value)) {
            return value;
        }

        const valueAsString = value.toString();
        const splitted = valueAsString.split('.');

        if (splitted[1] === undefined) {
            return value;
        }

        splitted[1] = splitted[1].substring(0, precision);

        return asNumber(splitted.join('.'));
    };
}
