export function isEnum<T extends object>(o?: any): o is T {
    if (!(o instanceof Object)) {
        return false;
    }

    const allValues = Object.values(o);
    const numberValues = allValues.filter(it => typeof it === 'number') as number[];
    const stringValues = allValues.filter(it => typeof it === 'string') as string[];

    if (numberValues.length !== stringValues.length) {
        return false;
    }

    const numberIndexedRecord = o as any as Record<number, string>;
    const stringIndexedRecord = o as any as Record<string, number>;

    for (let numberValue of numberValues) {
        const stringValue = numberIndexedRecord[numberValue]!;

        if (stringValues.indexOf(stringValue) < 0) {
            return false;
        }

        if (stringIndexedRecord[stringValue] !== numberValue) {
            return false;
        }
    }

    return true;
}
