/* tslint:disable */
/* eslint-disable */
/**
 * SR AAT specification
 * Description
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const StatusCodes = {
    Success: 'SUCCESS',
    Error: 'ERROR'
} as const;
export type StatusCodes = typeof StatusCodes[keyof typeof StatusCodes];


export function StatusCodesFromJSON(json: any): StatusCodes {
    return StatusCodesFromJSONTyped(json, false);
}

export function StatusCodesFromJSONTyped(json: any, ignoreDiscriminator: boolean): StatusCodes {
    return json as StatusCodes;
}

export function StatusCodesToJSON(value?: StatusCodes | null): any {
    return value as any;
}

