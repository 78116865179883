import {EntryPoint} from '../../common/core/entry-point/EntryPoint';
import * as Rx from 'rxjs';
import i18next from 'i18next';
import {Config_core} from '../../common/core/entry-point/Config_core';
import {services} from '../../config/services';
import * as s from 'underscore.string';
import {asJson} from '../../common/helpers/converters/asJson';
import {q} from '../../common/helpers/rxjs/qx/queue/q';

export class WebToolsEntryPoint extends EntryPoint {
    protected async main(): Promise<void> {
        this.addGlobalAccessObject().andWeAreDone();
        this.printDatabase().andWeAreDone();
    }

    private async addGlobalAccessObject() {
        const global = window as any;
        const cfg = this.cfg as Config_core;

        const controllers = cfg.controllers;
        const db = await cfg.services.database.getInstance();
        const collections = db.collections;

        const accessObject: any = {
            ...services,
            ...controllers,
            ...collections,
            ...cfg,
            cfg,
            db,
            Rx,
            i18next,
            s,
            show: (...collections: string[]) => {
                this.printDatabase(...collections)
                    .andWeAreDone();
            },
            showdb: (...collections: string[]) => {
                this.printDatabase(...collections)
                    .andWeAreDone();
            },
            q: q
        };

        accessObject.aat = accessObject;

        const done = [];

        for (const it in accessObject) {
            if (!Object.hasOwn(global, it)) {
                global[it] = accessObject[it];
                done.push(it);
            }
        }

        console.debug(`Web Tools support added as ${done.join(', ')}`);
    }

    private async printDatabase(...collections: string[]) {
        const cfg = this.cfg as Config_core;
        const db: any = await cfg.services.database.getInstance();

        for (let collectionName in db.collections) {
            if (collections.length && !collections.includes(collectionName)) {
                continue;
            }

            const result = db[collectionName]._;

            if (result.length) {
                console.info(`[DB] ${collectionName} has ${result.length} docs`);
                console.table(asJson(result));
            } else {
                console.info(`[DB] ${collectionName} has no data`);
            }
        }
    }
}
