import {asyncScheduler} from 'rxjs';
import {asInt} from '../converters/asInt';

export function repeat(
    fn: () => void,
    timeout: number = 100,
    delay: number = 0,
    stepFactor: number = 5
) {
    if (delay > 0) {
        asyncScheduler.schedule(() => {
            repeat(fn, timeout, 0, stepFactor);
        }, delay);
    } else {
        requestAnimationFrame(fn);

        const nextTimeout = timeout > (10 * stepFactor)
            ? timeout / stepFactor
            : timeout;

        if (nextTimeout > 0) {
            asyncScheduler.schedule(() => {
                repeat(fn, timeout - nextTimeout, 0, stepFactor);
            }, asInt(nextTimeout));
        }
    }
}
