import {isPlainObject} from '../../types/guards/isPlainObject';
import {ObjectIndex} from '../../types/common/index/ObjectIndex';
import {asJsonObjectOrNull} from './asJsonObjectOrNull';

export function asObjectOrNull(o?: unknown): Record<ObjectIndex, unknown> | null {
    if (isPlainObject(o)) {
        return o;
    }

    return asJsonObjectOrNull(o);
}
