/*** Autogenerated. Use 'npm run generate-sources' to regenerate. ***/
import {RxJsonObject} from '../../../types/json/RxJsonObject';
import {JSONSchema4} from 'json-schema';
import {generateConverterToRx} from '../../../helpers/db/converters/generateConverterToRx';
import {generateArrayConverter} from '../../../helpers/db/converters/generateArrayConverter';
import {isDescribedByJsonSchema} from '../../../types/guards/isDescribedByJsonSchema';
import {isPartiallyDescribedByJsonSchema} from '../../../types/guards/isPartiallyDescribedByJsonSchema';
/**
 * Collection `groups` contains documents of `RxGroup` type.
 */
export interface RxGroup {
  /**
   * Primary key.
   */
  id: string;
  name: string;
  description?: string;
  total_agents?: number;
  editor_name?: string;
  /**
   * Special property. Property indicates if given document is active and should be used in application. Document with this property set to `false` should be considered as non-existing.
   */
  active__document?: boolean;
}
export const RxGroupSchema: JSONSchema4 = {
    title: 'RxGroup',
    primaryKey: 'id',
    required: [
        'id',
        'name',
    ],
    version: 0,
    type: 'object',
    properties: {
        id: {
            maxLength: 10000,
            type: 'string',
            description: 'Primary key.',
        },
        name: {
            type: 'string',
            description: '',
        },
        description: {
            type: 'string',
            description: '',
        },
        total_agents: {
            type: 'integer',
            default: 0,
            description: '',
        },
        editor_name: {
            type: 'string',
            description: '',
        },
        active__document: {
            type: 'boolean',
            default: false,
            description: 'Special property. Property indicates if given document is active and should be used in application. Document with this property set to `false` should be considered as non-existing.',
        },
    },
    additionalProperties: false,
    description: 'Collection `groups` contains documents of `RxGroup` type.',
}
export const asRxGroup = generateConverterToRx<RxGroup>(RxGroupSchema, 'RxGroupSchema');
export const asRxGroupArray = generateArrayConverter<RxGroup>(asRxGroup);
export function instanceOfRxGroup(o?: any): o is RxGroup { 
    return isDescribedByJsonSchema(o, RxGroupSchema, false, false); 
}
export function isRxGroup(o?: any): o is RxGroup { 
    return isDescribedByJsonSchema(o, RxGroupSchema, false, false); 
}
export function instanceOfPartialRxGroup(o?: any): o is Partial<RxGroup> { 
    return isPartiallyDescribedByJsonSchema(o, RxGroupSchema);
}
export function isPartialRxGroup(o?: any): o is Partial<RxGroup> { 
    return isPartiallyDescribedByJsonSchema(o, RxGroupSchema); 
}