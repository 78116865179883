import {DependencyList, EffectCallback, useEffect} from 'react';
import {asyncScheduler} from 'rxjs';

export function useAsyncEffect(effect: EffectCallback, deps?: DependencyList, delay?: number): void {
    useEffect(() => {
        let destructor: ReturnType<EffectCallback>;

        const sub = asyncScheduler.schedule(() => {
            destructor = effect();
        }, delay);

        return () => {
            sub.unsubscribe();
            destructor?.();
        };
    }, deps);
}
