import {getAllMemberNames} from '../objects/getAllMemberNames';
import {ConditionalExcept} from 'type-fest';

export function removeNullishMembers<T extends object>(o: T): ConditionalExcept<T, undefined> {
    const result = {...o};

    getAllMemberNames(o).forEach(key => {
        const desc = Object.getOwnPropertyDescriptor(o, key);

        if (desc && desc.get) {
            delete result[key];
        }

        if ((desc && desc.value === undefined) || o[key] === undefined) {
            delete result[key];
        }

        if ((desc && desc.value === null) || o[key] === null) {
            delete result[key];
        }
    });

    return result;
}
