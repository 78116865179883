/*** Autogenerated. Use 'npm run generate-sources' to regenerate. ***/
import {RxJsonObject} from '../../../types/json/RxJsonObject';
import {JSONSchema4} from 'json-schema';
import {generateConverterToRx} from '../../../helpers/db/converters/generateConverterToRx';
import {generateArrayConverter} from '../../../helpers/db/converters/generateArrayConverter';
import {isDescribedByJsonSchema} from '../../../types/guards/isDescribedByJsonSchema';
import {isPartiallyDescribedByJsonSchema} from '../../../types/guards/isPartiallyDescribedByJsonSchema';
/**
 * Collection `notifications` contains documents of `RxNotification` type.
 */
export interface RxNotification {
  /**
   * Primary key.
   */
  id: string;
  ts: number;
  read?: boolean;
  title?: string;
  content: string;
  status?: string;
  stick_to?: string;
}
export const RxNotificationSchema: JSONSchema4 = {
    title: 'RxNotification',
    primaryKey: 'id',
    required: [
        'id',
        'ts',
        'content',
    ],
    version: 0,
    type: 'object',
    properties: {
        id: {
            maxLength: 10000,
            type: 'string',
            description: 'Primary key.',
        },
        ts: {
            type: 'number',
            default: 0,
            description: '',
        },
        read: {
            type: 'boolean',
            default: false,
            description: '',
        },
        title: {
            type: 'string',
            default: '',
            description: '',
        },
        content: {
            type: 'string',
            description: '',
        },
        status: {
            type: 'string',
            default: 'info',
            description: '',
        },
        stick_to: {
            type: 'string',
            default: '',
            description: '',
        },
    },
    additionalProperties: false,
    description: 'Collection `notifications` contains documents of `RxNotification` type.',
}
export const asRxNotification = generateConverterToRx<RxNotification>(RxNotificationSchema, 'RxNotificationSchema');
export const asRxNotificationArray = generateArrayConverter<RxNotification>(asRxNotification);
export function instanceOfRxNotification(o?: any): o is RxNotification { 
    return isDescribedByJsonSchema(o, RxNotificationSchema, false, false); 
}
export function isRxNotification(o?: any): o is RxNotification { 
    return isDescribedByJsonSchema(o, RxNotificationSchema, false, false); 
}
export function instanceOfPartialRxNotification(o?: any): o is Partial<RxNotification> { 
    return isPartiallyDescribedByJsonSchema(o, RxNotificationSchema);
}
export function isPartialRxNotification(o?: any): o is Partial<RxNotification> { 
    return isPartiallyDescribedByJsonSchema(o, RxNotificationSchema); 
}