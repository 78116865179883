import {TOptions} from 'i18next';
import {mapEntries} from '../../objects/mapEntries';
import {escapeHtml} from './escapeHtml';
import {tAsString} from './tAsString';
import {isString} from '../../../types/guards/isString';

export function tWithHtml(key: string, options: TOptions = {}, escapeParams: boolean = true): JSX.Element {
    if (escapeParams) {
        options = mapEntries(options, pair => [
            pair[0],
            isString(pair[1])
                ? escapeHtml(pair[1])
                : pair[1]
        ]);
    }

    return <span dangerouslySetInnerHTML={{__html: tAsString(key, options)}}/>;
}
