import React from 'react';
import styles from './Separator.module.css';
import {SeparatorProps} from './SeparatorProps';

export default function DotSeparator(props: SeparatorProps) {
    return (
        <span className={styles.DotSeparator}>
            <span className={props.className}>
                &#8226;
            </span>
        </span>
    );
}
