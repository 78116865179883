import {useControllers} from '../../../../common/helpers/react/hooks/useControllers';
import {useEffect} from 'react';

export default function GoToDefaultPage() {
    const auth = useControllers().auth;

    useEffect(() => auth.navigateToDefaultPage().andWeAreDone());

    return <></>;
}
