import {AnyFn} from '../../types/common/functions/AnyFn';
import {AnyIndex} from '../../types/common/index/AnyIndex';

const MOCKED: unique symbol = Symbol('getMocked().MOCKED');

export function getMocked<T extends Record<string | symbol, any>>(
    mocks: Partial<T> = {},
    // returns any for purpose
    defaultMockFactory: AnyFn<[AnyIndex]> = () => undefined
): T {
    if (mocks[MOCKED]) {
        return mocks as T;
    }

    // low-level magic, do not try at home
    return new Proxy<T>(mocks as T, {
        get(target, prop) {
            if (prop === MOCKED) {
                return true;
            }

            return mocks?.[prop as keyof T] ?? defaultMockFactory(prop);
        },

        set(target, prop, value: unknown) {
            mocks[prop as keyof T] = value as T[keyof T];
            return true;
        }
    });
}
