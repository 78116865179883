import React, {useState} from 'react';
import {RolesToolbar} from './RolesToolbar';
import RolesTable from './RolesTable';
import {useControllers} from '../../../../../common/helpers/react/hooks/useControllers';
import LoadingProgress from '../../../common/placeholders/loading/LoadingProgress';

export const AddAggregatedRoleModal = () => {
    const [checkedRows, setCheckedRows] = useState<string[]>([]);
    const dialog = useControllers().dialog;
    const roles = useControllers().roles;
    const isBusy = ['GETTING_PARENT_ROLES', 'UPDATING_PARENT_ROLES'].includes(roles.isBusy$().asState() as string);
    const modal = dialog.getCurrentModal$().asState() ?? {};
    modal.data = {
        add: checkedRows
    };

    return (<>
        {isBusy && <LoadingProgress/>}
        <RolesToolbar addAggregatedRolesMode={true}/>
        <RolesTable addAggregatedRolesMode={true} checkedRows={checkedRows} setCheckedRows={setCheckedRows} mainTableView={false}/>
    </>);
}