import {getMocked} from './getMocked';
import {noop} from '../functions/noop';
import {Controller} from '../../../controllers/Controller';
import {AnyFn} from '../../types/common/functions/AnyFn';
import {AnyIndex} from '../../types/common/index/AnyIndex';
import {ObservableFlag} from '../../types/rxjs/ObservableFlag';

export function getMockedController<T extends Controller>(
    mockedValues?: Partial<T>,
    defaultMockFactory?: AnyFn<[AnyIndex]>
): T {
    const extendedMocks: any = mockedValues ?? {};

    extendedMocks.priority ??= 0;
    extendedMocks.init ??= noop();
    extendedMocks.getBusyFlag ??= () => new ObservableFlag();

    return getMocked<T>(extendedMocks, defaultMockFactory);
}
