/* tslint:disable */
/* eslint-disable */
/**
 * SR AAT specification
 * Description
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Agency } from './Agency';
import {
    AgencyFromJSON,
    AgencyFromJSONTyped,
    AgencyToJSON,
} from './Agency';
import type { Group } from './Group';
import {
    GroupFromJSON,
    GroupFromJSONTyped,
    GroupToJSON,
} from './Group';
import type { Location } from './Location';
import {
    LocationFromJSON,
    LocationFromJSONTyped,
    LocationToJSON,
} from './Location';

/**
 * 
 * @export
 * @interface Pcc
 */
export interface Pcc {
    /**
     * 
     * @type {number}
     * @memberof Pcc
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof Pcc
     */
    pcc: string;
    /**
     * 
     * @type {string}
     * @memberof Pcc
     */
    sccode?: string;
    /**
     * 
     * @type {string}
     * @memberof Pcc
     */
    agencyName?: string;
    /**
     * 
     * @type {Location}
     * @memberof Pcc
     */
    location?: Location;
    /**
     * 
     * @type {Agency}
     * @memberof Pcc
     */
    agency?: Agency;
    /**
     * 
     * @type {Array<Group>}
     * @memberof Pcc
     */
    groups?: Array<Group>;
}

/**
 * Check if a given object implements the Pcc interface.
 */
export function instanceOfPcc(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "pcc" in value;

    return isInstance;
}

export function PccFromJSON(json: any): Pcc {
    return PccFromJSONTyped(json, false);
}

export function PccFromJSONTyped(json: any, ignoreDiscriminator: boolean): Pcc {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'pcc': json['pcc'],
        'sccode': !exists(json, 'sccode') ? undefined : json['sccode'],
        'agencyName': !exists(json, 'agencyName') ? undefined : json['agencyName'],
        'location': !exists(json, 'location') ? undefined : LocationFromJSON(json['location']),
        'agency': !exists(json, 'agency') ? undefined : AgencyFromJSON(json['agency']),
        'groups': !exists(json, 'groups') ? undefined : ((json['groups'] as Array<any>).map(GroupFromJSON)),
    };
}

export function PccToJSON(value?: Pcc | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'pcc': value.pcc,
        'sccode': value.sccode,
        'agencyName': value.agencyName,
        'location': LocationToJSON(value.location),
        'agency': AgencyToJSON(value.agency),
        'groups': value.groups === undefined ? undefined : ((value.groups as Array<any>).map(GroupToJSON)),
    };
}

