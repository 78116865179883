import {Status} from '../../core/rest-client/aat';

export class ServerError extends Error {
    constructor(status?: Status) {
        const msg = `${status?.status}: ${status?.statusDescription}`
            ?? 'Server error occurred';

        super(msg);
    }
}
