import {ColumnDescription} from '../ColumnDescription';
import {cssClasses} from '../../../../../../common/helpers/browser/cssClasses';

export function getColumnClassName<T>(c: ColumnDescription<T>, additionalClassName?: string) {
    return cssClasses(
        c.size ? `col-xs-${c.size}` : undefined,
        c.hiddenWhen ? `spark-hidden--${c.hiddenWhen}` : undefined,
        additionalClassName
    );
}
