import {RxLocation} from '../../../../../../../common/model/db/types/RxLocation';
import {isDefined} from '../../../../../../../common/types/guards/isDefined';

export function getCityAndCountry(location?: RxLocation): string {
    return [
        location?.city?.toTitleCase(),
        location?.country_code?.toUpperCase()
    ]
        .filter(isDefined)
        .join(', ');
}
