import {UpsertMode} from './UpsertMode';
import {upsertInto} from './upsertInto';
import {ForeignKey} from './ForeignKey';
import {IdHolder} from './IdHolder';
import {identity} from '../functions/identity';
import {ensure} from '../../types/guards/ensure';
import {isArray} from '../../types/guards/isArray';
import Comparators from 'comparators';
import {isString} from '../../types/guards/isString';
import {isArrayOf} from '../../types/guards/isArrayOf';
import {hasFlag} from '../flags/hasFlag';
import {QxCollection} from '../rxjs/qx/db/QxCollection';

export async function upsertManyToMany<T extends IdHolder>(
    collection: QxCollection<T>,
    data: IdsData<T>,
    mapFn: (doc: Partial<T>) => Partial<T> = identity,
    mode: UpsertMode = UpsertMode.REPLACE,
    filterFn?: (it: T) => boolean,
    doc: Partial<T> = {}
): Promise<void> {
    const hasNoRows = !!Object.values(data)
        .filter(isArrayOf(isString))
        .filter(it => !it.length)
        .length;

    if (hasNoRows) {
        if (hasFlag(UpsertMode.REMOVE)(mode)) {
            return upsertInto(collection, [], mode, it => {
                for (let attr in data) {
                    const ids = data[attr as keyof typeof data];

                    if (ids?.length && !ids.includes((it as any)[attr])) {
                        return false;
                    }
                }

                return true;
            });
        } else {
            return Promise.resolve();
        }
    }

    const keys = Object.keys(data);
    const firstKey = keys[0] as ForeignKey<T> | undefined;

    if (firstKey) {
        const firstKeyData = data[firstKey] ?? [];
        ensure(isArray, firstKeyData);

        if (keys.length === 1) {
            const newDocs = firstKeyData
                .map(id => getNewDoc(doc, firstKey, id))
                .map(mapFn);

            return upsertInto(collection, newDocs, mode, filterFn);
        } else if (keys.length > 1) {
            const lessData = {...data};
            delete lessData[firstKey];

            await firstKeyData.mapAsync(async id => {
                const newDoc = getNewDoc(doc, firstKey, id);

                const newFilterFn = (it: T): boolean => {
                    if (filterFn && !filterFn(it)) {
                        return false;
                    }

                    return it[firstKey] === id;
                };

                await upsertManyToMany(collection, lessData, mapFn, mode, newFilterFn, newDoc);
            });
        }
    }
}

type IdsData<T extends IdHolder> = Partial<Record<ForeignKey<T>, string[]>>;

function getNewDoc<T extends IdHolder>(doc: Partial<T>, firstKey: ForeignKey<T>, id: string): Partial<T> {
    const newDoc = {
        ...doc,
        [firstKey]: id
    };

    return {
        ...newDoc,
        id: getComplexId(newDoc)
    };
}

function getComplexId<T extends IdHolder>(doc: Partial<T>): string {
    return Object.entries(doc)
        .filter(pair => pair[0] !== 'id')
        .filter(pair => isString(pair[1]))
        .sort(Comparators.comparing(doc => doc[0]))
        .map(pair => pair[1])
        .join('_');
}
