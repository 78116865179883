import {Service} from '../Service';
import {Bound} from '../../../common/decorators/methods/Bound';
import {
    asyncScheduler,
    catchError,
    combineLatestWith,
    filter,
    map,
    Observable,
    observeOn,
    Subject
} from 'rxjs';
import {RequestInitExtended} from './RequestInitExtended';
import {THROTTLING_INTERVAL_FOR_HTTP, TIMEOUT_FOR_HTTP} from '../../../common/CONST';
import {ConfigurationParameters, FetchError} from '../../rest-client/aat';
import {once} from '../../../common/helpers/functions/once';
import {Once} from '../../../common/decorators/methods/Once';
import {timeoutLikeABoss} from '../../../common/helpers/rxjs/timeoutLikeABoss';
import {distinctLikeABoss} from '../../../common/helpers/rxjs/distinctLikeABoss';
import {asString} from '../../../common/helpers/converters/asString';
import {QxQueue} from '../../../common/helpers/rxjs/qx/queue/QxQueue';
import {isInstanceOf} from '../../../common/types/guards/isInstanceOf';
import {getSignature} from '../../../common/helpers/signature/getSignature';
import md5 from 'md5';

interface FetchAPIHolder {
    fetch: WindowOrWorkerGlobalScope['fetch'];
}

export class HttpService extends Service implements FetchAPIHolder {
    /**
     * Fetching calls queue.
     */
    #q = new QxQueue();

    /**
     * HTTP results - responses and errors
     */
    #httpResults = new Subject<unknown>();

    @Once()
    getHttpErrors$(): Observable<Error> {
        return this.#httpResults
            .pipe(
                filter(isInstanceOf(Error)),
                observeOn(asyncScheduler)
            );
    }

    @Once()
    getHttpResponses$(): Observable<Response> {
        return this.#httpResults
            .pipe(
                filter(isInstanceOf(Response)),
                map(it => it.clone()),
                observeOn(asyncScheduler)
            );
    }

    @Bound()
    async fetch(input: RequestInfo | URL, init?: RequestInitExtended): Promise<Response> {
        const requestId = md5(getSignature([input, init]));

        return this.#q.next(async () => {
            console.perf({
                keys: ['fetch', asString(input)],
                reset: true
            });

            console.debug(`Fetching STARTED`, {input, init});

            const start = Date.now();

            const timeout = init?.timeout || TIMEOUT_FOR_HTTP;
            const abortController = new AbortController();

            const abortTID = setTimeout(() => {
                abortController.abort();
            }, timeout);

            let data: unknown;

            try {
                const response = await fetch(input, {
                    ...init,
                    signal: abortController.signal
                });

                // against FetchError...
                const clone = response.clone();
                clone.json = once(clone.json);
                response.json = once(response.json);

                data = clone;

                return response;
            } catch (err) {
                if (err instanceof Error && err.message.startsWith(`The user aborted`)) {
                    const urlAsString = this.controllers.http.getUrlAsString(input);
                    err = new FetchError(err, `Couldn't fetch ${urlAsString} in ${timeout}ms`);
                }

                data = err;

                throw err;
            } finally {
                clearTimeout(abortTID);

                let text = '';
                let json = {};
                let status = 999;
                let method = init?.method ?? 'GET?';

                if (data instanceof Response) {
                    try {
                        status = data.status;
                        text = await data.clone().text();
                        json = JSON.parse(text);
                    } catch (e) {
                        console.warn(asString(e));
                    }
                }

                const result = status >= 500 || data instanceof Error
                    ? 'ERROR'
                    : 'SUCCESS';

                console.debug(`Fetching FINISHED with ${result} in ${Date.now() - start}ms`, {
                    status, method, input, text, init, json, data
                });

                console.perf({
                    keys: ['fetch', asString(input)]
                });

                this.#httpResults.next(data);
            }
        }, {
            taskId: requestId,
            ttl: THROTTLING_INTERVAL_FOR_HTTP
        });
    }

    @Once()
    getAatApiConfiguration$(): Observable<ConfigurationParameters> {
        return this.controllers.auth.getAuthConfig$()
            .pipe(
                combineLatestWith(this.controllers.auth.getATK$()),
                filter(([authConfig, atk]) => !!atk && !!authConfig.url_aat),
                map(([authConfig, atk]): ConfigurationParameters => {
                    return {
                        basePath: authConfig.url_aat,
                        accessToken: atk,
                        fetchApi: this.services.http.fetch
                    };
                }),
                timeoutLikeABoss(TIMEOUT_FOR_HTTP, `No AAT API configuration received!`),
                catchError(err => {
                    this.controllers.auth.killSession(asString(err)).andWeAreDone();
                    throw err;
                }),
                distinctLikeABoss()
            );
    }
}
