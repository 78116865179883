import {useParams} from 'react-router-dom';
import {useControllers} from '../../../../../../../common/helpers/react/hooks/useControllers';
import {asNumber} from '../../../../../../../common/helpers/converters/asNumber';
import {default as React} from 'react';
import GroupWizardDetailsStepForm from './GroupWizardDetailsStepForm';
import {HashWizardHelpers} from '../../../../../common/components/wizards/HashWizardHelpers';
import LoadingBlock from '../../../../../common/placeholders/loading/LoadingBlock';
import {GroupWizardDetailsStepFormValues} from './GroupWizardDetailsStepFormValues';
import {isString} from '../../../../../../../common/types/guards/isString';

export type GroupWizardDetailsStepFormPresenter = {
    wizardHelpers: Pick<HashWizardHelpers, 'stepData' | 'setStepData' | 'isLoading' | 'step' | 'isEditing'>;
}

export default function GroupWizardDetailsStepFormPresenter(props: GroupWizardDetailsStepFormPresenter) {
    const wh = props.wizardHelpers;
    const {id} = useParams();

    const controllers = useControllers();
    const groups = controllers.groups;
    const group = groups.getGroupById$(asNumber(id)).asState();

    if (wh.isLoading || (wh.isEditing && !group)) {
        return <LoadingBlock height={60}/>;
    }

    const initialData: Partial<GroupWizardDetailsStepFormValues> = {
        name: isString(wh.stepData?.name)
            ? wh.stepData?.name
            : group?.name,
        description: isString(wh.stepData?.description)
            ? wh.stepData?.description
            : group?.description
    };

    return <GroupWizardDetailsStepForm wizardHelpers={wh} initialData={initialData}/>;
}
