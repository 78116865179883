/* tslint:disable */
/* eslint-disable */
/**
 * SR AAT specification
 * Description
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Group } from './Group';
import {
    GroupFromJSON,
    GroupFromJSONTyped,
    GroupToJSON,
} from './Group';
import type { Location } from './Location';
import {
    LocationFromJSON,
    LocationFromJSONTyped,
    LocationToJSON,
} from './Location';

/**
 * 
 * @export
 * @interface Agency
 */
export interface Agency {
    /**
     * 
     * @type {number}
     * @memberof Agency
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof Agency
     */
    name: string;
    /**
     * 
     * @type {Location}
     * @memberof Agency
     */
    location?: Location;
    /**
     * 
     * @type {Array<Group>}
     * @memberof Agency
     */
    groups?: Array<Group>;
}

/**
 * Check if a given object implements the Agency interface.
 */
export function instanceOfAgency(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "name" in value;

    return isInstance;
}

export function AgencyFromJSON(json: any): Agency {
    return AgencyFromJSONTyped(json, false);
}

export function AgencyFromJSONTyped(json: any, ignoreDiscriminator: boolean): Agency {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': json['name'],
        'location': !exists(json, 'location') ? undefined : LocationFromJSON(json['location']),
        'groups': !exists(json, 'groups') ? undefined : ((json['groups'] as Array<any>).map(GroupFromJSON)),
    };
}

export function AgencyToJSON(value?: Agency | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'location': LocationToJSON(value.location),
        'groups': value.groups === undefined ? undefined : ((value.groups as Array<any>).map(GroupToJSON)),
    };
}

