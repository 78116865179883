import {ConfigurationToolbar} from './ConfigurationToolbar';
import {ConfigurationsTable} from './ConfigurationsTable';
import {tAsString} from '../../../../../common/helpers/react/text/tAsString';
import {useOnce} from '../../../../../common/helpers/react/hooks/useOnce';
import {useSubtitle} from '../../../../../common/helpers/react/hooks/useSubtitle';
import {useControllers} from '../../../../../common/helpers/react/hooks/useControllers';

export default function ConfigurationPresenter() {
    useSubtitle(tAsString('MANAGE_CONFIGURATIONS'));

    const configurations = useControllers().configurations;
    const redApps = useControllers().redApps;
    useOnce(() => redApps.refreshRedApps()).andWeAreDone();
    useOnce(() => configurations.refreshConfigurations()).andWeAreDone();

    return (
        <>
            <h1>{tAsString('MANAGE_CONFIGURATIONS')}</h1>
            <ConfigurationToolbar/>
            <ConfigurationsTable/>
        </>
    );
}
