import {OperatorFunction} from 'rxjs';
import {Factory} from '../../types/common/functions/Factory';

export function PipedObservable(
    pipe: Factory<OperatorFunction<any, any>[]>
): MethodDecorator {
    return (proto: any, prop: string | symbol, desc: PropertyDescriptor) => {
        const FN = Symbol(`@Piped(${String(prop)}).FN`);

        delete desc.value;
        delete desc.get;

        proto[FN] = proto[prop];
        proto[prop] = function (this: typeof proto, ...args: any) {
            return proto[FN].apply(this, args).pipe(...pipe());
        };
    };
}
