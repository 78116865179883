import {Popover} from '@sabre/spark-react-core';
import {PopoverProps} from '@sabre/spark-react-core/popover/popover.types';
import {cssClasses} from '../../../../../common/helpers/browser/cssClasses';
import styles from './PopoverMenu.module.css';
import {KEY} from '../../../../../common/helpers/id/KEY';
import {IconProps} from '@sabre/spark-react-core/icon/icon.types';
import PopoverMenuOption, {PopoverMenuOptionProps} from './PopoverMenuOption';
import {useI18Next} from '../../../../../common/helpers/react/hooks/useI18Next';
import {useRefByClassName} from '../../../../../common/helpers/react/hooks/useRefByClassName';
import {asInt} from '../../../../../common/helpers/converters/asInt';
import {useMemo} from 'react';
import {repeat} from '../../../../../common/helpers/functions/repeat';
import {REM, TIMEOUT_FOR_LIGHTNING_ACTION} from '../../../../../common/CONST';

export type PopoverMenuProps<T = unknown> = {
    className?: string,
    anchorX?: PopoverProps['anchorX'],
    anchorY?: PopoverProps['anchorY'],
    disabled?: boolean,
    icon?: IconProps['name'],
    caption?: string,
    options: PopoverMenuOptionProps<T>[][]
}

export default function PopoverMenu<T = unknown>(props: PopoverMenuProps<T>) {
    const {t} = useI18Next();

    const toggleEl = props.icon
        ? <button
            aria-label={props.caption ?? t('MENU')}
            disabled={props.disabled ?? false}
            className={cssClasses(
                styles.Icon,
                `spark-btn--icon spark-icon spark-icon-${props.icon}`,
                'qa-icon-menu-ellipsis-vertical-button',
                props.className
            )}
        />
        : <button
            disabled={props.disabled ?? false}
            className={cssClasses(
                styles.Icon,
                'qa-icon-menu-ellipsis-vertical-button',
                props.className
            )}
        >{props.caption ?? ''}</button>;

    const popoverRef = useRefByClassName();
    const fixPosition = useMemo(() => () => {
        const el = popoverRef.current;

        if (el instanceof HTMLElement) {
            const maxYPosition = window.innerHeight * 0.95;
            const minYPosition = window.innerHeight * 0.05;

            const rect = el.getBoundingClientRect();

            const currentTop = asInt(el.style.top);
            const currentLeft = Math.min(
                Math.max(
                    asInt(el.getAttribute('data-aat-left') ?? el.style.left),
                    asInt(el.style.left)
                ),
                window.innerWidth - (4 * REM) - rect.width
            );

            const bottomBorderYPosition = rect.top + rect.height;

            const deltaBottom = bottomBorderYPosition - maxYPosition;
            const deltaTop = rect.top - minYPosition;

            if (deltaBottom > 0) {
                el.style.top = `${currentTop - deltaBottom}px`;
            } else if (deltaTop < 0) {
                el.style.top = `${currentTop - deltaTop}px`;
            }

            const horizontalModifier = props.anchorY === 'middle'
                ? 0
                : props.anchorX === 'center'
                    ? 0
                    : props.anchorX === 'right'
                        ? (3 * REM)
                        : (-3 * REM);

            el.style.left = `${currentLeft + horizontalModifier}px`;
            el.style.opacity = '1';

            el.setAttribute('data-aat-left', `${currentLeft}px`);

            el.querySelector<HTMLElement>(`li:first-child *:first-child`)?.focus();
        }
    }, [popoverRef.className]);

    const onClick = () => repeat(fixPosition, TIMEOUT_FOR_LIGHTNING_ACTION);

    return <Popover
        className={cssClasses(
            styles.PopoverMenu,
            popoverRef.className,
            'aat-popover-menu'
        )}
        anchorX={props.anchorX ?? 'left'}
        anchorY={props.anchorY ?? 'bottom'}
        toggleEl={toggleEl}
        onClick={onClick}
    >
        <div className={'spark-toolbar__item--content'}>
            {props.options.map((it, idx) =>
                <ul
                    key={KEY(it)}
                    className={cssClasses(
                        'spark-popover__list'
                    )}
                >
                    {
                        idx > 0
                            ? <hr className={styles.Separator}/>
                            : <></>
                    }
                    {it.map(it =>
                        <li key={KEY(it)} className="spark-popover__list-item">
                            <PopoverMenuOption {...it} />
                        </li>
                    )}
                </ul>
            )}
        </div>
    </Popover>;
}
