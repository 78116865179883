import {isFunction} from "../../types/guards/isFunction";

export function Bound(): MethodDecorator {
    return (proto: any, prop: string | symbol, desc: PropertyDescriptor) => {
        const methodName = `@Bound(${String(prop)})`;
        const FN = Symbol(`${methodName}.FN`);
        const BOUND = Symbol(`${methodName}.BOUND`);

        delete desc.value;
        delete desc.writable;

        proto[FN] = proto[prop];
        desc.get = function (this: typeof proto) {
            return (this[BOUND] ??= proto[FN].bind(this));
        };

        desc.set = function (this: typeof proto, value: unknown) {
            delete this[BOUND];

            this[BOUND] = isFunction(value)
                ? value.bind(this)
                : undefined;
        }
    };
}
