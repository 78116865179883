import {useEffect} from 'react';
import {useControllers} from '../../../../../common/helpers/react/hooks/useControllers';

export const RolesPresenter = () => {
    const controllers = useControllers();
    const http = controllers.http;
    const roles = controllers.roles;
    roles.clearSearchedRoles();
    useEffect(() => http.navigateTo('roles/search').andWeAreDone());

    return <></>;
}