/**
 * Status of authentication and authorization from SPA PoV.
 *
 * It is based on flags and represents states of virtual state machine.
 * (Really virtual, non-existing beside our brains).
 */
export enum AuthStatus {
    /**
     * Not used directly, but as part of other error states.
     */
    ERROR = 0b0_000_001,
    /**
     * The initial state flag. Used while waiting for authentication.
     *
     * May be changed to AUTHENTICATED or any error state.
     */
    PENDING = 0b0_000_010,
    /**
     * State telling us that we know the guy. We have tokens and cookies required to call for
     * authorization.
     *
     * May be changed to AUTHORIZED or any error state.
     */
    AUTHENTICATED = 0b0_000_100,
    /**
     * Error state telling us that the guy is unknown.
     *
     * May be changed to any other state.
     */
    NOT_AUTHENTICATED = 0b0_001_000 + ERROR,
    /**
     * Error state telling us that we know the guy, but we do not allow him or her to deal with
     * our AAT web tool.
     *
     * May be changed to any other state.
     */
    NOT_AUTHORIZED = 0b0_010_000 + AUTHENTICATED + ERROR,
    /**
     * State telling us that the user is our man. We know the guy, we trust the guy, and we allow
     * him or her to play with our AAT web tool.
     *
     * May be changed to any error state due to practical reasons.
     * Hey! In the end, we want to re-check the user, but we do not want he or she to know about it!
     */
    AUTHORIZED = 0b0_100_000 + AUTHENTICATED
}