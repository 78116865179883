import {Panel} from "@sabre/spark-react-core";
import styles from './GroupWizardShowProductAssigneesModal.module.css';
import {useControllers} from "../../../../../../../common/helpers/react/hooks/useControllers";
import DefaultTable from "../../../../../common/components/table/DefaultTable";
import {useMemo, useState} from "react";
import {ColumnDescription} from "../../../../../common/components/table/ColumnDescription";
import {Assignee} from "../../../../../../../common/model/types/assignees/Assignee";
import {useI18Next} from "../../../../../../../common/helpers/react/hooks/useI18Next";
import {cssClasses} from "../../../../../../../common/helpers/browser/cssClasses";
import {tAsString} from "../../../../../../../common/helpers/react/text/tAsString";

export type GroupWizardShowProductAssigneesModalProps = {
    productId: number,
    groupId: number,
    assigneesCount: number,
    toAdd: string[],
    toRemove: string[]
}

export default function GroupWizardShowProductAssigneesModal(props: GroupWizardShowProductAssigneesModalProps) {
    const {t} = useI18Next();
    const groups = useControllers().groups;
    const [isBusy, setBusy] = useState(true);

    useMemo(() => {
        groups.searchForProductAssignees(props.productId, props.groupId, {
            add: props.toAdd,
            remove: props.toRemove
        }).then(() => setBusy(false));
    }, [props.groupId, props.productId, props.toAdd, props.toRemove]);

    const assigneesList = groups.getProductAssignees$(props.productId, props.groupId).asState();

    const columns: ColumnDescription<Assignee>[] = [
        {
            header: t('ASSIGNEE_ID_HEADER'),
            render: row => <>{row.agency?.name
                ?? row.pcc?.code
                ?? row.agent?.sabre_id}</>
        },
        {
            header: t('AGENT_NAME_HEADER'),
            render: row => <>{row.agent?.name ?? t('ALL')}</>
        }
        ];

    return (
        <>
            <Panel
                className={cssClasses(
                    styles.ProductAssigneesPanel,
                    'qa-modal-assignees-panel'
                )}>
                {t("ACTIVE_ASSIGNEES")}: {props.assigneesCount}
            </Panel>
            <DefaultTable
                className={cssClasses('qa-modal-assignees-table',styles.ProductAssigneesTable)}
                data={assigneesList}
                columns={columns}
                forceSize={true}
                isBusy={isBusy}
                noDataLabel={[
                    tAsString('NO_ASSIGNEES_TO_DISPLAY')
                ]}
            />
        </>
    )
}
