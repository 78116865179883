/* tslint:disable */
/* eslint-disable */
/**
 * SR AAT specification
 * Description
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { GroupType } from './GroupType';
import {
    GroupTypeFromJSON,
    GroupTypeFromJSONTyped,
    GroupTypeToJSON,
} from './GroupType';

/**
 * 
 * @export
 * @interface GroupInfoUsingAgentsDetailsInner
 */
export interface GroupInfoUsingAgentsDetailsInner {
    /**
     * 
     * @type {number}
     * @memberof GroupInfoUsingAgentsDetailsInner
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof GroupInfoUsingAgentsDetailsInner
     */
    assignee: string;
    /**
     * 
     * @type {string}
     * @memberof GroupInfoUsingAgentsDetailsInner
     */
    agent: string;
    /**
     * 
     * @type {GroupType}
     * @memberof GroupInfoUsingAgentsDetailsInner
     */
    level: GroupType;
}

/**
 * Check if a given object implements the GroupInfoUsingAgentsDetailsInner interface.
 */
export function instanceOfGroupInfoUsingAgentsDetailsInner(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "assignee" in value;
    isInstance = isInstance && "agent" in value;
    isInstance = isInstance && "level" in value;

    return isInstance;
}

export function GroupInfoUsingAgentsDetailsInnerFromJSON(json: any): GroupInfoUsingAgentsDetailsInner {
    return GroupInfoUsingAgentsDetailsInnerFromJSONTyped(json, false);
}

export function GroupInfoUsingAgentsDetailsInnerFromJSONTyped(json: any, ignoreDiscriminator: boolean): GroupInfoUsingAgentsDetailsInner {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'assignee': json['assignee'],
        'agent': json['agent'],
        'level': GroupTypeFromJSON(json['level']),
    };
}

export function GroupInfoUsingAgentsDetailsInnerToJSON(value?: GroupInfoUsingAgentsDetailsInner | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'assignee': value.assignee,
        'agent': value.agent,
        'level': GroupTypeToJSON(value.level),
    };
}

