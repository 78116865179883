import {DefaultWizardHelpers} from './DefaultWizardHelpers';
import LoadingPage from '../../placeholders/loading/LoadingPage';
import {DefaultWizardStepDescription} from './DefaultWizardStepDescription';
import {
    usePromisedStateConditionally
} from '../../../../../common/helpers/react/hooks/state-info/usePromisedStateConditionally';
import {StateStatus} from '../../../../../common/helpers/react/hooks/state-info/StateStatus';
import {useControllers} from '../../../../../common/helpers/react/hooks/useControllers';
import {MessageStatus} from '@sabre/spark-react-core/types';
import {asString} from '../../../../../common/helpers/converters/asString';
import {useAsyncEffect} from '../../../../../common/helpers/react/hooks/useAsyncEffect';

export type DefaultWizardStepProps = {
    wizardHelpers: DefaultWizardHelpers,
    step: DefaultWizardStepDescription,
    fallback?: JSX.Element
}

export default function DefaultWizardStep(props: DefaultWizardStepProps) {
    const wh = props.wizardHelpers;
    const step = props.step;

    const dialog = useControllers().dialog;

    const beforeStateInfo = usePromisedStateConditionally(async () => {
        const validationResult = await wh.allStepsData$;
        const allData = validationResult.data;
        await step.onBeforeLoaded?.(allData[wh.stepName], wh, allData);
    }, undefined, [step.name, wh.wizardData?.wizardId]);

    useAsyncEffect(() => {
        if (beforeStateInfo.status === StateStatus.ERROR) {
            dialog.showNotification({
                status: MessageStatus.ERROR,
                content: asString(beforeStateInfo.error)
            });
        }
    });

    const stepElement = step.element;

    if (beforeStateInfo.status === StateStatus.PENDING || wh.isLoading || !stepElement) {
        return props.fallback ?? <LoadingPage/>;
    }

    return stepElement;
}
