/* tslint:disable */
/* eslint-disable */
/**
 * SR AAT specification
 * Description
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { RedApp } from './RedApp';
import {
    RedAppFromJSON,
    RedAppFromJSONTyped,
    RedAppToJSON,
} from './RedApp';
import type { Status } from './Status';
import {
    StatusFromJSON,
    StatusFromJSONTyped,
    StatusToJSON,
} from './Status';
import type { StatusCodes } from './StatusCodes';
import {
    StatusCodesFromJSON,
    StatusCodesFromJSONTyped,
    StatusCodesToJSON,
} from './StatusCodes';

/**
 * 
 * @export
 * @interface RedAppResponse
 */
export interface RedAppResponse extends Status {
    /**
     * 
     * @type {Array<RedApp>}
     * @memberof RedAppResponse
     */
    redApps: Array<RedApp>;
}

/**
 * Check if a given object implements the RedAppResponse interface.
 */
export function instanceOfRedAppResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "redApps" in value;

    return isInstance;
}

export function RedAppResponseFromJSON(json: any): RedAppResponse {
    return RedAppResponseFromJSONTyped(json, false);
}

export function RedAppResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): RedAppResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        ...StatusFromJSONTyped(json, ignoreDiscriminator),
        'redApps': ((json['redApps'] as Array<any>).map(RedAppFromJSON)),
    };
}

export function RedAppResponseToJSON(value?: RedAppResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        ...StatusToJSON(value),
        'redApps': ((value.redApps as Array<any>).map(RedAppToJSON)),
    };
}

