/* tslint:disable */
/* eslint-disable */
/**
 * SR AAT specification
 * Description
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const GroupParticipantType = {
    Pcc: 'PCC',
    Country: 'COUNTRY',
    AgencyName: 'AGENCY_NAME',
    SabreId: 'SABRE_ID',
    AgentName: 'AGENT_NAME'
} as const;
export type GroupParticipantType = typeof GroupParticipantType[keyof typeof GroupParticipantType];


export function GroupParticipantTypeFromJSON(json: any): GroupParticipantType {
    return GroupParticipantTypeFromJSONTyped(json, false);
}

export function GroupParticipantTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): GroupParticipantType {
    return json as GroupParticipantType;
}

export function GroupParticipantTypeToJSON(value?: GroupParticipantType | null): any {
    return value as any;
}

