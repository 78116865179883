import {Button, Modal} from '@sabre/spark-react-core';
import {ModalConfig} from '../../../../controllers/dialog/ModalConfig';
import {cssClasses} from '../../../../common/helpers/browser/cssClasses';
import styles from './RegularModal.module.css';
import {renderMany} from '../../../../common/helpers/react/renderMany';
import {ButtonSize} from '@sabre/spark-react-core/types';
import {useControllers} from '../../../../common/helpers/react/hooks/useControllers';

export default function RegularModal(props: ModalConfig) {
    const dialogController = useControllers().dialog;

    const modalProps = {...props};
    delete modalProps.buttons;
    delete modalProps.maximized;

    return <Modal
        {...modalProps}
        className={cssClasses(
            props.className,
            styles.Modal,
            'aat-regular-modal'
        )}
        onClose={() => {
            props.onClose?.();
            dialogController.closeModal().andWeAreDone();
        }}
        open={true}
        children={props.children ?? ''}
        modalFooter={props.modalFooter ?? (
            <nav className="spark-btn-group">
                {
                    renderMany(Button, props.buttons, {
                        className: 'spark-btn-group-primary',
                        size: ButtonSize.MEDIUM,
                        children: ''
                    })
                }
            </nav>
        )}
    />;
}
