import {asArray} from '../../converters/asArray';
import {isDefined} from '../../../types/guards/isDefined';

export function generateArrayConverter<T>(converter: (o?: unknown) => T | null): (o?: unknown) => T[] {
    return (o?: unknown) => {
        return asArray(o)
            .map(converter)
            .filter(isDefined);
    };
}
