import {Service} from '../Service';
import {JsonObject, JsonValue} from 'type-fest';

export class SessionStorageService extends Service {
    private get key(): string {
        return `AAT_` + this.controllers.auth.getSessionId();
    }

    #getDict(): JsonObject {
        const dictAsString = sessionStorage.getItem(this.key) ?? '{}';
        return JSON.parse(dictAsString);
    }

    #saveDict(dict: JsonObject): void {
        sessionStorage.setItem(this.key, JSON.stringify(dict));
    }

    get(key: string): JsonValue | undefined {
        const dict = this.#getDict();
        return dict[key];
    }

    set(key: string, value: JsonValue): void {
        const dict = this.#getDict();
        dict[key] = value;
        this.#saveDict(dict);
    }
}