import RightMenu from './RightMenu';
import {useOnce} from '../../../../common/helpers/react/hooks/useOnce';
import {useControllers} from '../../../../common/helpers/react/hooks/useControllers';
import {useI18Next} from '../../../../common/helpers/react/hooks/useI18Next';

export default function RightMenuPresenter() {
    const {lang} = useI18Next();

    const controllers = useControllers();

    const setLanguage = useOnce(() => (lang: string) => {
        controllers.settings.set('lang', lang).andWeAreDone();
    });

    return <RightMenu
        currentLanguage={lang}
        setLanguage={setLanguage}
    />;
}
