import {cssClasses} from '../../common/helpers/browser/cssClasses';
import {tAsString} from '../../common/helpers/react/text/tAsString';
import {DialogController} from './DialogController';
import {ButtonConfig} from './ButtonConfig';

export namespace ModalButtons {
    export function CANCEL(dialogController: DialogController): ButtonConfig {
        return {
            secondary: true,
            children: tAsString('CANCEL'),
            className: cssClasses(
                'qa-modal-cancel-button',
                'aat-autokey-escape-first'
            ),
            onClick: () => {
                dialogController.closeModal().andWeAreDone();
            }
        };
    }
}
