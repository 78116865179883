import {Icon} from '@sabre/spark-react-core';
import React from 'react';
import {KEY} from '../../../../../common/helpers/id/KEY';
import {AssigneeBreadcrumb} from '../../../../../common/model/types/assignees/AssigneeBreadcrumb';
import {AssigneesSearchResultLevel} from '../../../../../common/model/types/assignees/AssigneesSearchResultLevel';
import {isNumber} from '../../../../../common/types/guards/isNumber';

export const printBreadcrumb = (onClick: () => void, breadcrumb: AssigneeBreadcrumb, breadcrumbIdx: number, isLastOne = false): JSX.Element => {
    const chevron = breadcrumbIdx ? <Icon name={'arrow-chevron-right'}/> : <></>;

    const label = breadcrumb.level === AssigneesSearchResultLevel.SEARCH && isNumber(breadcrumb.total_children)
        ? `${breadcrumb.label} (${breadcrumb.total_children})`
        : `${breadcrumb.label}`;
    if (!isLastOne)
    {
        return <React.Fragment key={KEY(breadcrumb)}>
            {chevron}<a tabIndex={0} onClick={onClick}>{label}</a>
        </React.Fragment>;
    } else
    {
        return <React.Fragment key={KEY(breadcrumb)}>{chevron}{label}</React.Fragment>;
    }
}