import DefaultWizard, {DefaultWizardProps} from './DefaultWizard';
import {useWizardIdsFromHash} from './useWizardIdsFromHash';
import GoTo from '../../goto/GoTo';
import {useHashWizard} from './useHashWizard';
import LoadingPage from '../../placeholders/loading/LoadingPage';
import {useParams} from 'react-router-dom';
import {useState} from 'react';
import {useControllers} from '../../../../../common/helpers/react/hooks/useControllers';
import {HashWizardHelpers} from './HashWizardHelpers';
import {MiscDataObject} from '../../../../../common/helpers/react/hooks/useMiscData';
import {HashWizardStepDescription} from './HashWizardStepDescription';
import {DefaultWizardStepDescription} from './DefaultWizardStepDescription';
import {getSignature} from '../../../../../common/helpers/signature/getSignature';
import {getWizardData} from './getWizardData';

export type HashWizardProps =
    Omit<DefaultWizardProps, 'wizardId' | 'currentStep' | 'onSubmit' | 'onBeforeLoaded' | 'steps'>
    &
    {
        onSubmit?: (data: Record<string, MiscDataObject>, wizardHelpers: HashWizardHelpers) => Promise<void>,
        onBeforeLoaded?: (data: Record<string, MiscDataObject>, wizardHelpers: HashWizardHelpers) => Promise<void>
        steps: HashWizardStepDescription[]
    };

export default function HashWizard(props: HashWizardProps) {
    const ids = useWizardIdsFromHash();
    const wh = useHashWizard();

    const params = useParams();
    const paramsSignature = getSignature(params.id);
    const [paramsState, setParamsState] = useState(paramsSignature);

    const http = useControllers().http;

    if (paramsSignature !== paramsState) {
        http.reload();
    }

    if (!ids.step) {
        const firstStepName = props.steps[0]?.name.toLowerCase();

        if (!firstStepName) {
            throw new Error(`Wizard misconfigured! No steps defined!`);
        }

        return <GoTo
            to={`${ids.wizardId}/${firstStepName}${ids.reset ? '.new' : ''}`}
            fallback={props.fallback ?? <LoadingPage/>}
            delay={100}
            replace
        />;
    }

    if (ids.reset) {
        return <GoTo
            to={`${ids.wizardId}/${ids.step}`}
            before={() => {
                return wh.resetWizard(getWizardData({
                    ...props,
                    wizardId: ids.wizardId,
                    currentStep: ids.step
                } as DefaultWizardProps));
            }}
            fallback={props.fallback ?? <LoadingPage/>}
            delay={100}
            replace
        />;
    }

    const steps: DefaultWizardStepDescription[] = props.steps.map((it) => {
        return {
            ...it,
            onValidate: it.onValidate
                ? async function (data, dwh, allData) {
                    return it.onValidate?.(data, {
                        ...wh,
                        ...dwh
                    }, allData);
                }
                : undefined,
            onBeforeLoaded: it.onBeforeLoaded
                ? async function (data, dwh, allData) {
                    return it.onBeforeLoaded?.(data, {
                        ...wh,
                        ...dwh
                    }, allData);
                }
                : undefined
        };
    });

    const onSubmit: DefaultWizardProps['onSubmit'] | undefined = props.onSubmit
        ? async function (data, dwh) {
            return props.onSubmit?.(data, {
                ...wh,
                ...dwh
            });
        }
        : undefined;

    const onBeforeLoaded: DefaultWizardProps['onBeforeLoaded'] | undefined = props.onBeforeLoaded
        ? async function (data, dwh) {
            return props.onBeforeLoaded?.(data, {
                ...wh,
                ...dwh
            });
        }
        : undefined;

    return <DefaultWizard
        steps={steps}
        wizardId={ids.wizardId}
        currentStep={ids.step}
        cancelPage={props.cancelPage}
        onSubmit={onSubmit}
        onBeforeLoaded={onBeforeLoaded}
        fallback={props.fallback}
    />;
}
