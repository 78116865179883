import {Config_common} from './Config_common';
import {Once} from '../../decorators/methods/Once';

export abstract class EntryPoint {
    protected readonly cfg: Config_common;

    constructor(cfg: Config_common) {
        this.cfg = cfg;
    }

    @Once()
    async start(): Promise<this> {
        await this.main();
        return Promise.resolve(this);
    }

    protected abstract main(): Promise<void>;
}
