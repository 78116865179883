export const MILLISECOND = 1;
export const SECOND = 1000 * MILLISECOND;
export const MICROSECOND = 0.000_001 * SECOND;
export const NANOSECOND = 0.000_000_001 * SECOND;
export const MINUTE = 60 * SECOND;
export const HOUR = 60 * MINUTE;
export const DAY = 24 * HOUR;
export const WEEK = 7 * DAY;
export const MONTH = 30 * DAY;
export const YEAR = 365.25 * DAY;
export const CENTURY = 100 * YEAR;
export const MILLENNIUM = 1000 * YEAR;
export const EON = 500_000_000 * YEAR;

/**
 * Default value for common timeouts.
 */
export const TIMEOUT = 30 * SECOND;

/**
 * Default value for HTTP calls timeout.
 */
export const TIMEOUT_FOR_HTTP = 90 * SECOND;

/**
 * Default value for instant action timeout.
 * Should be used when data is expected to be returned promptly.
 */
export const TIMEOUT_FOR_INSTANT_ACTION = SECOND;

/**
 * Default value for lightning timeout.
 * Should be used when user should not be sure if delay happened.
 */
export const TIMEOUT_FOR_LIGHTNING_ACTION = SECOND;

/**
 * Default value used for throttling interval in unknown context.
 */
export const THROTTLING_INTERVAL = 500 * MILLISECOND;

/**
 * Default throttling interval for HTTP calls.
 */
export const THROTTLING_INTERVAL_FOR_HTTP = 2 * SECOND;

/**
 * Default throttling interval for React's data sources
 */
export const THROTTLING_INTERVAL_FOR_DATA = 333 * MILLISECOND;

/**
 * Flag indicating if animations should be used in application.
 */
export const ANIMATE = true;

/**
 * Transparent 1x1 image to be used as placeholder, e.g., in table headers where space cannot go.
 */
export const IMG_1x1_URL = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVQYV2NgYAAAAAMAAWgmWQ0AAAAASUVORK5CYII=';

/**
 * Standard 1rem size in pixels
 */
export const REM = 12;
