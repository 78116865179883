export enum UpsertMode {
    /**
     * Ignores any action driven by other flags
     */
    IGNORE = 0b1,

    /**
     * Sets active__document flag to true for new documents
     */
    ADD = 0b10,

    /**
     * Sets active__document flag to false for non-new documents
     */
    REMOVE = 0b100,

    /**
     * Does the same as UpsertMode.ADD + UpsertMode.REMOVE
     */
    REPLACE = ADD + REMOVE,

    /**
     * Overwrites documents instead of merging them
     */
    OVERWRITE = 0b1000,

    /**
     * Does the same as UpsertMode.ADD + UpsertMode.OVERWRITE
     */
    ADD_OVERWRITE = OVERWRITE + ADD,

    /**
     * Does the same as UpsertMode.REMOVE + UpsertMode.OVERWRITE
     */
    REMOVE_OVERWRITE = OVERWRITE + REMOVE,

    /**
     * Does the same as UpsertMode.REPLACE + UpsertMode.OVERWRITE
     */
    REPLACE_OVERWRITE = OVERWRITE + REPLACE
}
