import {BehaviorSubject, Observable} from 'rxjs';
import {isPromiseLike} from '../guards/isPromiseLike';

export class ObservableFlag {
    readonly #$: BehaviorSubject<boolean | string>;
    readonly #runningValue: boolean | string;

    constructor(initialValue: boolean | string = false, runningValue: boolean | string = true) {
        this.#$ = new BehaviorSubject<boolean | string>(initialValue);
        this.#runningValue = runningValue;
    }

    get $(): Observable<boolean | string> {
        return this.#$;
    }

    get value(): boolean | string {
        return this.#$.value;
    }

    switch(nextValue?: boolean | string): boolean | string {
        if (typeof nextValue === 'undefined') {
            nextValue = !this.value;
        }

        this.#$.next(nextValue);

        return this.value;
    }

    run<T>(fn: () => T, reason?: boolean | string): T {
        const nextValue = reason ?? this.#runningValue;

        this.switch(nextValue);

        const result = fn();

        if (isPromiseLike(result)) {
            result.then(
                () => this.switch(!nextValue),
                () => this.switch(!nextValue)
            );
        } else {
            this.switch(!nextValue);
        }

        return result;
    }
}
