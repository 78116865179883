import {isSymbol} from '../../types/guards/isSymbol';
import {AllKeysOf} from '../../types/common/object/AllKeysOf';

export function getAllMemberNames<T extends object>(o?: T): AllKeysOf<T> {
    if (!o || o === Function.prototype || o === Object.prototype) {
        return [] as AllKeysOf<T>;
    }

    return [
        ...Object.getOwnPropertyNames(o).filter(predicate),
        ...Object.getOwnPropertySymbols(o).filter(predicate),
        ...getAllMemberNames(Object.getPrototypeOf(o))
    ] as AllKeysOf<T>;
}

const predicate = (it: string | symbol) => {
    return isSymbol(it) || !it.startsWith('__');
};
