import {isDefined} from './isDefined';
import {stack} from '../../helpers/objects/stack';
import JSON5 from 'json5';
import {JSONSchema4} from 'json-schema';

export function isDescribedByJsonSchema<T>(
    o?: any,
    jsonSchema?: JSONSchema4,
    strict: boolean = false,
    isImportant: boolean = true
): o is T {
    if (isDefined(o)) {
        // FIXME!!!
        return true;

        const finalJsonSchema = strict
            ? jsonSchema
            : {
                ...jsonSchema,
                additionalProperties: true
            };

        // FIXME: validate data!!!
        const validator = (it: any) => true;
        const validationResult = validator(o);

        if (!validationResult) {
            console[isImportant ? 'warn' : 'debug'](`JSON Schema validation failed`, {
                errors: undefined, // FIXME: validator.errors?.map(it => JSON5.stringify(it)),
                object: JSON5.stringify(o),
                jsonSchema: JSON5.stringify(jsonSchema),
                strict: strict,
                stack: stack(),
                validator: validator
            });
        }
        return validationResult;
    }

    return false;
}
