import WildTableRow from './WildTableRow';
import {useI18Next} from '../../../../../../common/helpers/react/hooks/useI18Next';

export type NoDataTableRowProps = {
    noDataLabel?: [string?, string?]
    colspan?: number;
}

export default function NoDataTableRow(props: NoDataTableRowProps) {
    const {t} = useI18Next();

    const title = props.noDataLabel?.[1]
        && props.noDataLabel[0];

    const content = props.noDataLabel?.[1]
        ?? props.noDataLabel?.[0]
        ?? t('NO_DATA');

    return <WildTableRow colspan={props.colspan} title={title} content={content}/>;
}
