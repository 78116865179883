import {
    DefaultWizardHelpers
} from '../../../../../../common/components/wizards/DefaultWizardHelpers';
import {isRecordOf} from '../../../../../../../../common/types/guards/isRecordOf';
import {RxGroupProduct} from '../../../../../../../../common/model/db/types/RxGroupProduct';
import {
    isProductPlacement
} from '../../../../../../../../common/model/types/products/isProductPlacement';

export function getRedAppPlacementValue(row: Pick<RxGroupProduct, 'product_ref' | 'redapp_placement'>, wh: DefaultWizardHelpers): string | undefined {
    const products = wh.stepData?.products ?? {};

    if (isRecordOf(isProductPlacement)(products)) {
        const stepValue = products[row.product_ref?.id ?? NaN];

        return isProductPlacement(stepValue)
            ? stepValue.redAppPlacement
            : row.redapp_placement;
    }

    return undefined;
}
