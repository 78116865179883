import styles from './LoadingPage.module.css';
import {useI18Next} from '../../../../../common/helpers/react/hooks/useI18Next';
import {useTimeout} from '../../../../../common/helpers/react/hooks/useTimeout';
import LoadingContent from './LoadingContent';
import LoadingBlock from './LoadingBlock';

type LoadingPageProps = {
    timeout?: number;
}

export default function LoadingPage(props: LoadingPageProps) {
    const {t} = useI18Next();

    const timeout = useTimeout(props.timeout);

    if (timeout) {
        return (
            <div
                className={styles.TimeoutWrapper}
                aria-live="assertive"
                role="alert"
                aria-label={t('TIMEOUT')}
            >
                <LoadingContent timeout={0}/>
            </div>
        );
    }

    return (
        <div
            className={styles.LoadingWrapper}
            aria-live="assertive"
            role="alert"
            aria-label={t('LOADING')}
        >
            <LoadingBlock/>
        </div>
    );
};
