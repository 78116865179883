import {ObjectIndex} from '../../types/common/index/ObjectIndex';
import {ObjectPair} from '../../types/common/object/ObjectPair';

const identity = <T, R>(it: T) => it as unknown as R;
const truthy = () => true;

export type InputPair<T extends any = unknown> = ObjectPair<T>;
export type OutputPair<R extends any = unknown> = ObjectPair<R> | undefined;

export function mapEntries<T extends any = unknown, R extends any = T>(
    o: Record<ObjectIndex, T>,
    mapFn: (entry: InputPair<T>, index: number, array: InputPair<T>[]) => OutputPair<R> = identity,
    filterFn: (value: OutputPair<R>, index: number, array: OutputPair<R>[]) => boolean = truthy,
    sortFn: (a: OutputPair<R>, b: OutputPair<R>) => number = () => 0
): Record<ObjectIndex, R> {
    return Object.entries(o)
        .map(mapFn)
        .filter(filterFn)
        .filter((it): it is [ObjectIndex, R] => typeof it !== 'undefined')
        .sort(sortFn)
        .thru<Record<ObjectIndex, R>>(Object.fromEntries);
}
