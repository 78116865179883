import {Controller} from '../Controller';
import {Witcher} from '../../common/witcher/Witcher';
import {BehaviorSubject, Observable} from 'rxjs';
import {RolesWitcherData} from './RolesWitcherData';
import * as yup from 'yup';
import {tAsString} from '../../common/helpers/react/text/tAsString';

export class RolesWitcherController extends Controller {
    #createWitcher$: BehaviorSubject<Witcher<RolesWitcherData>> = new BehaviorSubject(this.#getNewRolesWitcher());

    resetCreateWitcher(): void {
        this.#createWitcher$.next(this.#getNewRolesWitcher());
    }

    getCreateWitcher$(): Observable<Witcher<RolesWitcherData>> {
        return this.#createWitcher$;
    }

    #getNewRolesWitcher(data?: RolesWitcherData): Witcher<RolesWitcherData> {
        let witcher = new Witcher<RolesWitcherData>({
            // pass
        }, [
            {
                name: 'define',
                label: 'Define role',
                validator: async (data) => {
                    const yupValidator = yup.object({
                        roleName: yup
                            .string()
                            .required(tAsString('PROVIDE_A_ROLE_NAME'))
                            .matches(/^[a-zA-Z0-9_]*$/, tAsString('ONLY_ALPHANUMERICS_AND_UNDERSCORES'))
                            .max(100, tAsString('MAXIMUM_X_CHARACTERS', {
                                count: 100
                            }))
                    });

                    await yupValidator.validate(data);
                }
            }
        ]);

        if (data) {
            witcher.setData(data);
        }

        return witcher;
    }
}