import {RxGroup} from '../../../../../common/model/db/types/RxGroup';
import GroupsTableRowMenu from './show-many/GroupsTableRowMenu';
import {ColumnDescription} from '../../../common/components/table/ColumnDescription';
import {useI18Next} from '../../../../../common/helpers/react/hooks/useI18Next';

export function useGroupsTableColumns(): ColumnDescription<RxGroup>[] {
    const {t} = useI18Next();

    return [
        {
            name: 'name',
            header: t('GROUP_NAME'),
            size: 4
        },
        {
            name: 'description',
            header: t('DESCRIPTION'),
            size: 5,
            undefinedFallback: '',
            hiddenWhen: 'lte-sm'
        },
        {
            name: 'total_agents',
            header: t('TOTAL_ASSIGNEES'),
            size: 2,
            hiddenWhen: 'lte-md'
        },
        {
            ariaLabel: t('TOOLS'),
            size: 1,
            render: (row: RxGroup) => <GroupsTableRowMenu row={row}/>,
            align: 'right',
            cellProps: () => ({
                style: {
                    paddingTop: 0,
                    paddingBottom: 0
                }
            }),
            hideInCheckboxMode: true
        }
    ];
}
