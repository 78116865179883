/* tslint:disable */
/* eslint-disable */
/**
 * SR AAT specification
 * Description
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Agency } from './Agency';
import {
    AgencyFromJSON,
    AgencyFromJSONTyped,
    AgencyToJSON,
} from './Agency';
import type { Agent } from './Agent';
import {
    AgentFromJSON,
    AgentFromJSONTyped,
    AgentToJSON,
} from './Agent';
import type { Pcc } from './Pcc';
import {
    PccFromJSON,
    PccFromJSONTyped,
    PccToJSON,
} from './Pcc';
import type { Status } from './Status';
import {
    StatusFromJSON,
    StatusFromJSONTyped,
    StatusToJSON,
} from './Status';
import type { StatusCodes } from './StatusCodes';
import {
    StatusCodesFromJSON,
    StatusCodesFromJSONTyped,
    StatusCodesToJSON,
} from './StatusCodes';

/**
 * 
 * @export
 * @interface GroupParticipantsResponse
 */
export interface GroupParticipantsResponse extends Status {
    /**
     * 
     * @type {Agency}
     * @memberof GroupParticipantsResponse
     */
    i18nLvl?: Agency;
    /**
     * 
     * @type {Agency}
     * @memberof GroupParticipantsResponse
     */
    cntrLvl?: Agency;
    /**
     * 
     * @type {Pcc}
     * @memberof GroupParticipantsResponse
     */
    pccLvl?: Pcc;
    /**
     * 
     * @type {number}
     * @memberof GroupParticipantsResponse
     */
    cntrNo?: number;
    /**
     * 
     * @type {number}
     * @memberof GroupParticipantsResponse
     */
    pccNo?: number;
    /**
     * 
     * @type {Array<Agency>}
     * @memberof GroupParticipantsResponse
     */
    cntrLst?: Array<Agency>;
    /**
     * 
     * @type {Array<Pcc>}
     * @memberof GroupParticipantsResponse
     */
    pccLst?: Array<Pcc>;
    /**
     * 
     * @type {Array<Agent>}
     * @memberof GroupParticipantsResponse
     */
    agentLst?: Array<Agent>;
}

/**
 * Check if a given object implements the GroupParticipantsResponse interface.
 */
export function instanceOfGroupParticipantsResponse(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function GroupParticipantsResponseFromJSON(json: any): GroupParticipantsResponse {
    return GroupParticipantsResponseFromJSONTyped(json, false);
}

export function GroupParticipantsResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GroupParticipantsResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        ...StatusFromJSONTyped(json, ignoreDiscriminator),
        'i18nLvl': !exists(json, 'i18nLvl') ? undefined : AgencyFromJSON(json['i18nLvl']),
        'cntrLvl': !exists(json, 'cntrLvl') ? undefined : AgencyFromJSON(json['cntrLvl']),
        'pccLvl': !exists(json, 'pccLvl') ? undefined : PccFromJSON(json['pccLvl']),
        'cntrNo': !exists(json, 'cntrNo') ? undefined : json['cntrNo'],
        'pccNo': !exists(json, 'pccNo') ? undefined : json['pccNo'],
        'cntrLst': !exists(json, 'cntrLst') ? undefined : ((json['cntrLst'] as Array<any>).map(AgencyFromJSON)),
        'pccLst': !exists(json, 'pccLst') ? undefined : ((json['pccLst'] as Array<any>).map(PccFromJSON)),
        'agentLst': !exists(json, 'agentLst') ? undefined : ((json['agentLst'] as Array<any>).map(AgentFromJSON)),
    };
}

export function GroupParticipantsResponseToJSON(value?: GroupParticipantsResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        ...StatusToJSON(value),
        'i18nLvl': AgencyToJSON(value.i18nLvl),
        'cntrLvl': AgencyToJSON(value.cntrLvl),
        'pccLvl': PccToJSON(value.pccLvl),
        'cntrNo': value.cntrNo,
        'pccNo': value.pccNo,
        'cntrLst': value.cntrLst === undefined ? undefined : ((value.cntrLst as Array<any>).map(AgencyToJSON)),
        'pccLst': value.pccLst === undefined ? undefined : ((value.pccLst as Array<any>).map(PccToJSON)),
        'agentLst': value.agentLst === undefined ? undefined : ((value.agentLst as Array<any>).map(AgentToJSON)),
    };
}

