import DefaultTable, {
    DefaultTableCheckboxModeProps
} from '../../../../../common/components/table/DefaultTable';
import React, {useMemo} from 'react';
import {useControllers} from '../../../../../../../common/helpers/react/hooks/useControllers';
import {useOnce} from '../../../../../../../common/helpers/react/hooks/useOnce';
import {ColumnDescription} from '../../../../../common/components/table/ColumnDescription';
import {Assignee} from '../../../../../../../common/model/types/assignees/Assignee';
import {tAsString} from '../../../../../../../common/helpers/react/text/tAsString';
import {Icon} from '@sabre/spark-react-core';
import {noop} from '../../../../../../../common/helpers/functions/noop';
import {getAssigneeId} from '../../../../../../../common/model/types/assignees/getAssigneeId';
import {
    AssigneesSearchResultLevel
} from '../../../../../../../common/model/types/assignees/AssigneesSearchResultLevel';
import AssigneeGroups from './AssigneeGroups';
import {getCityAndCountry} from './getCityAndCountry';
import {getAssigneeName} from '../../../../../../../common/model/types/assignees/getAssigneeName';
import {Factory} from '../../../../../../../common/types/common/functions/Factory';
import {AddRemoveInfo} from './AddRemoveInfo';
import {cssClasses} from '../../../../../../../common/helpers/browser/cssClasses';
import {getTableLevelByData} from './getTableLevelByData';
import {asNumber} from '../../../../../../../common/helpers/converters/asNumber';

export type GroupWizardAddAssigneesTableProps = DefaultTableCheckboxModeProps & {
    groupId: number,
    addRemoveInfo: AddRemoveInfo
}

export default function GroupWizardAddAssigneesTable(props: GroupWizardAddAssigneesTableProps) {
    const controllers = useControllers();
    const groups = controllers.groups;

    const getSearchCallFunction: Factory<() => void> = useOnce(() => {
        groups.searchForPossibleAssignees().andWeAreDone();

        return function (row: Assignee) {
            return row.agent
                ? noop()
                : () => groups.searchForPossibleAssignees({
                    id: asNumber(getAssigneeId(row))
                }).andWeAreDone();
        };
    });

    const data = groups.getAssigneesForAddAssigneeTable$(props.groupId, props.addRemoveInfo).asState();
    const columns = useColumns(data, getSearchCallFunction);

    const rowProps = useMemo(() => (row: Assignee) => {
        return {
            onDoubleClick: getSearchCallFunction(row)
        };
    }, []);

    return <DefaultTable
        {...props}
        className={cssClasses(
            'qa-add-assignees-table'
        )}
        idGetter={getAssigneeId}
        data={data}
        columns={columns}
        rowProps={rowProps}
    />;
}

function useTableLevel(data?: Assignee[]): AssigneesSearchResultLevel {
    const groups = useControllers().groups;
    const results = groups.getAssigneesSearchResults$().asState();

    if (results?.level === AssigneesSearchResultLevel.GLOBAL) {
        return getTableLevelByData(data);
    } else if (results?.level === AssigneesSearchResultLevel.SEARCH) {
        return getTableLevelByData(data);
    } else {
        return results?.level ?? AssigneesSearchResultLevel.SEARCH;
    }
}

function useColumns(data: Assignee[] | undefined, getSearchCallFunction: Factory<() => void>) {
    const level = useTableLevel(data);

    const CHEVRON_COLUMN: ColumnDescription<Assignee> = useMemo(() => {
        return {
            size: 1,
            align: 'right',
            ariaLabel: tAsString('LINK'),
            render: function (row: Assignee) {
                return row.agent
                    ? <></>
                    : <a
                        aria-label={tAsString('SEARCH_FOR_ASSIGNEES_IN_X', {
                            name: getAssigneeName(row)
                        })}
                        href={document.location.hash}
                        onClick={getSearchCallFunction(row)}
                    >
                        <Icon name={'arrow-chevron-right'}/>
                    </a>;
            }
        };
    }, []);

    const defaultColumns: ColumnDescription<Assignee>[] = useMemo(() => [
        {
            header: tAsString('NAME'),
            render: row => <>{getAssigneeName(row)}</>
        },
        CHEVRON_COLUMN
    ], []);

    const pccColumns = useMemo<ColumnDescription<Assignee>[]>(() => [
        {
            size: 2,
            header: tAsString('EPR'),
            render: row => <>{row.agent?.epr}</>
        },
        {
            size: 2,
            header: tAsString('AGENT_NAME_HEADER'),
            render: row => <>{row.agent?.name}</>
        },
        {
            size: 2,
            header: tAsString('LOCATION_HEADER'),
            render: row => <>{
                getCityAndCountry(row.agent?.pcc_ref?.location_ref)
            }</>
        },
        {
            size: 3,
            header: tAsString('AGENCY_NAME_HEADER'),
            render: row => <>
                {row.agent?.pcc_ref?.agency_subscriber_name}
            </>
        },
        {
            size: 3,
            header: tAsString('GROUP_NAMES_HEADER'),
            render: row => <AssigneeGroups assignee={row}/>
        }
    ], []);

    const domesticColumns = useMemo<ColumnDescription<Assignee>[]>(() => [
        {
            size: 2,
            header: tAsString('PCC'),
            render: row => <>{getAssigneeName(row)}</>
        },
        {
            size: 2,
            header: tAsString('LOCATION_HEADER'),
            render: row => <>{getCityAndCountry(row.pcc?.location_ref)}</>
        },
        {
            size: 3,
            header: tAsString('AGENCY_NAME_HEADER'),
            render: row => <>
                {row.pcc?.agency_subscriber_name}
            </>
        },
        {
            size: 4,
            header: tAsString('GROUP_NAMES_HEADER'),
            render: row => <AssigneeGroups assignee={row}/>
        },
        CHEVRON_COLUMN
    ], []);

    const internationalColumns = useMemo<ColumnDescription<Assignee>[]>(() => [
        {
            size: 4,
            header: tAsString('NAME'),
            render: row => <>{getAssigneeName(row)}</>
        },
        {
            size: 3,
            header: tAsString('COUNTRY_HEADER'),
            render: row => <>{
                row.agency?.is_international
                    ? tAsString('INTERNATIONAL_AGENCY_LOCATION_PLACEHOLDER')
                    : row.agency?.location_ref?.country ?? tAsString('N/A')
            }</>
        },
        {
            size: 4,
            header: tAsString('GROUP_NAMES_HEADER'),
            render: row => <AssigneeGroups assignee={row}/>
        },
        CHEVRON_COLUMN
    ], []);

    switch (level) {
        case AssigneesSearchResultLevel.PCC:
            return pccColumns;
        case AssigneesSearchResultLevel.DOMESTIC:
            return domesticColumns;
        case AssigneesSearchResultLevel.INTERNATIONAL:
            return internationalColumns;
        case AssigneesSearchResultLevel.SEARCH:
        default:
            return defaultColumns;
    }
}
