import {useParams} from 'react-router-dom';
import {addHash} from '../../../../../common/helpers/browser/addHash';
import {isDefined} from '../../../../../common/types/guards/isDefined';

export function useWizardIdsFromHash() {
    const {step, id} = useParams();

    if (!step) {
        throw new Error(`Wizard is misconfigured! No step defined!`);
    }

    const [stepName, reset] = step.split('.');

    const hash = document.location.hash;
    const lastSlash = hash.lastIndexOf('/');
    const wizardId = `${addHash(hash.substring(0, lastSlash))}`;

    if (hash.substring(lastSlash + 1) !== step) {
        throw new Error(`Wizard is misconfigured! Last part of path must be step variable!`);
    }

    return {
        wizardId,
        step: (stepName ?? '').toLowerCase(),
        reset: !!reset,
        isEditing: isDefined(id)
    };
}
