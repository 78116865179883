import {Controller} from '../Controller';
import {asyncScheduler, BehaviorSubject, combineLatestWith, interval, map, Observable} from 'rxjs';
import {Once} from '../../common/decorators/methods/Once';
import {ModalConfig} from './ModalConfig';
import {NotificationConfig} from './NotificationConfig';
import {distinctLikeABoss} from '../../common/helpers/rxjs/distinctLikeABoss';
import {RxNotification} from '../../common/model/db/types/RxNotification';
import {DataSource} from '../../common/decorators/rxdb/DataSource';
import {DataSourceKind} from '../../common/decorators/rxdb/DataSourceKind';
import {MessageStatus} from '@sabre/spark-react-core/types';
import {getStatusResponse} from '../../common/helpers/json/getStatusResponse';
import {asString} from '../../common/helpers/converters/asString';
import {NotifiedActionConfig} from './NotifiedActionConfig';
import {tAsString} from '../../common/helpers/react/text/tAsString';
import {DB_ID} from '../../common/helpers/id/DB_ID';
import {Throttled} from '../../common/decorators/methods/Throttled';
import {getSignature} from '../../common/helpers/signature/getSignature';
import {THROTTLING_INTERVAL} from '../../common/CONST';
import Comparators from 'comparators';

export class DialogController extends Controller {
    #modals$ = new BehaviorSubject<ModalConfig[]>([]);

    #tsCmpAsc = Comparators.comparing('ts');
    #tsCmpDesc = Comparators.comparing('ts', {reversed: true});

    override init() {
        super.init();
        this.#setupNotificationEffects();
        this.#catchUncaughtErrors();
    }

    @DataSource()
    getAllNotifications$(): Observable<RxNotification[]> {
        return this.db.notifications.$.sort(this.#tsCmpAsc);
    }

    @DataSource(DataSourceKind.PARAMETRIZED)
    getToastNotifications$(limit: number = 1000): Observable<RxNotification[]> {
        return this.db.notifications.$
            .filter(this.#toastNFilterFn)
            .sort(this.#tsCmpDesc)
            .slice(0, limit);
    }

    @DataSource(DataSourceKind.PARAMETRIZED)
    getTooltipNotifications$(limit: number = 1000): Observable<RxNotification[]> {
        return this.db.notifications.$
            .filter(this.#tooltipNFilterFn)
            .sort(this.#tsCmpDesc)
            .slice(0, limit);
    }

    @Throttled({
        // throttling limits number of same messages
        keyFn: (...args) => {
            const signature = getSignature(args)
                .replace(/\..{2,3}:\d+:\d+/g, '');

            return Symbol.for(signature);
        }
    })
    async showNotification(notification: NotificationConfig): Promise<void> {
        await this.db.notifications.upsert({
            ...notification,
            ts: Date.now(),
            id: DB_ID()
        });
    }

    async closeNotification(id: string): Promise<void> {
        this.db.notifications.modifyById(id, it => {
            it.read = true;
        });
    }

    async closeAllNotifications(): Promise<void> {
        this.db.notifications.modify(it => {
            it.read = true;
        });
    }

    async closeAllTooltipNotifications(): Promise<void> {
        this.db.notifications.modify(it => {
            if (it.read === false && it.stick_to !== '') {
                it.read = true;
            }
        });
    }

    async takeNotifiedAction(config: NotifiedActionConfig): Promise<void> {
        await this.getBusyFlag().run(async () => {
            try {
                await config.action();
                await config.finalizer?.();

                const successMessage = config.successMessage
                    || tAsString('SUCCESS_RESULT');

                await this.showNotification({
                    title: tAsString('SUCCESSFUL'),
                    content: successMessage,
                    status: MessageStatus.SUCCESS,
                    ...config.successNotification
                });
            } catch (e: unknown) {
                await config.finalizer?.();

                const status = await getStatusResponse(e);

                const errorMessage = status.statusDescription
                    || asString(e)
                    || config.errorMessage
                    || tAsString('ERROR_RESULT');

                await this.showNotification({
                    title: tAsString('ERROR'),
                    content: errorMessage,
                    status: MessageStatus.ERROR,
                    ...config.errorNotification
                });
            }
        });
    }

    @Once()
    getCurrentModal$(): Observable<ModalConfig | undefined> {
        return this.#modals$
            .pipe(
                map(it => it[0])
            );
    }

    async showModal(modalConfig: ModalConfig): Promise<void> {
        this.#modals$.next([
            modalConfig,
            ...this.#modals$.value
        ]);
    }

    async closeModal(): Promise<void> {
        this.#modals$.next(
            this.#modals$.value.slice(1)
        );
    }

    async closeAllModals(): Promise<void> {
        this.#modals$.next([]);
    }

    useForceUpdate(): unknown {
        return this.getCurrentModal$().asState();
    }

    #toastNFilterFn(it: RxNotification): boolean {
        return it.read === false && it.stick_to === '';
    };

    #tooltipNFilterFn(it: RxNotification): boolean {
        return it.read === false && it.stick_to !== '';
    };

    #catchUncaughtErrors(): void {
        const errorHandler = (e: unknown) => {
            if (e instanceof ErrorEvent && e.message === 'Uncaught TypeError: request is not a function') {
                // FIXME: This is to handle error described in:
                // FIXME: https://ptjira.sabre.com/projects/UXSPR/issues/UXSPR-16
                // FIXME: Remove this IF as soon as ticket is resolved and integrated into SR-AAT
                return;
            }

            this.showNotification({
                title: tAsString('ERROR'),
                content: asString(e),
                status: MessageStatus.ERROR
            });
        };

        window.addEventListener('error', errorHandler);
        window.addEventListener('messageerror', errorHandler);
        window.addEventListener('unhandledrejection', errorHandler);
    }

    #setupNotificationEffects() {
        interval(THROTTLING_INTERVAL)
            .pipe(
                map(() => this.#calculateNotificationEffects()),
                combineLatestWith(this.getTooltipNotifications$()),
                distinctLikeABoss()
            )
            .subscribe(it => {
                this.#applyNotificationEffects(...it);
            });

        document.addEventListener('click', () => {
            this.closeAllTooltipNotifications().andWeAreDone();
        }, true);

        document.addEventListener('keydown', () => {
            this.closeAllTooltipNotifications().andWeAreDone();
        }, true);
    }

    #calculateNotificationEffects(): NotificationEffects {
        const notificationDiv = document.querySelector('.aat-notifications');
        const notificationsHeight = notificationDiv?.getBoundingClientRect().height ?? window.innerHeight;

        const header = document.querySelector('.spark-header');
        const headerBoundaries = header?.getBoundingClientRect();

        const panel = document.querySelector('.aat-toolbar')
            ?? document.querySelector('main .aat-toolbar')
            ?? document.querySelector('main .spark-panel');

        const panelBoundaries = panel?.getBoundingClientRect();

        const maximizedModalHeaderBoundaries = document.querySelector('.aat-modal-header')?.getBoundingClientRect()

        let minimalOffset = notificationsHeight < window.innerHeight
            ? (maximizedModalHeaderBoundaries
                ? maximizedModalHeaderBoundaries.height + 13
                : (headerBoundaries
                    ? headerBoundaries.y + headerBoundaries.height
                    : 0))
            : window.innerHeight - notificationsHeight - 8;

        const notificationsOffset = (
            panelBoundaries
                ? panel?.className.includes('spark-message')
                    ? Math.max(panelBoundaries.y + panelBoundaries.height + 12, minimalOffset)
                    : Math.max(panelBoundaries.y, minimalOffset)
                : minimalOffset
        ) + 18;

        return {
            notificationsOffset,
            notificationsHeight
        };
    }

    #applyNotificationEffects(
        data: NotificationEffects,
        tooltipNotifications: RxNotification[]
    ): void {
        requestAnimationFrame(() => {
            const rootDiv = document.querySelector<HTMLElement>('#root');
            const mainDiv = document.querySelector<HTMLElement>('main');

            if (rootDiv && mainDiv) {
                const rootHeight = data.notificationsHeight + data.notificationsOffset - rootDiv.getBoundingClientRect().y;
                const mainHeight = mainDiv.getBoundingClientRect().height;

                rootDiv.style.height = data.notificationsHeight
                    ? `${rootHeight < mainHeight ? mainHeight : rootHeight}px`
                    : 'auto';
            }

            const notificationsDiv = document.querySelector<HTMLElement>('.aat-notifications');

            if (notificationsDiv) {
                notificationsDiv.style.top = `${Math.floor(data.notificationsOffset)}px`;
            }

            asyncScheduler.schedule(() => {
                tooltipNotifications.forEach(config => {
                    const tooltip = document.querySelector<HTMLElement>(`#${config.stick_to}`);
                    let target = document.querySelector<HTMLElement>(`.${config.stick_to}`);

                    if (target?.className.includes('spark-checkbox')) {
                        target = target.querySelector('.spark-checkbox__box');
                    }

                    if (target && tooltip) {
                        const targetBox = target.getBoundingClientRect();
                        const tooltipBox = tooltip.getBoundingClientRect();

                        const top = targetBox.y - tooltipBox.height + 12;

                        const left = Math.max(
                            4,
                            Math.min(
                                window.innerWidth - tooltipBox.width - 4,
                                targetBox.x + (targetBox.width / 2) - (tooltipBox.width / 2)
                            )
                        );

                        tooltip.style.top = top + 'px';
                        tooltip.style.left = left + 'px';
                        tooltip.style.opacity = '0.9';
                    }
                });
            }, 30);
        });
    }
}

type NotificationEffects = {
    notificationsOffset: number,
    notificationsHeight: number
}
