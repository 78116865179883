import {useI18Next} from '../../../../../common/helpers/react/hooks/useI18Next';
import {ColumnDescription} from '../../../common/components/table/ColumnDescription';
import {RxConfiguration} from '../../../../../common/model/db/types/RxConfiguration';
import {ConfigurationsTableRowMenu} from './ConfigurationsTableRowMenu';

export const useConfigurationsTableColumns = (): ColumnDescription<RxConfiguration>[] => {
    const {t} = useI18Next();

    return [
        {
            name: 'redapp_name',
            header: t('REDAPP_NAME'),
            size: 5
        },
        {
            name: 'configuration_name',
            header: t('CONFIGURATION_NAME'),
            size: 5
        },
        {
            name: 'description',
            header: t('DESCRIPTION'),
            size: 10
        },
        {
            ariaLabel: t('TOOLS'),
            size: 1,
            render: (row: RxConfiguration) => <ConfigurationsTableRowMenu row={row}/>,
            align: 'right',
            cellProps: () => ({
                style: {
                    paddingTop: 0,
                    paddingBottom: 0
                }
            }),
            hideInCheckboxMode: true
        }
    ];
}