import {PropsWithChildren} from 'react';
import {useControllers} from '../../../../common/helpers/react/hooks/useControllers';

export type DevModeProps = PropsWithChildren & {
    fallback?: JSX.Element
};

export default function DevMode(props: DevModeProps): JSX.Element {
    const isDevMode: boolean = useControllers().settings.get$('dev_mode').asState() ?? false;

    if (isDevMode) {
        return <>{props.children}</>;
    }

    return props.fallback ?? <></>;
}
