import React, {PropsWithChildren, useLayoutEffect} from 'react';
import {Message, ScrollToTop} from '@sabre/spark-react-core';
import {asString} from '../../../../common/helpers/converters/asString';
import {MessageRole, MessageStatus, MessageType} from '@sabre/spark-react-core/types';
import {ANIMATE} from '../../../../common/CONST';
import {useI18Next} from '../../../../common/helpers/react/hooks/useI18Next';
import {useControllers} from '../../../../common/helpers/react/hooks/useControllers';

export type ContentProps = PropsWithChildren & {
    state: {
        error?: unknown
    }
}

export default function Content(props: ContentProps) {
    const {t} = useI18Next();

    const layout = useControllers().layout;
    useLayoutEffect(() => layout.scrollToTop());

    return (
        <>
            {
                props.state.error
                    ? <Message
                        title={t('ERROR')}
                        content={asString(props.state.error)}
                        status={MessageStatus.ERROR}
                        type={MessageType.PAGE}
                        role={MessageRole.ALERT}
                        animate={ANIMATE}
                    />
                    : props.children
            }
            <ScrollToTop ariaLabel={t('SCROLL_TO_TOP')}/>
        </>
    );
}
