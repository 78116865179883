import {AnyIndex} from '../../types/common/index/AnyIndex';
import {isArray} from '../../types/guards/isArray';
import {isDefined} from '../../types/guards/isDefined';

export function setMember<T extends object>(o: T, memberPath: AnyIndex[], value: any, override: boolean = true): T {
    if (!memberPath.length) {
        return o;
    }

    // [0] must be defined because .length > 0
    const key = memberPath[0]!;

    // using specific types here is hell, I am sorry
    const result: any = isArray(o)
        ? [...o]
        : {...o};

    // result must be of type object here, so it may have any member set
    if (memberPath.length === 1) {
        if (override || !isDefined(result[key])) {
            result[key] = value;
        } else {
            return o;
        }
    } else {
        result[key] = setMember(result[key] ?? {}, memberPath.slice(1), value);
    }

    return result;
}
