import {MiscDataObject, useMiscData} from '../../../../../common/helpers/react/hooks/useMiscData';
import {DefaultWizardHelpers} from './DefaultWizardHelpers';
import {isDefaultWizardData} from './isDefaultWizardData';
import {useControllers} from '../../../../../common/helpers/react/hooks/useControllers';
import {isString} from '../../../../../common/types/guards/isString';
import {DefaultWizardData} from './DefaultWizardData';
import {MessageStatus} from '@sabre/spark-react-core/types';
import {getCurrentStep} from './getCurrentStep';
import {tAsString} from '../../../../../common/helpers/react/text/tAsString';
import {map, switchMap} from 'rxjs';
import {useOnce} from '../../../../../common/helpers/react/hooks/useOnce';
import {DefaultWizardValidationError} from './DefaultWizardValidationError';
import {asString} from '../../../../../common/helpers/converters/asString';
import {useI18Next} from '../../../../../common/helpers/react/hooks/useI18Next';
import {setMember} from '../../../../../common/helpers/objects/setMember';
import {AnyIndex} from '../../../../../common/types/common/index/AnyIndex';
import {RxJsonObject} from '../../../../../common/types/json/RxJsonObject';
import {sleep} from '../../../../../common/helpers/misc/sleep';
import {mapEntries} from '../../../../../common/helpers/objects/mapEntries';
import {isPlainObject} from '../../../../../common/types/guards/isPlainObject';

export function useDefaultWizard(
    wizardId: string,
    stepName: string
): DefaultWizardHelpers {
    const {t} = useI18Next();
    const controllers = useControllers();
    const settings = controllers.settings;
    const dialog = useControllers().dialog;
    const http = controllers.http;

    const wizardMiscDataId = `wizard::${wizardId}`;
    const stepMiscDataId = `${wizardMiscDataId}/${stepName}`;
    const miscDataIdsRegexp = new RegExp(`^${wizardMiscDataId}.*$`);

    const [wizardData, setWizardData, removeWizardData] = useMiscData(wizardMiscDataId);
    const [stepData, setStepData, removeStepData] = useMiscData(stepMiscDataId);

    const allStepsData$ = useOnce(() => {
        return settings.getAllMiscDataAsMap$(miscDataIdsRegexp)
            .pipe(
                switchMap(async miscData => {
                    const wizardData = miscData[wizardMiscDataId];

                    if (isDefaultWizardData(wizardData)) {
                        const data: Record<string, MiscDataObject> = {};
                        const errors: DefaultWizardValidationError[] = [];

                        for (const it of wizardData.steps) {
                            const miscStepName = `wizard::${wizardId}/${it.name}`;

                            data[it.name] = miscData[miscStepName] ?? await it.getDefaultData();

                            try {
                                await it.onValidate(
                                    data[it.name],
                                    helpers as DefaultWizardHelpers,
                                    data
                                );
                            } catch (e) {
                                let message = asString(e);

                                if (message.includes('but the final value was: `null`')) {
                                    message = t(`STEP_NOT_COMPLETED`, {step: tAsString(it.label)});
                                }

                                errors.push({
                                    message,
                                    stepName: tAsString(it.label),
                                    currentStep: helpers.stepName === it.name
                                });
                            }
                        }

                        return errors.length
                            ? {
                                validated: true,
                                success: false,
                                data,
                                errors
                            }
                            : {
                                validated: true,
                                success: true,
                                data
                            };
                    } else {
                        console.debug(`Cannot validate wizard data`, {wizardData, miscData});

                        return {
                            validated: false,
                            success: true,
                            data: mapEntries(miscData, pair => [
                                asString(pair[0]).substring(wizardMiscDataId.length + 1),
                                pair[1]
                            ])
                        };
                    }
                })
            );
    });

    const stepData$ = useOnce(() => {
        return allStepsData$
            .pipe(
                map(it => it.data[stepName])
            );
    });

    const helpers: DefaultWizardHelpers = {
        wizardData: undefined,
        setWizardData: async function (data: RxJsonObject): Promise<void> {
            return setWizardData(data);
        },
        removeWizardData,

        stepData: undefined,
        setStepData: async function (data: RxJsonObject): Promise<void> {
            return setStepData(data);
        },
        removeStepData,
        setStepMember: async function (memberPath: AnyIndex[], data: unknown, override: boolean = true) {
            const stepData = await this.stepData$;

            return this.setStepData(
                setMember(stepData ?? {}, memberPath, data, override)
            );
        },

        allStepsData$,
        stepData$,

        removeAllData: async function () {
            await settings.deleteMiscData(miscDataIdsRegexp);
        },
        destroyWizard: async function () {
            await this.removeAllData();

            const cancelPage = wizardData?.cancelPage;

            if (isString(cancelPage)) {
                await http.navigateTo(cancelPage);
            }
        },
        resetWizard: async function (data: DefaultWizardData) {
            return this.removeAllData()
                .then(() => this.setWizardData(data))
                .catch(() => {
                    throw new Error(`Wizard misconfigured! Cannot reset data!`);
                });
        },
        submitWizard: async function () {
            let anyInput: Element | undefined | null;

            const allInputs = document.querySelectorAll('input').values();

            for (let input of allInputs) {
                const rect = input.getBoundingClientRect();

                if (rect.y > 0) {
                    anyInput = input;
                    break;
                }
            }

            if (!anyInput) {
                anyInput = document.activeElement instanceof HTMLElement
                    ? document.activeElement
                    : document.querySelector<HTMLElement>('input');
            }

            if (anyInput instanceof HTMLElement) {
                anyInput.focus();
                anyInput.blur();
            }

            await sleep();
            const allData = await this.allStepsData$;

            allData.errors
                ?.filter(it => !it.currentStep)
                ?.forEach(it => {
                    const prefix = tAsString('X_STEP_VALIDATION_ERROR', {
                        step: it.stepName.toTitleCase()
                    });

                    dialog.showNotification({
                        status: MessageStatus.ERROR,
                        content: `${prefix}. ${it.message}`
                    });
                });

            if (!allData.success) {
                return;
            }

            if (wizardData && isDefaultWizardData(wizardData)) {
                return wizardData.onSubmit(allData.data, this);
            }
        },

        step: undefined,
        stepName,
        stepIndex: -1,

        isLastStep: false,
        isFirstStep: false,

        isLoading: false
    };

    if (isDefaultWizardData(wizardData)) {
        const currentStep = getCurrentStep(
            isDefaultWizardData(wizardData) ? wizardData.steps : [],
            stepName
        );

        helpers.step = wizardData
            ? currentStep
            : null;

        if (helpers.step) {
            helpers.stepIndex = wizardData.steps.indexOf(helpers.step);

            if (helpers.stepIndex === wizardData.steps.length - 1) {
                helpers.isLastStep = true;
            }

            if (helpers.stepIndex === 0) {
                helpers.isFirstStep = true;
            }
        }

        helpers.wizardData = wizardData;
    }

    if (stepData === null || isPlainObject(stepData)) {
        helpers.stepData = stepData;
    }

    helpers.isLoading = !helpers.wizardData || helpers.stepData === undefined;

    return helpers;
}
