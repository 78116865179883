/* tslint:disable */
/* eslint-disable */
/**
 * SR AAT specification
 * Description
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { RedAppPlacementType } from './RedAppPlacementType';
import {
    RedAppPlacementTypeFromJSON,
    RedAppPlacementTypeFromJSONTyped,
    RedAppPlacementTypeToJSON,
} from './RedAppPlacementType';

/**
 * 
 * @export
 * @interface RedAppPlacementSetting
 */
export interface RedAppPlacementSetting {
    /**
     * 
     * @type {number}
     * @memberof RedAppPlacementSetting
     */
    productId: number;
    /**
     * 
     * @type {RedAppPlacementType}
     * @memberof RedAppPlacementSetting
     */
    redAppPlacement: RedAppPlacementType;
    /**
     * 
     * @type {string}
     * @memberof RedAppPlacementSetting
     */
    redAppConfiguration: string;
}

/**
 * Check if a given object implements the RedAppPlacementSetting interface.
 */
export function instanceOfRedAppPlacementSetting(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "productId" in value;
    isInstance = isInstance && "redAppPlacement" in value;
    isInstance = isInstance && "redAppConfiguration" in value;

    return isInstance;
}

export function RedAppPlacementSettingFromJSON(json: any): RedAppPlacementSetting {
    return RedAppPlacementSettingFromJSONTyped(json, false);
}

export function RedAppPlacementSettingFromJSONTyped(json: any, ignoreDiscriminator: boolean): RedAppPlacementSetting {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'productId': json['productId'],
        'redAppPlacement': RedAppPlacementTypeFromJSON(json['redAppPlacement']),
        'redAppConfiguration': json['redAppConfiguration'],
    };
}

export function RedAppPlacementSettingToJSON(value?: RedAppPlacementSetting | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'productId': value.productId,
        'redAppPlacement': RedAppPlacementTypeToJSON(value.redAppPlacement),
        'redAppConfiguration': value.redAppConfiguration,
    };
}

