import {DefaultWizardProps} from './DefaultWizard';

export function getWizardData(props: DefaultWizardProps) {
    return {
        wizardId: props.wizardId,
        steps: props.steps?.map(it => {
            return {
                name: it.name.toLowerCase(),
                label: it.label,
                disabled: it.disabled ?? false,
                onValidate: it.onValidate ?? async function (data) {
                    // pass
                },
                getDefaultData: it.getDefaultData ?? async function () {
                    return null;
                },
                onBeforeLoaded: it.onBeforeLoaded ?? async function () {
                    // pass
                }
            };
        }) ?? [],
        cancelPage: props.cancelPage ?? '#',
        onSubmit: props.onSubmit ?? async function () {
            // pass
        },
        onBeforeLoaded: props.onBeforeLoaded ?? async function () {
            // pass
        }
    };
}
