/* tslint:disable */
/* eslint-disable */
/**
 * SR AAT specification
 * Description
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { PartialGroup } from './PartialGroup';
import {
    PartialGroupFromJSON,
    PartialGroupFromJSONTyped,
    PartialGroupToJSON,
} from './PartialGroup';
import type { PropertyUpdate } from './PropertyUpdate';
import {
    PropertyUpdateFromJSON,
    PropertyUpdateFromJSONTyped,
    PropertyUpdateToJSON,
} from './PropertyUpdate';
import type { RedAppPlacementSetting } from './RedAppPlacementSetting';
import {
    RedAppPlacementSettingFromJSON,
    RedAppPlacementSettingFromJSONTyped,
    RedAppPlacementSettingToJSON,
} from './RedAppPlacementSetting';
import type { WorkspaceEnablement } from './WorkspaceEnablement';
import {
    WorkspaceEnablementFromJSON,
    WorkspaceEnablementFromJSONTyped,
    WorkspaceEnablementToJSON,
} from './WorkspaceEnablement';

/**
 * 
 * @export
 * @interface UpdateGroupRequest
 */
export interface UpdateGroupRequest {
    /**
     * 
     * @type {PartialGroup}
     * @memberof UpdateGroupRequest
     */
    group: PartialGroup;
    /**
     * 
     * @type {Array<WorkspaceEnablement>}
     * @memberof UpdateGroupRequest
     */
    workspace?: Array<WorkspaceEnablement>;
    /**
     * 
     * @type {Array<RedAppPlacementSetting>}
     * @memberof UpdateGroupRequest
     */
    redapps?: Array<RedAppPlacementSetting>;
    /**
     * 
     * @type {Array<PropertyUpdate>}
     * @memberof UpdateGroupRequest
     */
    properties?: Array<PropertyUpdate>;
    /**
     * 
     * @type {Array<number>}
     * @memberof UpdateGroupRequest
     */
    addedCmGroupIds?: Array<number>;
    /**
     * 
     * @type {Array<number>}
     * @memberof UpdateGroupRequest
     */
    removedCmGroupIds?: Array<number>;
}

/**
 * Check if a given object implements the UpdateGroupRequest interface.
 */
export function instanceOfUpdateGroupRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "group" in value;

    return isInstance;
}

export function UpdateGroupRequestFromJSON(json: any): UpdateGroupRequest {
    return UpdateGroupRequestFromJSONTyped(json, false);
}

export function UpdateGroupRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): UpdateGroupRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'group': PartialGroupFromJSON(json['group']),
        'workspace': !exists(json, 'workspace') ? undefined : ((json['workspace'] as Array<any>).map(WorkspaceEnablementFromJSON)),
        'redapps': !exists(json, 'redapps') ? undefined : ((json['redapps'] as Array<any>).map(RedAppPlacementSettingFromJSON)),
        'properties': !exists(json, 'properties') ? undefined : ((json['properties'] as Array<any>).map(PropertyUpdateFromJSON)),
        'addedCmGroupIds': !exists(json, 'addedCmGroupIds') ? undefined : json['addedCmGroupIds'],
        'removedCmGroupIds': !exists(json, 'removedCmGroupIds') ? undefined : json['removedCmGroupIds'],
    };
}

export function UpdateGroupRequestToJSON(value?: UpdateGroupRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'group': PartialGroupToJSON(value.group),
        'workspace': value.workspace === undefined ? undefined : ((value.workspace as Array<any>).map(WorkspaceEnablementToJSON)),
        'redapps': value.redapps === undefined ? undefined : ((value.redapps as Array<any>).map(RedAppPlacementSettingToJSON)),
        'properties': value.properties === undefined ? undefined : ((value.properties as Array<any>).map(PropertyUpdateToJSON)),
        'addedCmGroupIds': value.addedCmGroupIds,
        'removedCmGroupIds': value.removedCmGroupIds,
    };
}

