import {ProgressIndicatorSize} from '@sabre/spark-react-core/types';
import styles from './LoadingProgress.module.css';
import {useI18Next} from '../../../../../common/helpers/react/hooks/useI18Next';
import {useTimeout} from '../../../../../common/helpers/react/hooks/useTimeout';
import LoadingContent from './LoadingContent';
import {cssClasses} from '../../../../../common/helpers/browser/cssClasses';
import {ProgressIndicator} from '@sabre/spark-react-core';

type LoadingProgressProps = {
    timeout?: number;
    reason?: string;
}

export default function LoadingProgress(props: LoadingProgressProps) {
    const {t} = useI18Next();

    const timeout = useTimeout(props.timeout);

    if (timeout) {
        return <LoadingContent timeout={0}/>;
    }

    return (
        <>
            <div className={styles.Background}></div>
            <div className={styles.Wrapper} aria-live="assertive" role="alert"
                 aria-label={t('LOADING')}>
                <ProgressIndicator
                    className={cssClasses(styles.LoadingProgress, 'qa-loading-progress')}
                    type={'indeterminate'}
                    size={ProgressIndicatorSize.SMALL}
                />
            </div>
        </>
    );
};
