/* tslint:disable */
/* eslint-disable */
/**
 * SR AAT specification
 * Description
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Version } from './Version';
import {
    VersionFromJSON,
    VersionFromJSONTyped,
    VersionToJSON,
} from './Version';

/**
 * 
 * @export
 * @interface GetVersion200Response
 */
export interface GetVersion200Response {
    /**
     * 
     * @type {string}
     * @memberof GetVersion200Response
     */
    timestamp: string;
    /**
     * 
     * @type {string}
     * @memberof GetVersion200Response
     */
    branch: string;
    /**
     * 
     * @type {string}
     * @memberof GetVersion200Response
     */
    checksum: string;
    /**
     * 
     * @type {string}
     * @memberof GetVersion200Response
     */
    version: string;
}

/**
 * Check if a given object implements the GetVersion200Response interface.
 */
export function instanceOfGetVersion200Response(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "timestamp" in value;
    isInstance = isInstance && "branch" in value;
    isInstance = isInstance && "checksum" in value;
    isInstance = isInstance && "version" in value;

    return isInstance;
}

export function GetVersion200ResponseFromJSON(json: any): GetVersion200Response {
    return GetVersion200ResponseFromJSONTyped(json, false);
}

export function GetVersion200ResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GetVersion200Response {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'timestamp': json['timestamp'],
        'branch': json['branch'],
        'checksum': json['checksum'],
        'version': json['version'],
    };
}

export function GetVersion200ResponseToJSON(value?: GetVersion200Response | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'timestamp': value.timestamp,
        'branch': value.branch,
        'checksum': value.checksum,
        'version': value.version,
    };
}

