/* tslint:disable */
/* eslint-disable */
/**
 * SR AAT specification
 * Description
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { AuthorizationStatusCodes } from './AuthorizationStatusCodes';
import {
    AuthorizationStatusCodesFromJSON,
    AuthorizationStatusCodesFromJSONTyped,
    AuthorizationStatusCodesToJSON,
} from './AuthorizationStatusCodes';
import type { Status } from './Status';
import {
    StatusFromJSON,
    StatusFromJSONTyped,
    StatusToJSON,
} from './Status';
import type { StatusCodes } from './StatusCodes';
import {
    StatusCodesFromJSON,
    StatusCodesFromJSONTyped,
    StatusCodesToJSON,
} from './StatusCodes';

/**
 * 
 * @export
 * @interface AuthorizationStatus
 */
export interface AuthorizationStatus extends Status {
    /**
     * 
     * @type {string}
     * @memberof AuthorizationStatus
     */
    authDescription?: string;
    /**
     * 
     * @type {string}
     * @memberof AuthorizationStatus
     */
    adminType?: string;
    /**
     * 
     * @type {AuthorizationStatusCodes}
     * @memberof AuthorizationStatus
     */
    authStatusCode: AuthorizationStatusCodes;
}

/**
 * Check if a given object implements the AuthorizationStatus interface.
 */
export function instanceOfAuthorizationStatus(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "authStatusCode" in value;

    return isInstance;
}

export function AuthorizationStatusFromJSON(json: any): AuthorizationStatus {
    return AuthorizationStatusFromJSONTyped(json, false);
}

export function AuthorizationStatusFromJSONTyped(json: any, ignoreDiscriminator: boolean): AuthorizationStatus {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        ...StatusFromJSONTyped(json, ignoreDiscriminator),
        'authDescription': !exists(json, 'authDescription') ? undefined : json['authDescription'],
        'adminType': !exists(json, 'adminType') ? undefined : json['adminType'],
        'authStatusCode': AuthorizationStatusCodesFromJSON(json['authStatusCode']),
    };
}

export function AuthorizationStatusToJSON(value?: AuthorizationStatus | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        ...StatusToJSON(value),
        'authDescription': value.authDescription,
        'adminType': value.adminType,
        'authStatusCode': AuthorizationStatusCodesToJSON(value.authStatusCode),
    };
}

