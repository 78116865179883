import {ProductState} from './ProductState';
import {isNumber} from '../../../types/guards/isNumber';
import {ProductType} from './ProductType';
import {RedAppPlacementType} from '../../../../core/rest-client/aat';
import {isStringEnumValue} from '../../../types/guards/isStringEnumValue';
import {asInt} from '../../../helpers/converters/asInt';

export const isProductPlacement = (o?: any): o is ProductState => {
    return isProduct(o) && isStringEnumValue(o?.redAppPlacement, RedAppPlacementType);
}

export const isProduct = (o?: any): o is ProductState => {
    return isNumber(asInt(o?.id)) && isStringEnumValue(o?.type, ProductType);
}