import {CoreEntryPoint} from '../../../core/CoreEntryPoint';
import {Controller} from '../../../controllers/Controller';
import {WebToolsEntryPoint} from '../../../clients/web-tools/WebToolsEntryPoint';
import {controllers} from '../../controllers';
import {Config_core} from '../../../common/core/entry-point/Config_core';
import {services} from '../../services';
import {i18n} from '../../i18n';
import {ReactAppEntryPoint} from '../../../clients/react-app/ReactAppEntryPoint';

export async function main() {
    await i18n();

    const cfg = Config_core.create({
        controllers,
        services
    });

    await Controller.feed(cfg);

    await new CoreEntryPoint(cfg).start();
    await new WebToolsEntryPoint(cfg).start();
    await new ReactAppEntryPoint(cfg).start();
}