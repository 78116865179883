import {LayoutController} from '../../../controllers/layout/LayoutController';
import {SettingsController} from '../../../controllers/settings/SettingsController';
import {PartialToLevel2} from '../../types/utility/partial/PartialToLevel2';
import {AuthController} from '../../../controllers/auth/AuthController';
import {GroupsController} from '../../../controllers/groups/GroupsController';
import {getMockedController} from '../../helpers/mocks/getMockedController';
import {HttpController} from '../../../core/services/http/HttpController';
import {DialogController} from '../../../controllers/dialog/DialogController';
import {ConfigurationsController} from '../../../controllers/configurations/ConfigurationsController';
import {RedAppsController} from '../../../controllers/redApps/RedAppsController';
import {RolesController} from "../../../controllers/roles/RolesController";
import {RolesWitcherController} from "../../../controllers/roles/RolesWitcherController";

export class Config_common {
    readonly controllers = {
        dialog: getMockedController<DialogController>(),
        http: getMockedController<HttpController>(),
        layout: getMockedController<LayoutController>(),
        settings: getMockedController<SettingsController>(),
        auth: getMockedController<AuthController>(),
        groups: getMockedController<GroupsController>(),
        configurations: getMockedController<ConfigurationsController>(),
        redApps: getMockedController<RedAppsController>(),
        roles: getMockedController<RolesController>(),
        rolesWitcher: getMockedController<RolesWitcherController>()
    } as const;

    static create(...cfg: PartialToLevel2<Config_common>[]): Config_common {
        return cfg.reduce<Config_common>((result, next) => {
            return {
                controllers: {
                    ...result.controllers,
                    ...next.controllers
                }
            };
        }, new Config_common());
    }
}
