import {ColumnDescription} from './ColumnDescription';
import {useI18Next} from '../../../../../common/helpers/react/hooks/useI18Next';
import {isCheckboxMode} from './helpers/isCheckboxMode';
import {asString} from '../../../../../common/helpers/converters/asString';
import {DefaultTableProps} from './DefaultTable';

export function useNormalizedColumns<T extends NonNullable<unknown>>(props: DefaultTableProps<T>): ColumnDescription<T>[] {
    const {t} = useI18Next();

    const checkboxMode = isCheckboxMode(props);
    const knownColumns = ['id', 'status', 'ts', 'order'];
    const data = props.data?.[0];

    return (
        props.columns
            ? [...props.columns]
            : data
                ? ([] as ColumnDescription<T>[])
                    .thru(columns => {
                        for (let name in data) {
                            const header = t(name.toUpperCase());
                            const method = knownColumns.includes(name) ? 'unshift' : 'push';

                            columns[method]({
                                name: name,
                                header: header,
                                ariaLabel: header,
                                undefinedFallback: 'n/a'
                            });
                        }

                        return columns;
                    })
                : []
    )
        .map(it => {
            return {
                ...it,
                size: it.size && it.size > 0
                    ? it.size
                    : knownColumns.includes(asString(it.name))
                        ? 1
                        : data && typeof data[it.name!] === 'string'
                            ? 3
                            : 2
            };
        })
        .tap(arr => {
            arr.forEach((it, idx) => {
                const heir = idx
                    ? arr[idx - 1]
                    : arr[idx + 1];

                if (heir && checkboxMode && it.hideInCheckboxMode) {
                    heir.size += it.size;
                }
            });
        })
        .filter(it => !(checkboxMode && it.hideInCheckboxMode))
        .thru(arr => {
            const totalSize = arr.reduce((a, b) => a + b.size, 0);

            return arr.map(it => {
                return {
                    ...it,
                    size: props.forceSize
                        ? it.size
                        : Math.max(Math.round(12 * it.size / totalSize), 1)
                };
            });
        });
}
