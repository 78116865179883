import {Button, Panel} from '@sabre/spark-react-core';
import styles from './Toolbar.module.css';
import {renderMany} from '../../../../../common/helpers/react/renderMany';
import {cssClasses} from '../../../../../common/helpers/browser/cssClasses';
import {ReactNode} from 'react';
import {ButtonConfig} from '../../../../../controllers/dialog/ButtonConfig';

export type ToolbarProps = {
    content?: ReactNode
    buttons?: ButtonConfig[];
    className?: string;
    contentClassName?: string;
    buttonsClassName?: string;
};

export default function Toolbar(props: ToolbarProps) {
    const buttonConfigs: ButtonConfig[] = props.buttons?.map(it => {
        return {
            ...it,
            className: cssClasses(
                it.className,
                it.textOnly ? styles.TextOnlyButton : styles.ActionButton
            )
        };
    }) ?? [];

    const contentCols = Math.max(12 - (2 * (props.buttons?.length ?? 0)), 5);
    const buttonsCols = 12 - contentCols;

    return (
        <Panel className={cssClasses(
            styles.Toolbar,
            props.className,
            'aat-toolbar'
        )}
        >
            <section className={'row'}>
                <header className={cssClasses(
                    styles.Content,
                    `col-md-${contentCols}`,
                    props.contentClassName,
                    'qa-toolbar-header'
                )}>
                    {props.content}
                </header>
                <div className={cssClasses(
                    styles.Buttons,
                    `col-md-${buttonsCols}`,
                    props.buttonsClassName,
                    props.buttons?.length ? `` : styles.NoButtons
                )}>
                    {renderMany(Button, buttonConfigs, {
                        children: ''
                    })}
                </div>
            </section>
        </Panel>
    );
}