import {Icon, Toggle, ToggleGroup, Tooltip} from '@sabre/spark-react-core';
import {cssClasses} from '../../../../../common/helpers/browser/cssClasses';
import styles from './DefaultToggle.module.css';
import {default as React, useState} from 'react';
import {ToggleOptionDescription} from './ToggleOptionDescription';
import {tAsString} from '../../../../../common/helpers/react/text/tAsString';
import {KEY} from '../../../../../common/helpers/id/KEY';

export type DefaultToggleProps = {
    name: string;
    onChange?: (value?: string) => void;
    options: ToggleOptionDescription[];
    initialValue?: string;
    condensed?: boolean;
    ariaLabel?: string;
    className?: string;
}

export default function DefaultToggle(props: DefaultToggleProps) {
    const [value, setValue] = useState<string | undefined>(props.initialValue);

    const currentOption = props.options.filter(it => it.value === value)[0];
    const currentColor = currentOption?.color;

    return (
        <ToggleGroup
            condensed={props.condensed}
            name={`toggle::${props.name}`}
            className={cssClasses(
                styles.Toggle,
                'aat-toggle',
                currentColor ? `aat-toggle--${currentColor}` : undefined,
                props.options[0]?.icon ? styles.ZoomOutIcon : styles.ZoomOutText,
                props.className
            )}
            onChange={(e, value) => {
                setValue(value);
                props.onChange?.(value);
            }}
            aria-label={props.ariaLabel}
        >
            {
                props.options.map(it => {
                    const label = it.label ?? tAsString(it.value);

                    const labelOrIcon = it.icon
                        ? <Tooltip toggleEl={<Icon name={it.icon}/>}>{label}</Tooltip>
                        : label;

                    return (
                        <Toggle
                            key={KEY(it)}
                            className={cssClasses(
                                currentOption?.value === it.value ? styles.Active : undefined,
                                styles.Option
                            )}
                            label={labelOrIcon}
                            value={it.value}
                            defaultChecked={it.value === props.initialValue}
                        />
                    );
                })
            }
        </ToggleGroup>
    );
}
