import {tAsString} from '../../../../../../common/helpers/react/text/tAsString';
import {Panel, Step, StepIndicator} from '@sabre/spark-react-core';
import ActionPanel from '../../../../common/components/actionPanel/ActionPanel';
import {ButtonConfig} from '../../../../../../controllers/dialog/ButtonConfig';
import {cssClasses} from '../../../../../../common/helpers/browser/cssClasses';
import {useControllers} from '../../../../../../common/helpers/react/hooks/useControllers';
import React from 'react';
import {StepIndicatorType} from '@sabre/spark-react-core/types';
import {ensure} from '../../../../../../common/types/guards/ensure';
import {isDefined} from '../../../../../../common/types/guards/isDefined';
import RolesWitcherForm from './RolesWitcherForm';

export default function RolesWitcherPresenter() {
    const controllers = useControllers();
    const roles = controllers.roles;
    const http = controllers.http;
    const dialog = controllers.dialog;
    const rolesWitcher = controllers.rolesWitcher;

    const witcher = rolesWitcher.getCreateWitcher$().asState();

    async function submit() {
        witcher?.takeNotifiedAction(dialog, data => {
            return {
                action: async () => {
                    ensure(isDefined, data?.roleName);

                    const createdRole = await roles.createRole({
                        name: data?.roleName!,
                        notes: data?.notes
                    });

                    roles.clearSearchedRoles();

                    ensure(isDefined, createdRole.id);

                    await roles.showParentRolesForGivenRole(createdRole.id!, true);
                    await http.navigateTo('/roles/search');

                    rolesWitcher.resetCreateWitcher();
                },
                successMessage: tAsString('THE_X_ROLE_CREATION_SUCCESS', {
                    name: data?.roleName
                }),
                errorMessage: tAsString('THE_X_ROLE_CREATION_FAILURE', {
                    name: data?.roleName
                })
            };
        });
    }

    function close() {
        http.navigateTo('/roles/search').andWeAreDone();
    }

    const actionButtons: ButtonConfig[] = [
        {
            className: cssClasses(
                'qa-cancel-button',
                'aat-autokey-escape'
            ),
            onClick: close,
            children: tAsString('CANCEL'),
            secondary: true
        },
        {
            className: 'qa-save-and-close-button',
            onClick: submit,
            children: tAsString('SAVE_&_CLOSE')
        }
    ];

    const activeStep = witcher?.getCurrentStepIndex(1) ?? 1;
    const completedSteps = [1, 2, 3, 4, 5, 6, 7, 8, 9].filter(it => it < activeStep);

    return (
        <>
            <h1>{tAsString('NEW_ROLE')}</h1>
            <Panel>
                <StepIndicator
                    activeStep={activeStep}
                    type={StepIndicatorType.STANDARD_DROPDOWN}
                    completedSteps={completedSteps}
                    className={'spark-mar-b-2 spark-pad-b-1 spark-pad-t-1'}
                >
                    {
                        witcher?.getAllSteps().map(it => {
                            return <Step label={it.label}/>;
                        })
                    }
                </StepIndicator>
                <RolesWitcherForm submit={submit} witcher={witcher}/>
            </Panel>
            <ActionPanel buttons={actionButtons}/>
        </>
    );
}