import {MapApiExtension} from '../../types/api-extensions/MapApiExtension';

declare global {
    interface Map<K, V> extends MapApiExtension<K, V> {
    }
}

// eslint-disable-next-line no-extend-native
Map.prototype.filter = function <K, V>(
    this: Map<K, V>,
    filterFn: (pair: [K, V]) => boolean
): Map<K, V> {
    return new Map([...this.entries()].filter(filterFn));
};

// eslint-disable-next-line no-extend-native
Map.prototype.filterAs = function <K, V, V2 extends V = V>(
    this: Map<K, V>,
    filterFn: (pair: [K, V]) => pair is [K, V2]
): Map<K, V2> {
    return new Map([...this.entries()].filter(filterFn));
};
