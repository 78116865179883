import {RxConfiguration} from '../../../../../common/model/db/types/RxConfiguration';
import PopoverMenu from '../../../common/components/popover-menu/PopoverMenu';
import {useI18Next} from '../../../../../common/helpers/react/hooks/useI18Next';
import {useControllers} from '../../../../../common/helpers/react/hooks/useControllers';
import {ModalButtons} from '../../../../../controllers/dialog/ModalButtons';
import {asModelConfiguration, ModelConfiguration} from '../../../../../core/rest-client/aat';
import {ensure} from '../../../../../common/types/guards/ensure';
import {isDefined} from '../../../../../common/types/guards/isDefined';
import {tWithHtml} from '../../../../../common/helpers/react/text/tWithHtml';


export type ConfigurationsTableRowMenuPresenterProps = {
    row: RxConfiguration
}

export const ConfigurationsTableRowMenu = ({row}: ConfigurationsTableRowMenuPresenterProps) => {
    const {t} = useI18Next();

    const controllers = useControllers();

    const dialogController = controllers.dialog;
    const configurationsController = controllers.configurations;
    const httpController = controllers.http;

    dialogController.useForceUpdate();

    const showDeleteConfigurationModal = async () => {
        await dialogController.showModal({
            title: t('DELETE_CONFIGURATION'),
            children: tWithHtml('CONFIGURATION_X_WILL_BE_DELETED', {name: row.configuration_name}),
            buttons: [
                ModalButtons.CANCEL(dialogController),
                {
                    children: t('DELETE'),
                    ariaLabel: t('DELETE_X_CONFIGURATION', {name: row.configuration_name}),
                    className: 'qa-modal-delete-button',
                    onClick: () => {
                        const configuration = asModelConfiguration(row);
                        ensure<ModelConfiguration>(isDefined, configuration);

                        dialogController.takeNotifiedAction({
                            action: async () => {
                                await configurationsController.deleteConfigurationsByIds([configuration.id]);
                            },
                            successMessage: t('THE_X_CONFIGURATION_HAS_BEEN_DELETED', {
                                name: configuration.configurationName
                            }),
                            errorMessage: t('THE_X_CONFIGURATION_DELETION_FAILED', {
                                name: configuration.configurationName
                            }),
                            finalizer: async () => {
                                await dialogController.closeModal();
                                await configurationsController.refreshConfigurations();
                            }
                        });
                    }
                }
            ]

        })
    };

    return <PopoverMenu
        icon={'menu-ellipsis-vertical'}
        options={[
            [
                {
                    className: 'qa-configuration-edit-link',
                    caption: t('EDIT_CONFIGURATION'),
                    ariaLabel: t('EDIT_X_CONFIGURATION', {name: row.configuration_name}),
                    onClick: () => httpController.navigateTo(`configurations/edit/${row.id}`)
                }
            ], [
                {
                    className: 'qa-popover-delete-link',
                    caption: t('DELETE'),
                    ariaLabel: t('DELETE_X_CONFIGURATION', {name: row.configuration_name}),
                    onClick: showDeleteConfigurationModal
                }
            ]
        ]}
    />;
}