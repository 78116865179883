import {QxCollectionOptions} from './QxCollectionOptions';
import {asString} from '../../../converters/asString';
import {BehaviorSubject, map, Observable} from 'rxjs';
import {Bound} from '../../../../decorators/methods/Bound';
import {QxAction} from './QxAction';
import {asArray} from '../../../converters/asArray';
import {isDescribedByJsonSchema} from '../../../../types/guards/isDescribedByJsonSchema';
import {throttleLikeABoss} from '../../throttleLikeABoss';
import {JSONSchema4} from 'json-schema';
import {Cached} from '../../../../decorators/methods/Cached';
import {as} from '../../../converters/as';
import {isActiveDocument} from '../../../../types/guards/isActiveDocument';

export class QxCollection<T> {
    readonly name: string;
    #docsMap$ = new BehaviorSubject<Map<string, T>>(new Map<string, T>());
    #docsMap$_throttled: Observable<Map<string, T>>;
    #docs$ = new BehaviorSubject<T[]>([]);

    constructor(private options: QxCollectionOptions<T>) {
        this.#docsMap$_throttled = this.#docsMap$.pipe(
            throttleLikeABoss(options.dataThrottling ?? 0)
        );

        this.#docsMap$_throttled.subscribe(it => {
            this.#docs$.next([...it.values()]);
        });

        this.name = options.name;
    }

    get $(): Observable<T[]> {
        return this.#docs$;
    }

    get _(): T[] {
        return this.#docs$.value;
    }

    get active_(): T[] {
        return this._.filter(isActiveDocument);
    }

    get active$(): Observable<T[]> {
        return this.$.filter(isActiveDocument);
    }

    get __(): Map<string, T> {
        return this.#docsMap$.value;
    }

    get $$(): Observable<Map<string, T>> {
        return this.#docsMap$_throttled;
    }

    getById(id: string): T | null {
        return this.#docsMap$.value.get(id) ?? null;
    }

    @Cached()
    getById$(id: string): Observable<T | null> {
        return this.#docsMap$.pipe(
            map(() => this.getById(id))
        );
    }

    @Bound()
    getId(doc: Partial<T>): string {
        const primaryKey = this.getPrimaryKey();
        return asString(doc[primaryKey] ?? '');
    }

    delete(idOrDoc: string | Partial<T>): void {
        const id = typeof idOrDoc === 'string'
            ? idOrDoc
            : this.getId(idOrDoc);

        if (id !== '') {
            this.#docsMap$.value.delete(id);
            this.emit();
        }
    }

    upsert(docs: Partial<T> | Partial<T>[]): void {
        const docProperties = this.getDocPropertyNames();

        // using .forEach() to have only one iteration over docs
        asArray(docs).forEach(newDoc => {
            const id = this.getId(newDoc);

            if (!id) {
                return;
            } else if (this.#docsMap$.value.has(id)) {
                const currentDoc = this.#docsMap$.value.get(id)!;

                docProperties.forEach(it => {
                    const newValue = newDoc[it];

                    if (newValue !== undefined) {
                        currentDoc[it] = newValue as NonNullable<T>[keyof T];
                    }
                });

                this.normalize(currentDoc);
                this.validate(id, currentDoc);
            } else {
                this.normalize(newDoc);

                if (this.validate(id, newDoc)) {
                    this.#docsMap$.value.set(id, newDoc);
                }
            }
        });

        this.emit();
    }

    overwrite(data: Partial<T> | Partial<T>[]): void {
        this.#docsMap$.value.clear();
        this.upsert(data);
    }

    modify(modifyFn: (it: T) => QxAction | undefined | void): void {
        this.#docsMap$.value.forEach(doc => {
            this.modifyByIdOrDoc(doc, modifyFn);
        });

        this.emit();
    }

    modifyById(id: string, modifyFn: (it: T) => QxAction | undefined | void): void {
        this.modifyByIdOrDoc(id, modifyFn);
        this.emit();
    }

    isDocProperty(o?: any): o is keyof T {
        return this.getDocPropertyNames().includes(o);
    }

    @Bound()
    isDoc(o?: any): o is T {
        return isDescribedByJsonSchema(o, this.options.jsonSchema);
    }

    emit(): void {
        this.#docsMap$.next(this.#docsMap$.value);
    }

    getPrimaryKey(): string & keyof T {
        return this.options.jsonSchema.primaryKey ?? 'id';
    }

    normalize(doc: Partial<T>): void {
        const properties = this.getDocProperties();

        for (let propertyName in properties) {
            const property: JSONSchema4 = properties[propertyName];

            if (typeof property.type === 'string') {
                if (doc[propertyName] !== undefined) {
                    doc[propertyName] = as(property.type, doc[propertyName]);
                } else if (property.default !== undefined) {
                    doc[propertyName] = as(property.type, property.default);
                }
            } else {
                console.warn(`No valid type found`, {property});
            }

            if (typeof property.ref === 'string') {
                this.defineRefProperty(doc, propertyName, property.ref);
            }
        }
    }

    private validate(id: string, doc: Partial<T>): doc is T {
        if (!this.isDoc(doc)) {
            console.warn(`Removing`, doc);
            this.#docsMap$.value.delete(id);
            return false;
        }

        return true;
    }

    private defineRefProperty(doc: Partial<T>, propertyName: string & keyof T, refCollectionName: string) {
        const refPropertyName = `${propertyName}_ref` as keyof T;

        if (!Object.getOwnPropertyDescriptor(doc, refPropertyName)) {
            const db = this.options.db;

            Object.defineProperty(doc, refPropertyName, {
                get() {
                    const refCollection = db?.collections[refCollectionName];
                    return refCollection?.getById(this[propertyName]) ?? null;
                }
            });
        }
    }

    private modifyByIdOrDoc(idOrDoc: string | T, modifyFn: (it: T) => (QxAction | void | undefined)) {
        let id: string, doc: T | null;

        if (typeof idOrDoc === 'string') {
            id = idOrDoc;
            doc = this.getById(id);
        } else {
            id = this.getId(idOrDoc);
            doc = idOrDoc;
        }

        if (doc && id) {
            const action = modifyFn(doc);

            switch (action) {
                case QxAction.DELETE:
                    this.#docsMap$.value.delete(id);
                    break;
                case QxAction.NOTHING:
                default:
                // pass
            }

            if (!this.isDoc(doc)) {
                this.#docsMap$.value.delete(id);
            }
        }
    }

    private getDocPropertyNames(): (keyof T)[] {
        return Object.keys(this.getDocProperties()) as (keyof T)[];
    }

    private getDocProperties(): Record<keyof T, JSONSchema4> {
        const properties = this.options.jsonSchema.properties ?? {};
        return properties as Record<keyof T, JSONSchema4>;
    }
}
