import {Factory} from '../../types/common/functions/Factory';
import {AnyFn} from '../../types/common/functions/AnyFn';

export function Pyped(pipe: Factory<<T extends AnyFn>(fn: T) => typeof fn>[]): MethodDecorator {
    return (proto: any, prop: string | symbol, desc: PropertyDescriptor) => {
        const FN = Symbol(`@Pyped(${String(prop)}).FN`);

        delete desc.value;
        delete desc.get;

        const origFn = proto[prop];

        proto[FN] = origFn;
        proto[prop] = pipe.reduce((fn: typeof origFn, decoratorFactory) => {
            return decoratorFactory()(fn);
        }, origFn);
    };
}
