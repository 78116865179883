import {DefaultWizardStepDescription} from './DefaultWizardStepDescription';
import {useEsc} from '../../../../../common/helpers/react/hooks/autokey/useEsc';
import {useDefaultWizard} from './useDefaultWizard';
import {DefaultWizardHelpers} from './DefaultWizardHelpers';
import {MiscDataObject} from '../../../../../common/helpers/react/hooks/useMiscData';
import LoadingPage from '../../placeholders/loading/LoadingPage';
import DefaultWizardStep from './DefaultWizardStep';
import {getCurrentStep} from './getCurrentStep';
import {
    usePromisedStateConditionally
} from '../../../../../common/helpers/react/hooks/state-info/usePromisedStateConditionally';
import {StateStatus} from '../../../../../common/helpers/react/hooks/state-info/StateStatus';
import {MessageStatus} from '@sabre/spark-react-core/types';
import {useControllers} from '../../../../../common/helpers/react/hooks/useControllers';
import {asString} from '../../../../../common/helpers/converters/asString';
import {getWizardData} from './getWizardData';

export type DefaultWizardProps = {
    steps: DefaultWizardStepDescription[],
    wizardId: string,
    currentStep: string,
    cancelPage?: string,
    fallback?: JSX.Element,
    onSubmit?: (data: Record<string, MiscDataObject>, wizardHelpers: DefaultWizardHelpers) => Promise<void>,
    onBeforeLoaded?: (data: Record<string, MiscDataObject>, wizardHelpers: DefaultWizardHelpers) => Promise<void>
}

export default function DefaultWizard(props: DefaultWizardProps) {
    const wh = useDefaultWizard(props.wizardId, props.currentStep);

    useEsc(() => {
        wh.destroyWizard().andWeAreDone();
    });

    const dialog = useControllers().dialog;

    let wizardData = wh.wizardData;

    if (!wizardData) {
        wizardData = getWizardData(props);
        wh.setWizardData(wizardData).andWeAreDone();
    }

    const beforeStateInfo = usePromisedStateConditionally(async () => {
        const validationResult = await wh.allStepsData$;
        const allData = validationResult.data;
        await props.onBeforeLoaded?.(allData, wh);
    });

    if (beforeStateInfo.status === StateStatus.ERROR) {
        dialog.showNotification({
            status: MessageStatus.ERROR,
            content: asString(beforeStateInfo.error)
        });
    }

    const currentStep = getCurrentStep(props.steps, props.currentStep);

    if (beforeStateInfo.status === StateStatus.PENDING || wh.isLoading || !currentStep) {
        return props.fallback ?? <LoadingPage/>;
    }

    return <DefaultWizardStep wizardHelpers={wh} step={currentStep} fallback={props.fallback}/>;
}
