import {removeNullishMembers} from '../../converters/removeNullishMembers';
import {mapEntries} from '../../objects/mapEntries';
import {as} from '../../converters/as';
import {isDescribedByJsonSchema} from '../../../types/guards/isDescribedByJsonSchema';
import {asString} from '../../converters/asString';
import {JsonObject} from 'type-fest';
import {getFlatSchema} from '../../json/getFlatSchema';
import {isPlainObject} from '../../../types/guards/isPlainObject';
import {asJsonObject} from '../../converters/asJsonObject';
import {JSONSchema4} from 'json-schema';

export function generateConverterToRx<T>(
    schema: JSONSchema4,
    schemaName: string,
    rxJsonConverter?: (o?: any) => JsonObject | null | T,
    rxTypeGuard?: (o?: any) => boolean,
    stringConverter: 'toSnakeCase' | 'toCamelCase' = 'toSnakeCase'
): (o?: unknown) => T | null {
    schema = getFlatSchema(schema);

    return (o?: unknown) => {
        try {
            let json: JsonObject | null | T = asJsonObject(o);

            json = rxJsonConverter && rxTypeGuard?.(json)
                ? rxJsonConverter(json)
                : json;

            if (isPlainObject(json)) {
                const ret = mapEntries(removeNullishMembers(json), pair => {
                    const key = typeof pair[0] === 'string'
                        ? pair[0][stringConverter]()
                        : pair[0];

                    const type = schema.properties?.[key as keyof T]?.type;
                    const value = as(typeof type === 'string' ? type : 'unknown', pair[1], '');

                    return [key, value];
                });

                if (isDescribedByJsonSchema<T>(ret, schema)) {
                    return ret;
                } else {
                    console.warn(`Cannot convert to RxGroup - effective JSON is not ${schemaName} compatible`);
                    return null;
                }
            } else {
                console.warn('Cannot convert to RxGroup - JSON seems to be null');
                return null;
            }
        } catch (e: any) {
            console.warn(asString(e));
            return null;
        }
    };
}
