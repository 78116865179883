import React from 'react';
import {MessageStatus} from '@sabre/spark-react-core/types';
import Toolbar from '../../../../../common/components/toolbar/Toolbar';
import {Checkbox, ProgressIndicator} from '@sabre/spark-react-core';
import {tWithHtml} from '../../../../../../../common/helpers/react/text/tWithHtml';
import {tAsString} from '../../../../../../../common/helpers/react/text/tAsString';
import {cssClasses} from '../../../../../../../common/helpers/browser/cssClasses';
import {useControllers} from '../../../../../../../common/helpers/react/hooks/useControllers';
import VerticalSeparator from '../../../../../common/components/separators/VerticalSeparator';
import {getAssigneeId} from '../../../../../../../common/model/types/assignees/getAssigneeId';
import styles from './GroupWizardAddAssigneesToolbar.module.css';
import {AddRemoveInfo} from './AddRemoveInfo';
import {DefaultTableCheckboxModeProps} from '../../../../../common/components/table/DefaultTable';
import {printBreadcrumb} from '../../../../../common/components/breadcrumb/printBreadcrumb';

export type GroupWizardAddAssigneesToolbarProps = DefaultTableCheckboxModeProps & {
    groupId: number,
    addRemoveInfo: AddRemoveInfo
}

export default function GroupWizardAddAssigneesToolbar(props: GroupWizardAddAssigneesToolbarProps) {
    const controllers = useControllers();
    const groups = controllers.groups;
    const dialog = controllers.dialog;

    const results = groups.getAssigneesSearchResults$().asState();
    const breadcrumbs = results?.breadcrumbs;

    const data = groups.getAssigneesForAddAssigneeTable$(props.groupId, props.addRemoveInfo).asState();

    const currentTotalRowsCount = data?.length ?? 0;

    const totalCheckedRowsCount = props.checkedRows.length;
    const currentCheckedRowsCount = (data ?? [])
        .filter(it => {
            const assigneeId = getAssigneeId(it);
            return props.checkedRows.includes(assigneeId);
        }).length;

    function onChange(e: React.ChangeEvent<HTMLInputElement>) {
        if (currentTotalRowsCount > 0) {
            if (data) {
                let checked: string[];

                const currentDataIds = [
                    ...data.map(o => getAssigneeId(o))
                ];

                if (e.target.checked) {
                    checked = [
                        ...props.checkedRows,
                        ...currentDataIds
                    ];
                } else {
                    checked = props.checkedRows
                        .filter(it => !currentDataIds.includes(it));
                }

                props.setCheckedRows(checked.uniq());
            }
        } else {
            dialog.showNotification({
                status: MessageStatus.WARNING,
                content: tAsString('NO_ROWS_TO_SELECT')
            }).andWeAreDone();
        }
    }

    const breadcrumbsElement = breadcrumbs?.map((it, idx) => {
        return printBreadcrumb(() => groups.searchForPossibleAssignees(breadcrumbs[idx]?.query), it, idx, idx == breadcrumbs.length - 1);
    });

    const breadcrumbsOrNot = data && breadcrumbsElement
        ? breadcrumbsElement
        : <ProgressIndicator
            type={'indeterminate'}
            size={'xs'}
            className={styles.BreadcrumbsLoadingSpinner}
        />;

    const toolbarContent = results
        ? (
            <>
                <Checkbox
                    label={<>{tWithHtml(`X_ASSIGNEES_SELECTED`, {
                        count: totalCheckedRowsCount
                    })}<VerticalSeparator className={'spark-hidden--lte-sm'}/></>}
                    checked={!!data?.length && currentTotalRowsCount === currentCheckedRowsCount}
                    onChange={onChange}
                    className={cssClasses('checkboxId')}
                />
                <span className={styles.Breadcrumbs}>{breadcrumbsOrNot}</span>
            </>
        )
        : `${tAsString('LOADING')}...`;

    return <Toolbar contentClassName={'row'} content={toolbarContent}/>;
}