import {Once} from '../methods/Once';
import {DataSourceKind} from './DataSourceKind';
import {Cached} from '../methods/Cached';
import {PipedObservable} from '../methods/PipedObservable';
import {distinctLikeABoss} from '../../helpers/rxjs/distinctLikeABoss';

export function DataSource(kind: DataSourceKind = DataSourceKind.STABLE, resolveRefs: boolean = true): MethodDecorator {
    const pipedFlow = () => [
        distinctLikeABoss()
    ];

    switch (kind) {
        case DataSourceKind.PARAMETRIZED:
            return (proto: any, prop: string | symbol, desc: PropertyDescriptor) => {
                PipedObservable(pipedFlow)(proto, prop, desc);
                Cached()(proto, prop, desc);
            };
        case DataSourceKind.STABLE:
        default:
            return (proto: any, prop: string | symbol, desc: PropertyDescriptor) => {
                PipedObservable(pipedFlow)(proto, prop, desc);
                Once()(proto, prop, desc);
            };
    }
}
