import {useAsyncEffect} from './useAsyncEffect';
import {useState} from 'react';
import {TIMEOUT_FOR_HTTP} from '../../../CONST';

export function useTimeout(timeout: number = TIMEOUT_FOR_HTTP) {
    const [isTimeout, setIsTimeout] = useState(false);

    useAsyncEffect(() => {
        setIsTimeout(true);
    }, undefined, timeout);

    return isTimeout;
}
