/* tslint:disable */
/* eslint-disable */
/**
 * SR AAT specification
 * Description
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ModelConfiguration } from './ModelConfiguration';
import {
    ModelConfigurationFromJSON,
    ModelConfigurationFromJSONTyped,
    ModelConfigurationToJSON,
} from './ModelConfiguration';
import type { Status } from './Status';
import {
    StatusFromJSON,
    StatusFromJSONTyped,
    StatusToJSON,
} from './Status';
import type { StatusCodes } from './StatusCodes';
import {
    StatusCodesFromJSON,
    StatusCodesFromJSONTyped,
    StatusCodesToJSON,
} from './StatusCodes';

/**
 * 
 * @export
 * @interface ConfigurationResponse
 */
export interface ConfigurationResponse extends Status {
    /**
     * 
     * @type {Array<ModelConfiguration>}
     * @memberof ConfigurationResponse
     */
    configurations: Array<ModelConfiguration>;
}

/**
 * Check if a given object implements the ConfigurationResponse interface.
 */
export function instanceOfConfigurationResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "configurations" in value;

    return isInstance;
}

export function ConfigurationResponseFromJSON(json: any): ConfigurationResponse {
    return ConfigurationResponseFromJSONTyped(json, false);
}

export function ConfigurationResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): ConfigurationResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        ...StatusFromJSONTyped(json, ignoreDiscriminator),
        'configurations': ((json['configurations'] as Array<any>).map(ModelConfigurationFromJSON)),
    };
}

export function ConfigurationResponseToJSON(value?: ConfigurationResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        ...StatusToJSON(value),
        'configurations': ((value.configurations as Array<any>).map(ModelConfigurationToJSON)),
    };
}

