import React from 'react';
import {RxGroupProduct} from '../../../../../../../../common/model/db/types/RxGroupProduct';
import {DefaultWizardHelpers} from '../../../../../../common/components/wizards/DefaultWizardHelpers';
import {asInt} from '../../../../../../../../common/helpers/converters/asInt';
import {isProduct} from '../../../../../../../../common/model/types/products/isProductPlacement';
import {isRecordOf} from '../../../../../../../../common/types/guards/isRecordOf';
import {ensure} from '../../../../../../../../common/types/guards/ensure';
import {isProductState} from '../../../../../../../../common/model/types/products/isProductState';
import {isDefined} from '../../../../../../../../common/types/guards/isDefined';

export const getRedAppConfigurationInputCellHelpers = (row: RxGroupProduct, wh: DefaultWizardHelpers) => {
    const productId = asInt(row.product_ref?.id);
    const initialValue = getRedAppConfigurationValue(row, wh);
    const products = wh.stepData?.products ?? {};
    ensure(isRecordOf(isProductState), products);
    const updateWizardData = async (value: string, setName: string = 'products') => {
        if (isDefined(productId)) {
            const data = {
                id: productId,
                type: row.product_ref?.type,
                redAppPlacement: products[productId]?.redAppPlacement ?? row.redapp_placement,
                redAppConfiguration: value,
                productEnablement: products[productId]?.productEnablement ?? row.product_enablement
            };

            await wh.setStepMember([setName, productId], data);
        }
    };

    const onChange = async (e: React.ChangeEvent<HTMLElement>, v: string) => {
        await updateWizardData(v);
    };

    return {
        productId,
        initialValue,
        onChange
    }
}

const getRedAppConfigurationValue = (row: RxGroupProduct, wh: DefaultWizardHelpers): string | undefined => {
    const products = wh.stepData?.products ?? {};

    if (isRecordOf(isProduct)(products)) {
        const stepValue = products[row.product_ref?.id ?? NaN];

        return isProduct(stepValue) ? stepValue.redAppConfiguration : row.redAppConfiguration;
    }
    return undefined;
}