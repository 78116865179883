import {isStringifiable} from '../../types/guards/isStringifiable';
import {isJsonizable} from '../../types/guards/isJsonizable';
import {asJson} from './asJson';
import {isFunction} from '../../types/guards/isFunction';
import {ValidationError} from 'yup';
import {isAsyncFunction} from '../../types/guards/isAsyncFunction';

export function asString(o?: unknown): string {
    if (typeof o === 'string') {
        return o;
    }

    if (Array.isArray(o)) {
        return JSON.stringify(JSON.decycle(o.map(asJson)));
    }

    if (o instanceof ValidationError) {
        return o.errors.join(`; `);
    }

    if (o instanceof Error) {
        return console.isDevMode()
            ? o.stack
                ? `${o.stack}\n----\n`
                : o.message
            : o.message;
    }

    if (o instanceof ErrorEvent) {
        return `${o.message} @ ${o.filename}:${o.lineno}:${o.colno} => ${asString(o.error)}`;
    }

    if (o instanceof PromiseRejectionEvent) {
        return asString(o.reason);
    }

    if (o instanceof HTMLElement) {
        return o.textContent ?? o.toString();
    }

    if (isAsyncFunction(o)) {
        return `async ${o.name}(${o.length})`;
    }

    if (isFunction(o)) {
        const name = o.name || 'anonymous';
        return `${name}(${o.length})`;
    }

    if (isStringifiable(o)) {
        const stringified = o.toString();

        if (stringified === '[object Object]') {
            return JSON.stringify(JSON.decycle(asJson(o)));
        } else {
            return stringified;
        }
    }

    if (isJsonizable(o)) {
        return JSON.stringify(JSON.decycle(asJson(o)));
    }

    return String(o);
}
