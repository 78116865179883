import {EntryPoint} from '../common/core/entry-point/EntryPoint';
import {Config_core} from '../common/core/entry-point/Config_core';

/**
 * Entry point for core part of application.
 */
export class CoreEntryPoint extends EntryPoint {
    protected override readonly cfg: Config_core;

    constructor(cfg: Config_core) {
        super(cfg);
        this.cfg = cfg;
    }

    protected async main(): Promise<void> {
        this.cfg.controllers.settings.get$('logging_level')
            .subscribe(it => {
                if (console.isLoggingLevel(it)) {
                    console.warn(`Setting logging level to`, it, console.getLoggingMethod(it));
                    console.setLoggingLevel(it);
                }
            });

        console.debug(`App started`);
    }
}
