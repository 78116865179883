import {Assignee} from '../../../../../../../common/model/types/assignees/Assignee';
import {
    AssigneesSearchResultLevel
} from '../../../../../../../common/model/types/assignees/AssigneesSearchResultLevel';
import {isDefined} from '../../../../../../../common/types/guards/isDefined';

export function getTableLevelByData(data: Assignee[] = []) {
    const types = data.reduce((memo, it) => {
        return {
            agent: memo.agent || it.agent,
            agency: memo.agency || it.agency,
            pcc: memo.pcc || it.pcc
        };
    }, {});

    if (Object.values(types).filter(isDefined).length > 1) {
        return AssigneesSearchResultLevel.SEARCH;
    }

    return data[0]?.agent
        ? AssigneesSearchResultLevel.PCC
        : data[0]?.pcc
            ? AssigneesSearchResultLevel.DOMESTIC
            : AssigneesSearchResultLevel.INTERNATIONAL;
}
