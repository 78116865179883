/* tslint:disable */
/* eslint-disable */
/**
 * SR AAT specification
 * Description
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ParentRole } from './ParentRole';
import {
    ParentRoleFromJSON,
    ParentRoleFromJSONTyped,
    ParentRoleToJSON,
} from './ParentRole';
import type { PartialRole } from './PartialRole';
import {
    PartialRoleFromJSON,
    PartialRoleFromJSONTyped,
    PartialRoleToJSON,
} from './PartialRole';

/**
 * 
 * @export
 * @interface Role
 */
export interface Role {
    /**
     * 
     * @type {Array<ParentRole>}
     * @memberof Role
     */
    parentRoles: Array<ParentRole>;
    /**
     * 
     * @type {number}
     * @memberof Role
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof Role
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof Role
     */
    notes?: string;
}

/**
 * Check if a given object implements the Role interface.
 */
export function instanceOfRole(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "parentRoles" in value;

    return isInstance;
}

export function RoleFromJSON(json: any): Role {
    return RoleFromJSONTyped(json, false);
}

export function RoleFromJSONTyped(json: any, ignoreDiscriminator: boolean): Role {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'parentRoles': ((json['parentRoles'] as Array<any>).map(ParentRoleFromJSON)),
        'id': !exists(json, 'id') ? undefined : json['id'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'notes': !exists(json, 'notes') ? undefined : json['notes'],
    };
}

export function RoleToJSON(value?: Role | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'parentRoles': ((value.parentRoles as Array<any>).map(ParentRoleToJSON)),
        'id': value.id,
        'name': value.name,
        'notes': value.notes,
    };
}

