import {createAssociatedIdGenerator} from './createAssociatedIdGenerator';

let idCnt = 0;

/**
 * Gets numeral ID for given value.
 *
 * It is created with createAssociatedIdGenerator().
 *
 * @param o Value for which ID should be returned
 * @return ID as a number
 */
export const ID = createAssociatedIdGenerator(() => idCnt++);
