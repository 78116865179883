/* tslint:disable */
/* eslint-disable */
/**
 * SR AAT specification
 * Description
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Role } from './Role';
import {
    RoleFromJSON,
    RoleFromJSONTyped,
    RoleToJSON,
} from './Role';
import type { Status } from './Status';
import {
    StatusFromJSON,
    StatusFromJSONTyped,
    StatusToJSON,
} from './Status';
import type { StatusCodes } from './StatusCodes';
import {
    StatusCodesFromJSON,
    StatusCodesFromJSONTyped,
    StatusCodesToJSON,
} from './StatusCodes';

/**
 * 
 * @export
 * @interface RoleResponse
 */
export interface RoleResponse extends Status {
    /**
     * 
     * @type {Role}
     * @memberof RoleResponse
     */
    role: Role;
}

/**
 * Check if a given object implements the RoleResponse interface.
 */
export function instanceOfRoleResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "role" in value;

    return isInstance;
}

export function RoleResponseFromJSON(json: any): RoleResponse {
    return RoleResponseFromJSONTyped(json, false);
}

export function RoleResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): RoleResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        ...StatusFromJSONTyped(json, ignoreDiscriminator),
        'role': RoleFromJSON(json['role']),
    };
}

export function RoleResponseToJSON(value?: RoleResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        ...StatusToJSON(value),
        'role': RoleToJSON(value.role),
    };
}

