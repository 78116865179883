import {RxGroupProperty} from '../../../../../../../../common/model/db/types/RxGroupProperty';
import {
    DefaultWizardHelpers
} from '../../../../../../common/components/wizards/DefaultWizardHelpers';
import {getOverrideValue} from './getOverrideValue';
import {isDefined} from '../../../../../../../../common/types/guards/isDefined';
import {getAllowUserOverride} from './getAllowUserOverride';
import {default as React} from 'react';

export function getPropertyInputCellHelpers(row: RxGroupProperty, wh: DefaultWizardHelpers) {
    const propertyId = row.property_ref?.id;
    const initialValue = getOverrideValue(row, wh);

    const updateWizardData = async (value: string, setName: string = 'properties') => {
        if (isDefined(propertyId)) {
            let data = {
                id: propertyId,
                overrideValue: value,
                allowUserOverride: getAllowUserOverride(row, wh)
                    ? 'true'
                    : 'false'
            };

            await wh.setStepMember([setName, propertyId], data);
        }
    };

    const onBlurCapture = async (e: React.FocusEvent<HTMLInputElement>) => {
        await updateWizardData(e.target.value);
    };

    const onChange = async (e: React.ChangeEvent<HTMLElement>, v: string) => {
        await updateWizardData(v);
    };

    return {propertyId, initialValue, onChange, onBlurCapture, updateWizardData};
}
