/* tslint:disable */
/* eslint-disable */
/**
 * SR AAT specification
 * Description
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface RedApp
 */
export interface RedApp {
    /**
     * 
     * @type {string}
     * @memberof RedApp
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof RedApp
     */
    iuName: string;
}

/**
 * Check if a given object implements the RedApp interface.
 */
export function instanceOfRedApp(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "iuName" in value;

    return isInstance;
}

export function RedAppFromJSON(json: any): RedApp {
    return RedAppFromJSONTyped(json, false);
}

export function RedAppFromJSONTyped(json: any, ignoreDiscriminator: boolean): RedApp {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': json['name'],
        'iuName': json['iu_name'],
    };
}

export function RedAppToJSON(value?: RedApp | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'iu_name': value.iuName,
    };
}

