import {asString} from './asString';

export function asIntWithRadix(radix: number = 10): (o?: unknown) => number {
    return (o?: unknown): number => {
        if (typeof o === 'number') {
            o = o.toString(radix);
        }

        return parseInt(asString(o), radix);
    };
}
