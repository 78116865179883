import {
    DefaultWizardHelpers
} from '../../../../../../common/components/wizards/DefaultWizardHelpers';
import {RxGroupProperty} from '../../../../../../../../common/model/db/types/RxGroupProperty';
import {ensure} from '../../../../../../../../common/types/guards/ensure';
import {isRecordOf} from '../../../../../../../../common/types/guards/isRecordOf';
import {
    isPropertyState
} from '../../../../../../../../common/model/types/properties/isPropertyState';
import {asBoolean} from '../../../../../../../../common/helpers/converters/asBoolean';

export function getAllowUserOverride(row: RxGroupProperty, wh: DefaultWizardHelpers): boolean | undefined {
    const properties = wh.stepData?.properties ?? {};

    ensure(isRecordOf(isPropertyState), properties);
    const stepValue = properties[row.property_ref?.id ?? NaN];

    return isPropertyState(stepValue)
        ? asBoolean(stepValue.allowUserOverride)
        : row.allow_user_override;
}
