/* tslint:disable */
/* eslint-disable */
/**
 * SR AAT specification
 * Description
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface Property
 */
export interface Property {
    /**
     * 
     * @type {number}
     * @memberof Property
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof Property
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof Property
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof Property
     */
    overrideValue?: string;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof Property
     */
    possibleValues?: { [key: string]: string; };
    /**
     * 
     * @type {boolean}
     * @memberof Property
     */
    allowUserOverride?: boolean;
}

/**
 * Check if a given object implements the Property interface.
 */
export function instanceOfProperty(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;

    return isInstance;
}

export function PropertyFromJSON(json: any): Property {
    return PropertyFromJSONTyped(json, false);
}

export function PropertyFromJSONTyped(json: any, ignoreDiscriminator: boolean): Property {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'overrideValue': !exists(json, 'overrideValue') ? undefined : json['overrideValue'],
        'possibleValues': !exists(json, 'possibleValues') ? undefined : json['possibleValues'],
        'allowUserOverride': !exists(json, 'allowUserOverride') ? undefined : json['allowUserOverride'],
    };
}

export function PropertyToJSON(value?: Property | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'description': value.description,
        'overrideValue': value.overrideValue,
        'possibleValues': value.possibleValues,
        'allowUserOverride': value.allowUserOverride,
    };
}

