import {CamelCase, KebabCase, PascalCase, SnakeCase} from 'type-fest';
import s from 'underscore.string';
import {StringApiExtension} from '../../types/api-extensions/StringApiExtension';

declare global {
    interface String extends StringApiExtension {
    }
}

// eslint-disable-next-line no-extend-native
String.prototype.toSnakeCase = function <T extends string>(
    this: String
): SnakeCase<T> {
    return s.underscored(this.toString()) as SnakeCase<T>;
};

// eslint-disable-next-line no-extend-native
String.prototype.toPascalCase = function <T extends string>(
    this: String
): PascalCase<T> {
    return s.titleize(this.toCamelCase()) as PascalCase<T>;
};

// eslint-disable-next-line no-extend-native
String.prototype.toCamelCase = function <T extends string>(
    this: String
): CamelCase<T> {
    return s.camelize(this.toString()) as CamelCase<T>;
};

// eslint-disable-next-line no-extend-native
String.prototype.toKebabCase = function <T extends string>(
    this: String
): KebabCase<T> {
    return s.dasherize(this.toString()) as KebabCase<T>;
};

// eslint-disable-next-line no-extend-native
String.prototype.toTitleCase = function <T extends string>(
    this: String
): string {
    return s.titleize(this.toString());
};

// eslint-disable-next-line no-extend-native
String.prototype.toNaturalCase = function <T extends string>(
    this: String
): string {
    return this.toSnakeCase().replace(/_/g, ' ');
};
