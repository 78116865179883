/**
 * Generic enum type guard.
 * Checks if given value is value of given classic TS enum (not const, not anything other..)
 *
 * @param enumValue Value to be checked
 * @param enumObject Enum to be checked
 */
import {isEnum} from './isEnum';

export function isEnumValue<T extends object>(enumValue?: any, enumObject?: T): enumValue is T {
    return (typeof enumValue === 'string' || typeof enumValue === 'number')
        && !!enumObject
        && isEnum(enumObject)
        && Object.values(enumObject).includes(enumValue);
}
