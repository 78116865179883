import {Controller} from '../Controller';
import {map, Observable} from 'rxjs';
import {RxMenuItem} from '../../common/model/db/types/RxMenuItem';
import {DataSource} from '../../common/decorators/rxdb/DataSource';
import Comparators from 'comparators';

export class LayoutController extends Controller {
    #orderCmpAsc = Comparators.comparing('order');

    @DataSource()
    getMenuItems$(): Observable<RxMenuItem[]> {
        return this.db.menu_items.$.pipe(
            map(they => they.sort(this.#orderCmpAsc))
        );
    }

    scrollToTop(): void {
        window.scrollTo({top: 0});
    }
}
