import ReactDOM from 'react-dom/client';
import '@sabre/spark/dist/css/spark.katana.min.css';
import './styles.css';
import {EntryPoint} from '../../common/core/entry-point/EntryPoint';
import reportWebVitals from './reportWebVitals';
import AppPresenter from './AppPresenter';
import {removeHash} from '../../common/helpers/browser/removeHash';
import {addHash} from '../../common/helpers/browser/addHash';

export class ReactAppEntryPoint extends EntryPoint {
    protected async main(): Promise<void> {
        this.#prepareHashHandling();
        this.#render();
        this.#setupInstrumentation();
    }

    #prepareHashHandling() {
        const settings = this.cfg.controllers.settings;

        function handleHash(e?: HashChangeEvent) {
            const oldHash = new URL(e?.oldURL || window.location.href).hash;
            const newUrl = new URL(e?.newURL || window.location.href);
            const newHash = newUrl.hash;

            const desiredHash = addHash('/' + newHash.replace(/[^\/+]/, ''));

            console.log(`Hash changes`, {oldHash, newHash, desiredHash});

            if (newHash === desiredHash) {
                settings.set('current_page', removeHash(desiredHash))
                    .andWeAreDone();
            } else {
                newUrl.hash = desiredHash;
                window.location.replace(newUrl);
            }
        }

        window.addEventListener('hashchange', handleHash);
        handleHash();
    }

    #render() {
        const root = ReactDOM.createRoot(
            document.getElementById('root') as HTMLElement
        );

        root.render(<AppPresenter cfg={this.cfg}/>);
    }

    #setupInstrumentation() {
        // If you want to start measuring performance in your app, pass a function
        // to log results (for example: reportWebVitals(console.log))
        // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
        reportWebVitals(function (data) {
            console.log(`[PERF] Web vitals`, data);
        });
    }
}
