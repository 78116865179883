import React from 'react';
import {Checkbox} from '@sabre/spark-react-core';
import {DefaultTableCheckboxModeProps} from '../table/DefaultTable';
import {MessageStatus} from '@sabre/spark-react-core/types';
import {ButtonSize} from '@sabre/spark-react-core/button/button.types';
import {useI18Next} from '../../../../../common/helpers/react/hooks/useI18Next';
import {useControllers} from '../../../../../common/helpers/react/hooks/useControllers';
import {ButtonConfig} from '../../../../../controllers/dialog/ButtonConfig';
import {cssClasses} from '../../../../../common/helpers/browser/cssClasses';
import {ModalButtons} from '../../../../../controllers/dialog/ModalButtons';
import {HTML_ID} from '../../../../../common/helpers/id/HTML_ID';
import Toolbar from './Toolbar';
import {tWithHtml} from '../../../../../common/helpers/react/text/tWithHtml';
import {RxConfiguration} from "../../../../../common/model/db/types/RxConfiguration";
import {RxGroup} from "../../../../../common/model/db/types/RxGroup";

export enum DeleteToolbarType {
    CONFIGURATIONS = 'configurations',
    GROUPS = 'groups'
}

export type DataType = RxConfiguration | RxGroup;


export interface DeleteToolbarProps<T extends DataType> extends DefaultTableCheckboxModeProps {
    getData: () => T[];
    deleteByIds: (ids: number[]) => Promise<void>;
    refresh: () => Promise<void>;
    type: DeleteToolbarType
}

const Settings = {
    [DeleteToolbarType.CONFIGURATIONS]: {
        mainPage: 'configurations',
        deleteItem: 'DELETE_CONFIGURATION',
        itemsWillBeDeleted: 'X_SELECTED_CONFIGURATIONS_WILL_BE_DELETED',
        itemsHaveBeenDeleted: 'X_CONFIGURATIONS_HAVE_BEEN_DELETED',
        itemsHaveNotBeenDeleted: 'THE_SELECTED_CONFIGURATIONS_HAVE_NOT_BEEN_DELETED'
    },
    [DeleteToolbarType.GROUPS]: {
        mainPage: 'groups',
        deleteItem: 'DELETE_GROUP',
        itemsWillBeDeleted: 'X_SELECTED_GROUPS_WILL_BE_DELETED',
        itemsHaveBeenDeleted: 'X_GROUPS_HAVE_BEEN_DELETED',
        itemsHaveNotBeenDeleted: 'THE_SELECTED_GROUPS_HAVE_NOT_BEEN_DELETED'
    }
}

export const DeleteToolbar = (props: DeleteToolbarProps<DataType>): JSX.Element => {
    const {t} = useI18Next();

    const controllers = useControllers();
    const dialogController = controllers.dialog;

    const data: DataType[] = props.getData();
    const dataCount = data.length;
    const listIds = props.checkedRows.map(it => parseInt(it));

    const http = controllers.http;

    const {
        mainPage,
        deleteItem,
        itemsWillBeDeleted,
        itemsHaveBeenDeleted,
        itemsHaveNotBeenDeleted
    } = Settings[props.type];

    const performDelete = async (): Promise<void> => {
        if (!props.checkedRows.length) {
            return dialogController.showNotification({
                status: MessageStatus.WARNING,
                content: t('SELECT_SOME_OPTIONS')
            });
        }

        const nameOfOne = data?.length > 0 ?
            (props.type === DeleteToolbarType.CONFIGURATIONS ?
                (data.find(group => group.id === props.checkedRows[0]) as RxConfiguration).configuration_name :
                (data.find(group => group.id === props.checkedRows[0]) as RxGroup).name)
            : undefined;

        const warningMessage = tWithHtml(itemsWillBeDeleted, {
            count: props.checkedRows.length,
            name: nameOfOne
        });

        await dialogController.showModal({
            title: t(deleteItem),
            children: warningMessage,
            buttons: [
                ModalButtons.CANCEL(dialogController), {
                    children: t('DELETE'),
                    className: 'qa-modal-delete-button',
                    onClick: () => {
                        dialogController.takeNotifiedAction({
                            action: async () => {
                                await props.deleteByIds(listIds);
                            },
                            successMessage: t(itemsHaveBeenDeleted, {
                                count: props.checkedRows.length,
                                name: nameOfOne
                            }),
                            errorMessage: t(itemsHaveNotBeenDeleted, {
                                count: dataCount
                            }),
                            finalizer: async () => {
                                props.setCheckedRows([]);
                                await dialogController.closeModal();
                                await props.refresh();
                            }
                        });
                    }
                }
            ]
        });
    }

    const deleteToolbarButtons: ButtonConfig[] = [
        {
            onClick: () => http.navigateTo(mainPage),
            children: t('CANCEL'),
            size: ButtonSize.SMALL,
            secondary: true,
            className: cssClasses('aat-autokey-escape', 'qa-delete-toolbar-cancel-button')
        },
        {
            children: t('DELETE'),
            size: ButtonSize.SMALL,
            className: cssClasses('qa-delete-toolbar-delete-button'),
            onClick: performDelete
        }
    ];

    const checkboxId = HTML_ID();

    const onChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
        if (dataCount !== 0) {
            if (e.target.checked) {
                props.setCheckedRows(data.map(o => o.id));
            } else {
                props.setCheckedRows([]);
            }
        } else {
            dialogController.showNotification({
                status: MessageStatus.WARNING,
                content: t('NO_ROWS_TO_SELECT'),
                stick_to: checkboxId
            }).andWeAreDone();
        }
    }

    const toolbarContent = <Checkbox
        label={tWithHtml(`X_OF_Y_SELECTED`, {
            count: props.checkedRows.length,
            total: dataCount
        })}
        checked={!!dataCount && props.checkedRows.length === dataCount}
        onChange={onChange}
        className={checkboxId}
    />;

    return <Toolbar
        content={toolbarContent}
        buttons={deleteToolbarButtons}
    />;
}