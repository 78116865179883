import {Database} from '../../../common/model/db/Database';
import {Once} from '../../../common/decorators/methods/Once';
import {collections} from '../../../common/model/db/collections';
import common_data from '../../../common/model/db/common_data/data.json';
import {Service} from '../Service';
import {DataJson} from '../../../common/model/DataJson';
import {QxDb} from '../../../common/helpers/rxjs/qx/db/QxDb';
import {asNumber} from '../../../common/helpers/converters/asNumber';
import {getSearchParam} from '../../../common/helpers/browser/getSearchParam';

export class DatabaseService extends Service {
    protected override priority = -100;

    @Once()
    async getInstance(): Promise<Database> {
        return this.#registerTeardownActions()
            .then(() => this.initializeData())
            .then(() => this.getDB());
    }

    @Once()
    async getDB(): Promise<Database> {
        let dataThrottling = asNumber(getSearchParam('data_throttling'));

        if (isNaN(dataThrottling)) {
            dataThrottling = 0;
        } else if (dataThrottling > 0) {
            console.warn(`Using data throttling in QxDB`, {dataThrottling});
        }

        return QxDb.create({
            collections,
            dataThrottling
        });
    }

    protected async upsertData(data: Partial<DataJson>): Promise<void> {
        const db = await this.getDB();

        for (let i in db.collections) {
            const collectionName = i as (keyof typeof collections);
            const collection = db[collectionName]!;

            // collection init against errors
            await collection.upsert([]);

            const collectionData = data[collectionName];

            if (collectionData) {
                await collection.upsert(collectionData);
            }
        }
    }

    protected async initializeData(): Promise<void> {
        const env_data = await this.fetchEnvData();

        await this.upsertData(common_data);
        await this.upsertData(env_data);

        console.debug(`Collections initialized`);
    }

    @Once()
    protected async fetchEnvData(): Promise<DataJson> {
        const response = await this.services.http.fetch('./ENV/data.json');
        return response.json();
    }

    protected override teardown(): void {
        super.teardown();

        this.getDB().then(db => {
            if (!db.destroyed) {
                db.destroy().then(() => console.debug(`Database destroyed`));
            }
        });
    }

    async #registerTeardownActions(): Promise<void> {
        window.addEventListener('unload', () => this.teardown());
    }
}
