import {Controller} from '../../../controllers/Controller';
import {Path} from 'react-router-dom';
import {isString} from '../../../common/types/guards/isString';
import {asString} from '../../../common/helpers/converters/asString';
import {AnyUrl} from '../../../common/types/common/url/AnyUrl';
import {addHash} from '../../../common/helpers/browser/addHash';
import {removeHash} from '../../../common/helpers/browser/removeHash';
import {isCurrentHash} from '../../../common/helpers/browser/isCurrentHash';

export class HttpController extends Controller {
    #redirectingTo: string = '';

    reload(): void {
        console.debug(`Reloading`);
        document.location.reload();
    }

    redirect(input: Partial<AnyUrl>, replace: boolean = false): void {
        const urlAsString = this.getUrlAsString(input);

        if (this.#redirectingTo.length) {
            if (urlAsString === this.#redirectingTo) {
                console.debug(`Be patient. Already redirecting to ${urlAsString}`);
            } else {
                console.debug(`Cannot redirect to ${urlAsString}, already redirecting to ${this.#redirectingTo}`);
            }
        } else {
            this.#redirectingTo = urlAsString;

            console.debug(`Redirecting to ${urlAsString}`);

            if (replace) {
                document.location.replace(urlAsString);
            } else {
                document.location.assign(urlAsString);
            }
        }
    }

    open(url: AnyUrl, target: string): Window | null {
        const urlAsString = this.getUrlAsString(url);
        return window.open(urlAsString, target);
    }

    async navigateTo(page?: string, replace: boolean = false): Promise<void> {
        if (!!page) {
            page = addHash(page);

            const promise: Promise<void> = new Promise((res, rej) => {
                const listener = (e: HashChangeEvent) => {
                    const hash = new URL(e.newURL).hash;

                    if (isCurrentHash(hash, page)) {
                        window.removeEventListener('hashchange', listener);
                        res();
                    }
                };

                window.addEventListener('hashchange', listener);
            });

            if (replace) {
                const url = new URL(window.location.href);
                url.hash = removeHash(page);
                window.location.replace(url);
            } else {
                window.location.hash = page;
            }

            return promise;
        }
    }

    getUrlAsString(input: Partial<AnyUrl> = document.location.href): string {
        if (typeof input === 'string') {
            return input;
        } else if (input instanceof URL) {
            return input.toString();
        } else if (this.#isRequest(input)) {
            return input.url;
        } else if (this.#isPartialPath(input)) {
            return (
                ``
                + (
                    input.pathname
                        ? `${window.location.origin}${input.pathname}`
                        : ``
                )
                + (
                    input.search
                        ? `?${input.search}`
                        : ``
                )
                + (
                    input.hash
                        ? `#${input.hash}`
                        : ``
                )
            );
        } else {
            throw new Error('Cannot get URL from given object: ' + asString(input));
        }
    }

    getUrlAsObject(input: Partial<AnyUrl> = document.location.href): URL {
        if (input instanceof URL) {
            return input;
        } else {
            return new URL(this.getUrlAsString(input));
        }
    }

    #isRequest(o?: any): o is Request {
        return isString(o?.url);
    }

    #isPartialPath(o?: any): o is Partial<Path> {
        return isString(o?.pathname)
            || isString(o?.search)
            || isString(o?.hash);
    }
}
