import {JsonValue} from 'type-fest';
import {isJsonizable} from '../../types/guards/isJsonizable';
import {isDefined} from '../../types/guards/isDefined';
import {isString} from '../../types/guards/isString';
import {isBoolean} from '../../types/guards/isBoolean';
import {isNumber} from '../../types/guards/isNumber';
import {stack} from '../objects/stack';
import {mapEntries} from '../objects/mapEntries';
import {asString} from './asString';
import {isPlainObject} from '../../types/guards/isPlainObject';
import {isArray} from '../../types/guards/isArray';

export function asJson(o?: unknown): JsonValue {
    try {
        if (!isDefined(o)) {
            return null;
        }

        if (isJsonizable(o)) {
            return o.toMutableJSON?.()
                ?? o.toJson?.()
                ?? o.toJSON?.()
                ?? null;
        }

        if (o instanceof Error) {
            return {
                name: o.name,
                message: o.message,
                stack: stack(o),
                cause: asJson(o.cause),
                response: asJson((o as any).response)
            };
        }

        if (isArray(o)) {
            return o.map(asJson);
        }

        if (isString(o) || isBoolean(o) || isNumber(o)) {
            return o;
        }

        if (isPlainObject(o)) {
            return mapEntries(o, pair => [
                asString(pair[0]),
                asJson(pair[1])
            ]);
        }
    } catch (e) {
        console.warn(`Cannot convert to JSON object`, asString(e));
    }

    return null;
}
