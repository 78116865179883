import {AnyFn} from '../../types/common/functions/AnyFn';
import {Factory} from '../../types/common/functions/Factory';
import {asyncScheduler} from 'rxjs';
import {isFunction} from '../../types/guards/isFunction';
import {ORIGINAL_FUNCTION} from '../test/mockMethod';
import {withName} from './withName';
import {getSignature} from '../signature/getSignature';

const RESULT = Symbol(`cached().RESULT`);
const THROWN = Symbol(`cached().THROWN`);
const RECALL = Symbol(`cached().RECALL`);

type CachedValue<TResult = unknown, TThrown = unknown> = {
    [RESULT]?: TResult;
    [THROWN]?: TThrown;
    [RECALL]?: any[];
}

type CachedValues = Map<symbol, CachedValue>;

const globalCache = new WeakMap<object, WeakMap<object, CachedValues>>();

const defaultCacheKeyFn = function (...args: any[]): symbol {
    return Symbol.for(getSignature(args));
};

export type CachingOptions = {
    keyFn?: Factory<symbol>;
    timeout?: number;
    recall?: boolean;
}

export function cached<T extends AnyFn>(
    fn: T, options?: CachingOptions
): typeof fn {
    const cachedFn = withName(fn)(function (this: unknown, ...args: unknown[]) {
        const globalContext = this ?? window;

        if (!globalCache.has(globalContext)) {
            globalCache.set(globalContext, new WeakMap<object, CachedValues>());
        }

        const cache = globalCache.get(globalContext);

        if (!cache) {
            throw new Error('Cache error. No cache.');
        }

        const context = fn;

        let values: CachedValues | undefined = cache.get(context);

        if (typeof values === 'undefined') {
            values = new Map();
        }

        const cacheKeyFn = options?.keyFn ?? defaultCacheKeyFn;
        const cacheKey = cacheKeyFn(...args);

        let value = values.get(cacheKey);

        if (typeof value === 'undefined') {
            value = {};

            try {
                value[RESULT] = fn.apply(this, args);
            } catch (e) {
                value[THROWN] = e;
            }

            values.set(cacheKey, value);
            cache.set(context, values);

            if (options?.timeout) {
                asyncScheduler.schedule(() => {
                    cache.delete(context);

                    if (options?.recall) {
                        const values = cache.get(context);
                        const value = values?.get(cacheKey);

                        if (value?.[RECALL]) {
                            cachedFn.apply(this, value[RECALL]);
                        }
                    }
                }, options.timeout);
            }
        } else if (options?.recall) {
            value[RECALL] = args;
        }

        if (value[THROWN]) {
            throw value[THROWN];
        }

        return value[RESULT];
    });

    return cachedFn as typeof fn;
}

cached.removeCache = function (globalContext: any, fnName: string): void {
    if (!isFunction(globalContext?.[fnName])) {
        return;
    }

    const cache = globalCache.get(globalContext);

    if (!cache) {
        return;
    }

    let context: any = globalContext[fnName];

    while (isFunction(context[ORIGINAL_FUNCTION])) {
        context = context[ORIGINAL_FUNCTION];
    }

    cache.delete(context);
};
