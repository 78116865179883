import {ProductState} from './ProductState';
import {isNumber} from '../../../types/guards/isNumber';
import {ProductType} from './ProductType';
import {ProductEnablement} from '../../../../core/rest-client/aat';
import {isStringEnumValue} from '../../../types/guards/isStringEnumValue';

export function isProductState(o?: any): o is ProductState {
    return isNumber(o?.id)
        && isStringEnumValue(o?.type, ProductType)
        && isStringEnumValue(o?.productEnablement, ProductEnablement);
}
