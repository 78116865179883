import {UpsertMode} from './UpsertMode';
import {hasFlag} from '../flags/hasFlag';
import {removeNullishMembers} from '../converters/removeNullishMembers';
import {QxCollection} from '../rxjs/qx/db/QxCollection';

/**
 * Fills collection with new data.
 *
 * @param collection RxDB collection
 * @param data New data
 * @param mode Upsert mode
 * @param filterFn Filtering function
 */
export async function upsertInto<T>(
    collection: QxCollection<T>,
    data: Partial<T>[],
    mode: UpsertMode = UpsertMode.REPLACE,
    filterFn?: (it: T) => boolean
): Promise<void> {
    const givenIds: string[] = data.map(it => collection.getId(it));

    if (givenIds.length) {
        await addData(collection, data, givenIds, mode);
    }

    const active__document = 'active__document';
    const isAddingMode = hasFlag(UpsertMode.ADD)(mode);
    const isRemovingMode = hasFlag(UpsertMode.REMOVE)(mode);

    if (collection.isDocProperty(active__document)) {
        collection.modify(it => {
            if (!filterFn || filterFn(it)) {
                const id = collection.getId(it);

                if (isAddingMode) {
                    if (givenIds.includes(id)) {
                        it[active__document] = true as any;
                    }
                }

                if (isRemovingMode) {
                    if (!givenIds.includes(id)) {
                        it[active__document] = false as any;
                    }
                }
            }
        });
    }
}

async function addData<T>(
    collection: QxCollection<T>,
    data: Partial<T>[],
    givenIds: string[],
    mode: UpsertMode
): Promise<void> {
    const currentDocsData = collection.__;

    const isOverwriteMode = hasFlag(UpsertMode.OVERWRITE)(mode);

    const upsertDataMap = data.reduce((memo, newDoc) => {
        const id = collection.getId(newDoc);

        const dbDoc = currentDocsData.get(id);
        const memoDoc = memo[id];

        memo[id] = {
            ...isOverwriteMode
                ? dbDoc
                : removeNullishMembers(dbDoc ?? {}),
            ...memoDoc,
            ...isOverwriteMode
                ? newDoc
                : removeNullishMembers(newDoc)
        } as Partial<T>;

        return memo;
    }, {} as Record<string, Partial<T>>);

    const upsertData = Object.values(upsertDataMap);

    if (upsertData.length) {
        try {
            await collection.upsert(upsertData);
        } catch (e) {
            console.error(`Error while upserting data into ${collection.name}: `, upsertData, e);
        }
    }
}
