/* tslint:disable */
/* eslint-disable */
/**
 * SR AAT specification
 * Description
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SearchRoleRequest
 */
export interface SearchRoleRequest {
    /**
     * 
     * @type {string}
     * @memberof SearchRoleRequest
     */
    pattern: string;
}

/**
 * Check if a given object implements the SearchRoleRequest interface.
 */
export function instanceOfSearchRoleRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "pattern" in value;

    return isInstance;
}

export function SearchRoleRequestFromJSON(json: any): SearchRoleRequest {
    return SearchRoleRequestFromJSONTyped(json, false);
}

export function SearchRoleRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): SearchRoleRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'pattern': json['pattern'],
    };
}

export function SearchRoleRequestToJSON(value?: SearchRoleRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'pattern': value.pattern,
    };
}

