import {GroupParticipantType} from "../../../core/rest-client/aat";
import {SearchType} from "../../model/types/assignees/SearchType";

export function asGroupParticipantType(type: string): GroupParticipantType {
    if (type === SearchType.PCC) {
        return GroupParticipantType.Pcc
    } else if (type === SearchType.SABRE_ID) {
        return GroupParticipantType.SabreId
    } else if (type === SearchType.COUNTRY){
        return GroupParticipantType.Country
    } else if (type === SearchType.AGENT_NAME) {
        return GroupParticipantType.AgentName
    } else {
        return GroupParticipantType.AgencyName
    }
}
