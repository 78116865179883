/* tslint:disable */
/* eslint-disable */
/**
 * SR AAT specification
 * Description
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { StatusCodes } from './StatusCodes';
import {
    StatusCodesFromJSON,
    StatusCodesFromJSONTyped,
    StatusCodesToJSON,
} from './StatusCodes';

import {
     AuthorizationStatusFromJSONTyped,
     ConfigurationResponseFromJSONTyped,
     GetRoleResponseFromJSONTyped,
     GroupParticipantsResponseFromJSONTyped,
     GroupResponseFromJSONTyped,
     GroupSettingsIdsReponseFromJSONTyped,
     GroupSettingsResponseFromJSONTyped,
     GroupsAssigneesResponseFromJSONTyped,
     GroupsResponseFromJSONTyped,
     RedAppResponseFromJSONTyped,
     RoleResponseFromJSONTyped,
     SearchRoleResponseFromJSONTyped
} from './';

/**
 * 
 * @export
 * @interface Status
 */
export interface Status {
    /**
     * 
     * @type {StatusCodes}
     * @memberof Status
     */
    status: StatusCodes;
    /**
     * 
     * @type {string}
     * @memberof Status
     */
    statusDescription?: string;
}

/**
 * Check if a given object implements the Status interface.
 */
export function instanceOfStatus(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "status" in value;

    return isInstance;
}

export function StatusFromJSON(json: any): Status {
    return StatusFromJSONTyped(json, false);
}

export function StatusFromJSONTyped(json: any, ignoreDiscriminator: boolean): Status {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    if (!ignoreDiscriminator) {
        if (json['openApiType'] === 'AuthorizationStatus') {
            return AuthorizationStatusFromJSONTyped(json, true);
        }
        if (json['openApiType'] === 'ConfigurationResponse') {
            return ConfigurationResponseFromJSONTyped(json, true);
        }
        if (json['openApiType'] === 'GetRoleResponse') {
            return GetRoleResponseFromJSONTyped(json, true);
        }
        if (json['openApiType'] === 'GroupParticipantsResponse') {
            return GroupParticipantsResponseFromJSONTyped(json, true);
        }
        if (json['openApiType'] === 'GroupResponse') {
            return GroupResponseFromJSONTyped(json, true);
        }
        if (json['openApiType'] === 'GroupSettingsIdsReponse') {
            return GroupSettingsIdsReponseFromJSONTyped(json, true);
        }
        if (json['openApiType'] === 'GroupSettingsResponse') {
            return GroupSettingsResponseFromJSONTyped(json, true);
        }
        if (json['openApiType'] === 'GroupsAssigneesResponse') {
            return GroupsAssigneesResponseFromJSONTyped(json, true);
        }
        if (json['openApiType'] === 'GroupsResponse') {
            return GroupsResponseFromJSONTyped(json, true);
        }
        if (json['openApiType'] === 'RedAppResponse') {
            return RedAppResponseFromJSONTyped(json, true);
        }
        if (json['openApiType'] === 'RoleResponse') {
            return RoleResponseFromJSONTyped(json, true);
        }
        if (json['openApiType'] === 'SearchRoleResponse') {
            return SearchRoleResponseFromJSONTyped(json, true);
        }
    }
    return {
        
        'status': StatusCodesFromJSON(json['status']),
        'statusDescription': !exists(json, 'statusDescription') ? undefined : json['statusDescription'],
    };
}

export function StatusToJSON(value?: Status | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'status': StatusCodesToJSON(value.status),
        'statusDescription': value.statusDescription,
    };
}

