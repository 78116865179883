import {isArray} from './isArray';
import {withName} from '../../helpers/functions/withName';
import {TypeGuard} from '../common/functions/TypeGuard';
import {asString} from '../../helpers/converters/asString';

export function isArrayOf<T>(fn: (o?: any) => o is T): (o?: any) => o is T[] {
    return withName<TypeGuard<T[]>>(`isArrayOf(${asString(fn)})`)((o?: any): o is T[] => {
        if (!isArray(o)) {
            return false;
        }

        return o.every(it => fn(it));
    });
}
