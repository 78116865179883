/* tslint:disable */
/* eslint-disable */
/**
 * SR AAT specification
 * Description
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ProductEnablement } from './ProductEnablement';
import {
    ProductEnablementFromJSON,
    ProductEnablementFromJSONTyped,
    ProductEnablementToJSON,
} from './ProductEnablement';
import type { RedAppPlacementType } from './RedAppPlacementType';
import {
    RedAppPlacementTypeFromJSON,
    RedAppPlacementTypeFromJSONTyped,
    RedAppPlacementTypeToJSON,
} from './RedAppPlacementType';

/**
 * 
 * @export
 * @interface Workspace
 */
export interface Workspace {
    /**
     * 
     * @type {number}
     * @memberof Workspace
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof Workspace
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof Workspace
     */
    description?: string;
    /**
     * 
     * @type {number}
     * @memberof Workspace
     */
    totalAgents?: number;
    /**
     * 
     * @type {number}
     * @memberof Workspace
     */
    usingAgents?: number;
    /**
     * 
     * @type {ProductEnablement}
     * @memberof Workspace
     */
    productEnablement?: ProductEnablement;
    /**
     * 
     * @type {RedAppPlacementType}
     * @memberof Workspace
     */
    redappPlacement?: RedAppPlacementType;
    /**
     * 
     * @type {string}
     * @memberof Workspace
     */
    redappConfiguration?: string;
}

/**
 * Check if a given object implements the Workspace interface.
 */
export function instanceOfWorkspace(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "name" in value;

    return isInstance;
}

export function WorkspaceFromJSON(json: any): Workspace {
    return WorkspaceFromJSONTyped(json, false);
}

export function WorkspaceFromJSONTyped(json: any, ignoreDiscriminator: boolean): Workspace {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'name': json['name'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'totalAgents': !exists(json, 'totalAgents') ? undefined : json['totalAgents'],
        'usingAgents': !exists(json, 'usingAgents') ? undefined : json['usingAgents'],
        'productEnablement': !exists(json, 'productEnablement') ? undefined : ProductEnablementFromJSON(json['productEnablement']),
        'redappPlacement': !exists(json, 'redappPlacement') ? undefined : RedAppPlacementTypeFromJSON(json['redappPlacement']),
        'redappConfiguration': !exists(json, 'redappConfiguration') ? undefined : json['redappConfiguration'],
    };
}

export function WorkspaceToJSON(value?: Workspace | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'name': value.name,
        'description': value.description,
        'totalAgents': value.totalAgents,
        'usingAgents': value.usingAgents,
        'productEnablement': ProductEnablementToJSON(value.productEnablement),
        'redappPlacement': RedAppPlacementTypeToJSON(value.redappPlacement),
        'redappConfiguration': value.redappConfiguration,
    };
}

