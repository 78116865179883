import {isArray} from '../../types/guards/isArray';
import {JSONSchema4} from 'json-schema';

export function getFlatSchema(schemaLike: JSONSchema4): JSONSchema4 {
    let result: any = {
        ...schemaLike
    };

    if (schemaLike.allOf) {
        for (let o of schemaLike.allOf) {
            const flatSchema = getFlatSchema(o);

            result = {
                ...result,
                ...flatSchema,
                properties: {
                    ...result.properties,
                    ...flatSchema.properties
                },
                required: [
                    ...result.required ?? [],
                    ...isArray(flatSchema.required) ? flatSchema.required : []
                ]
            };
        }

        delete result.allOf;
    }

    return result;
}
