import {useWizardIdsFromHash} from './useWizardIdsFromHash';
import {useDefaultWizard} from './useDefaultWizard';
import {HashWizardHelpers} from './HashWizardHelpers';
import {useControllers} from '../../../../../common/helpers/react/hooks/useControllers';
import {isNumber} from '../../../../../common/types/guards/isNumber';
import {removeHash} from '../../../../../common/helpers/browser/removeHash';
import {useForceUpdateUntil} from '../../../../../common/helpers/react/hooks/useForceUpdateUntil';
import {getCurrentStep} from './getCurrentStep';

export function useHashWizard(): HashWizardHelpers {
    const http = useControllers().http;
    const ids = useWizardIdsFromHash();

    const helpers = useDefaultWizard(ids.wizardId, ids.step);

    const goToStep = async function (step: string | number, offsetIfDisabled: number = 1): Promise<void> {
        let stepName: string | undefined;

        const wizardData = helpers.wizardData;

        if (wizardData) {
            if (isNumber(step)) {
                let stepObject;

                do {
                    if (step < 0 || step > wizardData.steps.length - 1) {
                        stepName = undefined;
                        break;
                    }

                    stepObject = wizardData.steps[step];

                    if (stepObject && !stepObject?.disabled) {
                        stepName = stepObject.name;
                        break;
                    }

                    if (offsetIfDisabled === 0) {
                        stepName = undefined;
                        break;
                    }

                    step += offsetIfDisabled;
                } while (!stepObject || stepObject.disabled);
            } else {
                stepName = step;
            }
        }

        const currentStep = getCurrentStep(wizardData?.steps ?? [], stepName ?? '');

        if (currentStep && !currentStep.disabled) {
            http.navigateTo(`${removeHash(ids.wizardId)}/${stepName}`);
        } else {
            console.warn(`Cannot navigate to ${step} step`);
        }
    };

    useForceUpdateUntil(!helpers.isLoading);

    return {
        ...helpers,
        goToStep,
        goToNextStep: async function () {
            await goToStep(helpers.stepIndex + 1, +1);
        },
        goToPrevStep: async function () {
            await goToStep(helpers.stepIndex - 1, -1);
        },
        isEditing: ids.isEditing
    };
}
