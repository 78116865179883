import {Pyped} from './Pyped';
import {throttled} from '../../helpers/functions/throttled';
import {THROTTLING_INTERVAL} from '../../CONST';
import {CachingOptions} from '../../helpers/functions/cached';

export function Throttled(intervalOrOptions: number | CachingOptions = THROTTLING_INTERVAL): MethodDecorator {
    return Pyped([
        () => (fn) => throttled(fn, intervalOrOptions)
    ]);
}
