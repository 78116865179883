/* tslint:disable */
/* eslint-disable */
/**
 * SR AAT specification
 * Description
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const ProductEnablement = {
    On: 'ON',
    Off: 'OFF',
    Default: 'DEFAULT'
} as const;
export type ProductEnablement = typeof ProductEnablement[keyof typeof ProductEnablement];


export function ProductEnablementFromJSON(json: any): ProductEnablement {
    return ProductEnablementFromJSONTyped(json, false);
}

export function ProductEnablementFromJSONTyped(json: any, ignoreDiscriminator: boolean): ProductEnablement {
    return json as ProductEnablement;
}

export function ProductEnablementToJSON(value?: ProductEnablement | null): any {
    return value as any;
}

