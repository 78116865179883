import {Controller} from '../Controller';
import {map, Observable} from 'rxjs';
import {DataSource} from '../../common/decorators/rxdb/DataSource';
import {DataSourceKind} from '../../common/decorators/rxdb/DataSourceKind';
import {Settings} from '../../common/model/types/Settings';
import i18next from 'i18next';
import {throttleLikeABoss} from '../../common/helpers/rxjs/throttleLikeABoss';
import {detectLanguage} from '../../common/helpers/browser/detectLanguage';
import {isDefined} from '../../common/types/guards/isDefined';
import {asString} from '../../common/helpers/converters/asString';
import {RxMiscData} from '../../common/model/db/types/RxMiscData';
import {MiscDataObject} from '../../common/helpers/react/hooks/useMiscData';
import {isString} from '../../common/types/guards/isString';
import {Cached} from '../../common/decorators/methods/Cached';
import {QxAction} from '../../common/helpers/rxjs/qx/db/QxAction';

export class SettingsController extends Controller {
    #ORDER_OF_SEARCH_PARAMS = 20000;
    #ORDER_OF_SET = 30000;

    @DataSource()
    getAllSettings$(): Observable<Settings> {
        return this.db.settings.$.pipe(
            map(it => {
                const settings = Object.assign({}, ...it);

                delete settings.id;
                delete settings.order;

                return settings;
            })
        );
    }

    @DataSource(DataSourceKind.PARAMETRIZED)
    get$<TValue extends Settings[TKey], TKey extends keyof Settings>(key: TKey): Observable<TValue> {
        return this.getAllSettings$().pipe(
            map((it) => it[key] as any as TValue)
        );
    }

    async set<TValue extends Settings[TKey], TKey extends keyof Settings>(key: TKey, value: TValue): Promise<void> {
        await this.db.settings.upsert({
            id: this.#getSetId(key),
            [key]: value,
            order: this.#ORDER_OF_SET
        });
    }

    async delete<TKey extends keyof Settings>(key: TKey): Promise<void> {
        this.db.settings.delete(this.#getSetId(key));
    }

    @DataSource(DataSourceKind.PARAMETRIZED)
    getMiscData$(id: string): Observable<MiscDataObject> {
        return this.db.misc_data.getById$(id).pipe(
            map(it => it ? it.data : null)
        );
    }

    @Cached()
    getAllMiscDataAsMap$(id: string | RegExp): Observable<Record<string, MiscDataObject>> {
        const filterFn = this.getMiscDataFilterFn(id);

        return this.db.misc_data.$.pipe(
            map(it => {
                const result: Record<string, MiscDataObject> = {};

                it.forEach(it => {
                    if (filterFn(it)) {
                        result[it.id] = it.data;
                    }
                });

                return result;
            })
        );
    }

    async setMiscData<T extends RxMiscData['data']>(id: string, data: T): Promise<void> {
        await this.db.misc_data
            .upsert({id, data});
    }

    async deleteMiscData(id: string | RegExp): Promise<void> {
        const filterFn = this.getMiscDataFilterFn(id);
        this.db.misc_data.modify(it => filterFn(it) ? QxAction.DELETE : undefined);
    }

    isSettingsKey(o?: any): o is keyof Settings {
        return this.db.settings.isDocProperty(o)
            && o !== 'id'
            && o !== 'order';
    }

    protected override init() {
        super.init();

        this.#observeLanguage();

        this.get$('use_search_params')
            .subscribe(it => {
                if (it === true) {
                    const searchParams = this.controllers.http.getUrlAsObject().searchParams;
                    const doc: Record<string, any> = {
                        id: 'search_params',
                        order: this.#ORDER_OF_SEARCH_PARAMS
                    };

                    for (let [key, value] of searchParams) {
                        if (this.isSettingsKey(key)) {
                            doc[key] = value;
                        }
                    }

                    this.db.settings.upsert(doc);
                }
            });
    }

    @Cached()
    private getMiscDataFilterFn(id: string | RegExp): (it: Partial<RxMiscData>) => boolean {
        return function (it: Partial<RxMiscData>) {
            if (!isDefined(it.id)) {
                return false;
            } else if (isString(id)) {
                return id === it.id;
            } else {
                return id.test(it.id);
            }
        };
    }

    #getSetId<TKey>(key: TKey): string {
        return `set-${asString(key)}`;
    }

    #observeLanguage() {
        this.get$('lang')
            .pipe(
                throttleLikeABoss()
            )
            .subscribe((lang = detectLanguage()) => {
                i18next.changeLanguage(lang)
                    .then(() => document.documentElement.lang = lang);
            });
    }
}
