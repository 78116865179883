import {AnyFn} from '../../types/common/functions/AnyFn';
import {isDefined} from '../../types/guards/isDefined';

export function withName<T extends AnyFn>(name?: string | AnyFn): (fn: T) => T {
    if (!isDefined(name)) {
        return (fn) => fn;
    }

    if (typeof name === 'function') {
        name = Object.getOwnPropertyDescriptor(name, 'name')?.value ?? 'unknown';
    }

    return (fn: T): T => {
        Object.defineProperty(fn, 'name', {value: name});
        return fn;
    };
}
