import {Button} from '@sabre/spark-react-core';
import {ButtonSize} from '@sabre/spark-react-core/types';
import {tAsString} from '../../../../common/helpers/react/text/tAsString';
import React from 'react';
import {useControllers} from '../../../../common/helpers/react/hooks/useControllers';
import {cssClasses} from '../../../../common/helpers/browser/cssClasses';

export default function LogoutButton() {
    const auth = useControllers().auth;

    return (
        <Button
            textOnly={true}
            size={ButtonSize.EXTRA_SMALL}
            secondary={true}
            onClick={() => auth.killSession('Logout button clicked')}
            className={cssClasses(
                'qa-sign-out-button'
            )}
        >{tAsString('SIGN_OUT')}</Button>
    );
}
