import {VoidFn} from '../../types/common/functions/VoidFn';
import {getSignature} from '../../helpers/signature/getSignature';

export function keyFunction(prop: string | symbol, args: any[]) {
    return Symbol.for(getSignature([prop, args]));
}

export const cacheCleaners: Record<symbol, VoidFn> = {};

export function Cached(keyFn: (prop: string | symbol, args: any[]) => symbol = keyFunction): MethodDecorator {
    return (proto: any, prop: string | symbol, desc: PropertyDescriptor) => {
        const FN = Symbol(`@Cached(${String(prop)})`);

        delete desc.value;
        delete desc.get;

        proto[FN] = proto[prop];
        proto[prop] = function (this: typeof proto, ...args: any) {
            const cacheKey = keyFn(prop, args);

            cacheCleaners[cacheKey] ??= () => {
                delete this[cacheKey];
                delete cacheCleaners[cacheKey];
            };

            if (!this) {
                debugger;
            }

            return this[cacheKey] ??= proto[FN].apply(this, args);
        };
    };
}
