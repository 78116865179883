import {AnyFn} from '../../types/common/functions/AnyFn';
import {withName} from './withName';

export function noop<P extends any[] = any[], R extends any = any>(name?: string, argCount: number = 0): AnyFn<P, R> {
    let fn = () => undefined as R;

    Object.defineProperty(fn, 'length', {value: argCount});

    return withName(name)(fn);
}
