import {AnyFn} from '../../types/common/functions/AnyFn';
import JSON5 from 'json5';

export function usingJSON5<T extends AnyFn>(fn: T): (...args: Parameters<T>) => ReturnType<T> {
    return function (this: unknown, ...args: Parameters<T>): ReturnType<T> {
        const stringify = JSON.stringify;
        const parse = JSON.parse;

        JSON.stringify = JSON5.stringify;
        JSON.parse = JSON5.parse;

        const result = fn.apply(this, args);

        JSON.stringify = stringify;
        JSON.parse = parse;

        return result;
    };
}
