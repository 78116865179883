import {MILLISECOND} from '../../CONST';

/**
 * Returns Promise which resolves after given time.
 *
 * If number passed to function is positive X,
 * Promise is resolved after given number of X milliseconds.
 *
 * If number passed to function is negative Y,
 * Promise is resolved after given number of -Y milliseconds or when idle period appears.
 *
 * By default, Promise resolves after 100ms.
 *
 * @example
 *
 *      await sleep() // resolves after 100ms
 *      await sleep(10 * SECOND) // resolves after 10s
 *      await sleep(-MINUTE) // resolves after 1min or when idle period appears
 *
 * @param wakeUpAfter Time in milliseconds after which Promise is resolved (see description for details).
 * @returns Promise which is resolved after given time
 */
export async function sleep(wakeUpAfter: number = 100 * MILLISECOND): Promise<void> {
    return wakeUpAfter < 0
        ? new Promise(res => requestIdleCallback(() => res(), {timeout: -wakeUpAfter}))
        : new Promise(res => setTimeout(() => res(), wakeUpAfter));
}
