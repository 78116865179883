import {To} from 'react-router';
import {useControllers} from '../../../../common/helpers/react/hooks/useControllers';
import {useEffect} from 'react';
import {sleep} from '../../../../common/helpers/misc/sleep';

export type PropsWithUrl = {
    url: string;
    target?: '_blank' | '_self' | '_parent' | '_top';
    replace?: boolean;
};

export type PropsWithTo = { to: To; replace?: boolean };

export type PropsWithReload = { reload?: boolean }

type PropsWithNoDestination = { url?: string, to?: To };

export type GoToProps = (PropsWithUrl | PropsWithTo | PropsWithReload | PropsWithNoDestination) & {
    delay?: number;
    before?: () => Promise<void>;
    fallback?: JSX.Element
};

export default function GoTo(props: GoToProps) {
    const http = useControllers().http;

    async function goto() {
        await sleep(props.delay);
        await props.before?.();

        if (hasUrl(props)) {
            if (props.target) {
                http.open(props.url, props.target)?.focus();
            } else if (document.location.href !== props.url) {
                http.redirect(props.url, props.replace);
            }
        } else if (hasTo(props)) {
            const to = http.getUrlAsString(props.to);

            if (to.match(/^https?:\/\//)) {
                http.redirect(to, props.replace);
            } else {
                http.navigateTo(to, props.replace);
            }
        } else if (hasReload(props)) {
            if (props.reload) {
                http.reload();
            }
        }
    }

    useEffect(() => {
        goto().andWeAreDone();
    });

    return props.fallback ?? <></>;
}

function hasUrl(o: any): o is PropsWithUrl {
    return typeof o?.url !== 'undefined';
}

function hasTo(o: any): o is PropsWithTo {
    return typeof o?.to !== 'undefined';
}

function hasReload(o: any): o is PropsWithReload {
    return typeof o?.reload !== 'undefined';
}
