import {ColumnDescription} from './ColumnDescription';
import React from 'react';
import {asString} from '../../../../../common/helpers/converters/asString';
import {isCheckboxMode} from './helpers/isCheckboxMode';
import {Checkbox, TableCell} from '@sabre/spark-react-core';
import {cssClasses} from '../../../../../common/helpers/browser/cssClasses';
import styles from './DefaultTable.module.css';
import {getColumnClassName} from './helpers/getColumnClassName';
import {getColumnStyle} from './helpers/getColumnStyle';
import {DefaultTableCheckboxModeProps, DefaultTableProps} from './DefaultTable';

export type DefaultTableCellProps<T> = Partial<DefaultTableCheckboxModeProps> & {
    column: ColumnDescription<T>,
    columnIdx: number,
    data: T,
    idGetter: NonNullable<DefaultTableProps<T>['idGetter']>
}

export default function DefaultTableCell<T>(props: DefaultTableCellProps<T>) {
    const c = props.column;
    const it = props.data;

    let value = c.render
        ? c.render(it, c)
        : c.name
            ? asString(it[c.name] ?? c.undefinedFallback)
            : '';

    if (props.columnIdx === 0 && isCheckboxMode(props)) {
        const id = props.idGetter(it);

        value = <Checkbox
            label={value}
            onChange={e => {
                if (isCheckboxMode(props)) {
                    props.setCheckedRows([
                        ...props.checkedRows.filter(it => it !== id),
                        ...(e.target.checked ? [id] : [])
                    ]);
                }
            }}
            checked={props.checkedRows.includes(id)}
            className={cssClasses(
                'qa-row-checkbox',
                styles.CheckboxRow
            )}/>;
    }

    const cellProps = c.cellProps?.(it, c);

    const newCellProps = {
        ...cellProps,
        className: getColumnClassName(
            c,
            cellProps?.className
        ),
        style: getColumnStyle(
            c,
            cellProps?.style
        )
    };

    return <TableCell
        title={typeof value === 'string' ? value : undefined}
        {...newCellProps}
    >{value}</TableCell>;
}
