import {useState} from 'react';
import {asyncScheduler} from 'rxjs';
import {THROTTLING_INTERVAL} from '../../../CONST';
import {useAsyncEffect} from './useAsyncEffect';
import {Factory} from '../../../types/common/functions/Factory';
import {isFunction} from '../../../types/guards/isFunction';

export function useForceUpdateUntil(
    done: boolean | Factory<boolean> = false,
    interval: number = THROTTLING_INTERVAL
): unknown {
    const [tick, setTick] = useState(Symbol());

    useAsyncEffect(() => {
        if (isFunction(done)) {
            done = done();
        }

        if (!done) {
            asyncScheduler.schedule(() => setTick(Symbol()), interval);
        }
    });

    return tick;
}
