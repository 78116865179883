import {isPlainObject} from './isPlainObject';
import {asString} from '../../helpers/converters/asString';
import {withName} from '../../helpers/functions/withName';
import {TypeGuard} from '../common/functions/TypeGuard';
import {ObjectIndex} from '../common/index/ObjectIndex';

export function isRecordOf<T>(fn: (o?: any) => o is T): (o?: any) => o is Record<ObjectIndex, T> {
    return withName<TypeGuard<Record<ObjectIndex, T>>>(`isRecordOf(${asString(fn)})`)((o?: any): o is  Record<ObjectIndex, T> => {
        if (!isPlainObject(o)) {
            return false;
        }

        return Object.values(o).every(it => fn(it));
    });
}
