/* tslint:disable */
/* eslint-disable */
/**
 * SR AAT specification
 * Description
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  AuthorizationStatus,
  ConfigurationResponse,
  CreateConfigurationRequest,
  Status,
  UpdateConfigurationRequest,
} from '../models';
import {
    AuthorizationStatusFromJSON,
    AuthorizationStatusToJSON,
    ConfigurationResponseFromJSON,
    ConfigurationResponseToJSON,
    CreateConfigurationRequestFromJSON,
    CreateConfigurationRequestToJSON,
    StatusFromJSON,
    StatusToJSON,
    UpdateConfigurationRequestFromJSON,
    UpdateConfigurationRequestToJSON,
} from '../models';

export interface CreateConfigurationOperationRequest {
    createConfigurationRequest: CreateConfigurationRequest;
    authorization?: string;
    onBehalfOf?: string;
}

export interface DeleteConfigurationsRequest {
    requestBody: Array<number>;
    authorization?: string;
    onBehalfOf?: string;
}

export interface GetConfigurationsRequest {
    authorization?: string;
    onBehalfOf?: string;
}

export interface UpdateConfigurationOperationRequest {
    id: number;
    updateConfigurationRequest: UpdateConfigurationRequest;
    authorization?: string;
    onBehalfOf?: string;
}

/**
 * 
 */
export class ConfigurationsApi extends runtime.BaseAPI {

    /**
     * Creates a configuration
     */
    async createConfigurationRaw(requestParameters: CreateConfigurationOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ConfigurationResponse>> {
        if (requestParameters.createConfigurationRequest === null || requestParameters.createConfigurationRequest === undefined) {
            throw new runtime.RequiredError('createConfigurationRequest','Required parameter requestParameters.createConfigurationRequest was null or undefined when calling createConfiguration.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (requestParameters.onBehalfOf !== undefined && requestParameters.onBehalfOf !== null) {
            headerParameters['On-Behalf-Of'] = String(requestParameters.onBehalfOf);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/configurations`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateConfigurationRequestToJSON(requestParameters.createConfigurationRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ConfigurationResponseFromJSON(jsonValue));
    }

    /**
     * Creates a configuration
     */
    async createConfiguration(requestParameters: CreateConfigurationOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ConfigurationResponse> {
        const response = await this.createConfigurationRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Endpoint for deleting configurations
     * Delete Configurations Endpoint
     */
    async deleteConfigurationsRaw(requestParameters: DeleteConfigurationsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Status>> {
        if (requestParameters.requestBody === null || requestParameters.requestBody === undefined) {
            throw new runtime.RequiredError('requestBody','Required parameter requestParameters.requestBody was null or undefined when calling deleteConfigurations.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (requestParameters.onBehalfOf !== undefined && requestParameters.onBehalfOf !== null) {
            headerParameters['On-Behalf-Of'] = String(requestParameters.onBehalfOf);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/configurations:batchDelete`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.requestBody,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => StatusFromJSON(jsonValue));
    }

    /**
     * Endpoint for deleting configurations
     * Delete Configurations Endpoint
     */
    async deleteConfigurations(requestParameters: DeleteConfigurationsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Status> {
        const response = await this.deleteConfigurationsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Returns all configurations
     * Configurations
     */
    async getConfigurationsRaw(requestParameters: GetConfigurationsRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ConfigurationResponse>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (requestParameters.onBehalfOf !== undefined && requestParameters.onBehalfOf !== null) {
            headerParameters['On-Behalf-Of'] = String(requestParameters.onBehalfOf);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/configurations`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ConfigurationResponseFromJSON(jsonValue));
    }

    /**
     * Returns all configurations
     * Configurations
     */
    async getConfigurations(requestParameters: GetConfigurationsRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ConfigurationResponse> {
        const response = await this.getConfigurationsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update a configuration.
     */
    async updateConfigurationRaw(requestParameters: UpdateConfigurationOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<ConfigurationResponse>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateConfiguration.');
        }

        if (requestParameters.updateConfigurationRequest === null || requestParameters.updateConfigurationRequest === undefined) {
            throw new runtime.RequiredError('updateConfigurationRequest','Required parameter requestParameters.updateConfigurationRequest was null or undefined when calling updateConfiguration.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (requestParameters.onBehalfOf !== undefined && requestParameters.onBehalfOf !== null) {
            headerParameters['On-Behalf-Of'] = String(requestParameters.onBehalfOf);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/configurations/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateConfigurationRequestToJSON(requestParameters.updateConfigurationRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ConfigurationResponseFromJSON(jsonValue));
    }

    /**
     * Update a configuration.
     */
    async updateConfiguration(requestParameters: UpdateConfigurationOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<ConfigurationResponse> {
        const response = await this.updateConfigurationRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
