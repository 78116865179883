import {Panel} from '@sabre/spark-react-core';
import SwaggerUI from 'swagger-ui-react';
import React from 'react';
import {useControllers} from '../../../../../common/helpers/react/hooks/useControllers';
import LoadingBlock from '../../../common/placeholders/loading/LoadingBlock';
import {useOnce} from '../../../../../common/helpers/react/hooks/useOnce';
import {tAsString} from '../../../../../common/helpers/react/text/tAsString';
import 'swagger-ui-react/swagger-ui.css';

export default function ApiPresenter() {
    const controllers = useControllers();

    const atk = controllers.auth.getATK$().asState();
    const serverUrl = controllers.settings.get$('url_aat').asState();
    const env = controllers.settings.get$('env').asState();

    const spec = useOnce(() => {
        return fetch('./api/sr-aat.json')
            .then(response => {
                return response.json();
            });
    }).asState();

    const isLoaded = atk && env && spec && serverUrl;

    if (isLoaded && spec.servers[0]?.url !== serverUrl) {
        spec.servers = [{
            url: serverUrl,
            description: tAsString(`X_CONFIGURATION_SERVER`, {
                configuration: env.toUpperCase()
            })
        }] as ServerInfo[];
    }

    const swaggerUI = isLoaded
        ? <SwaggerUI
            spec={spec}
            docExpansion={'none'}
            requestInterceptor={req => {
                req.headers ??= {};
                req.headers['Authorization'] ??= `Bearer: ${atk}`;
                return req;
            }}
        />
        : <LoadingBlock/>;

    return (
        <>
            <h1>{tAsString('API_PAGE_HEADER')}</h1>
            <Panel>{swaggerUI}</Panel>
        </>
    );
}

type ServerInfo = { url: string, description?: string };
