import {isDescribedByJsonSchema} from './isDescribedByJsonSchema';
import {JSONSchema4} from 'json-schema';

export function isPartiallyDescribedByJsonSchema<T>(
    o?: any,
    jsonSchema?: JSONSchema4,
    strict: boolean = false,
    isImportant: boolean = true
): o is Partial<T> {
    if (!jsonSchema) {
        return false;
    }

    const partialSchema = {...jsonSchema};
    delete partialSchema.required;
    partialSchema.additionalProperties = true;

    Object.keys(partialSchema.properties ?? {})
        .forEach(it => {
            const property = partialSchema.properties?.[it];

            if (property?.type) {
                property.nullable = true;
            }
        });

    return isDescribedByJsonSchema<T>(o, partialSchema, strict, isImportant);
}
