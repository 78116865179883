import {useOnce} from './useOnce';
import {useEffect} from 'react';
import {HTML_ID} from '../../id/HTML_ID';

export function useRefByClassName<T extends Element>(initialValue?: T | null) {
    return useOnce(() => {
        const className = HTML_ID();

        return {
            className,
            get current() {
                return document.querySelector<T>(`.${className}`) ?? initialValue;
            },
            useRef: function (fn: (el: T) => void): void {
                useEffect(() => {
                    const el = this.current;

                    if (el) {
                        fn(el);
                    }
                });
            }
        };
    });
}
