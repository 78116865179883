import {Service} from '../../../core/services/Service';
import {getMockedController} from './getMockedController';
import {AnyFn} from '../../types/common/functions/AnyFn';
import {AnyIndex} from '../../types/common/index/AnyIndex';

export function getMockedService<T extends Service>(
    mockedValues?: Partial<T>,
    defaultMockFactory?: AnyFn<[AnyIndex]>
): T {
    return getMockedController<T>(mockedValues, defaultMockFactory);
}
