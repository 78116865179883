/* tslint:disable */
/* eslint-disable */
/**
 * SR AAT specification
 * Description
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { UpdateConfigurationRequest } from './UpdateConfigurationRequest';
import {
    UpdateConfigurationRequestFromJSON,
    UpdateConfigurationRequestFromJSONTyped,
    UpdateConfigurationRequestToJSON,
} from './UpdateConfigurationRequest';

/**
 * 
 * @export
 * @interface CreateConfigurationRequest
 */
export interface CreateConfigurationRequest {
    /**
     * 
     * @type {string}
     * @memberof CreateConfigurationRequest
     */
    redappName: string;
    /**
     * 
     * @type {string}
     * @memberof CreateConfigurationRequest
     */
    configurationName: string;
    /**
     * 
     * @type {string}
     * @memberof CreateConfigurationRequest
     */
    value: string;
    /**
     * 
     * @type {string}
     * @memberof CreateConfigurationRequest
     */
    description: string;
}

/**
 * Check if a given object implements the CreateConfigurationRequest interface.
 */
export function instanceOfCreateConfigurationRequest(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "redappName" in value;
    isInstance = isInstance && "configurationName" in value;
    isInstance = isInstance && "value" in value;
    isInstance = isInstance && "description" in value;

    return isInstance;
}

export function CreateConfigurationRequestFromJSON(json: any): CreateConfigurationRequest {
    return CreateConfigurationRequestFromJSONTyped(json, false);
}

export function CreateConfigurationRequestFromJSONTyped(json: any, ignoreDiscriminator: boolean): CreateConfigurationRequest {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'redappName': json['redappName'],
        'configurationName': json['configurationName'],
        'value': json['value'],
        'description': json['description'],
    };
}

export function CreateConfigurationRequestToJSON(value?: CreateConfigurationRequest | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'redappName': value.redappName,
        'configurationName': value.configurationName,
        'value': value.value,
        'description': value.description,
    };
}

