import {Database} from '../common/model/db/Database';
import {Config_core} from '../common/core/entry-point/Config_core';
import {Once} from '../common/decorators/methods/Once';
import Comparators from 'comparators';
import {ObservableFlag} from '../common/types/rxjs/ObservableFlag';
import {Observable} from 'rxjs';

export abstract class Controller {
    protected priority: number = 0;

    protected readonly db!: Database;
    protected readonly controllers!: Config_core['controllers'];
    protected readonly services!: Config_core['services'];

    static async feed(config: Config_core): Promise<void> {
        const hungryGuys = [
            ...Object.values(config.controllers),
            ...Object.values(config.services)
        ];

        const repletionPromises = hungryGuys
            .map(it => it as any as FriendlyController)
            .sort(Comparators.comparing<FriendlyController>('priority'))
            .map(it => Controller.feedOne(config, it));

        return Promise.all(repletionPromises)
            .then(fullGuys => fullGuys.forEach(it => it.init()));
    }

    private static async feedOne(config: Config_core, controller: FriendlyController): Promise<FriendlyController> {
        controller.controllers = config.controllers;
        controller.services = config.services;
        controller.db = await config.services.database.getInstance();

        return controller;
    }

    @Once()
    isBusy$(): Observable<boolean | string> {
        return this.getBusyFlag().$;
    }

    @Once()
    protected init(): void {
        // pass
    };

    @Once()
    protected teardown(): void {
        // pass
    };

    @Once()
    protected getBusyFlag(): ObservableFlag {
        return new ObservableFlag();
    }
}

interface FriendlyController {
    priority: Controller['priority'];

    db: Controller['db'];
    controllers: Controller['controllers'];
    services: Controller['services'];

    init: Controller['init'];
}
