/* tslint:disable */
/* eslint-disable */
/**
 * SR AAT specification
 * Description
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Agency } from './Agency';
import {
    AgencyFromJSON,
    AgencyFromJSONTyped,
    AgencyToJSON,
} from './Agency';
import type { Agent } from './Agent';
import {
    AgentFromJSON,
    AgentFromJSONTyped,
    AgentToJSON,
} from './Agent';
import type { Pcc } from './Pcc';
import {
    PccFromJSON,
    PccFromJSONTyped,
    PccToJSON,
} from './Pcc';
import type { Status } from './Status';
import {
    StatusFromJSON,
    StatusFromJSONTyped,
    StatusToJSON,
} from './Status';
import type { StatusCodes } from './StatusCodes';
import {
    StatusCodesFromJSON,
    StatusCodesFromJSONTyped,
    StatusCodesToJSON,
} from './StatusCodes';

/**
 * 
 * @export
 * @interface GroupsAssigneesResponse
 */
export interface GroupsAssigneesResponse extends Status {
    /**
     * 
     * @type {Array<Agency>}
     * @memberof GroupsAssigneesResponse
     */
    agencies: Array<Agency>;
    /**
     * 
     * @type {Array<Pcc>}
     * @memberof GroupsAssigneesResponse
     */
    pccs: Array<Pcc>;
    /**
     * 
     * @type {Array<Agent>}
     * @memberof GroupsAssigneesResponse
     */
    agents: Array<Agent>;
}

/**
 * Check if a given object implements the GroupsAssigneesResponse interface.
 */
export function instanceOfGroupsAssigneesResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "agencies" in value;
    isInstance = isInstance && "pccs" in value;
    isInstance = isInstance && "agents" in value;

    return isInstance;
}

export function GroupsAssigneesResponseFromJSON(json: any): GroupsAssigneesResponse {
    return GroupsAssigneesResponseFromJSONTyped(json, false);
}

export function GroupsAssigneesResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GroupsAssigneesResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        ...StatusFromJSONTyped(json, ignoreDiscriminator),
        'agencies': ((json['agencies'] as Array<any>).map(AgencyFromJSON)),
        'pccs': ((json['pccs'] as Array<any>).map(PccFromJSON)),
        'agents': ((json['agents'] as Array<any>).map(AgentFromJSON)),
    };
}

export function GroupsAssigneesResponseToJSON(value?: GroupsAssigneesResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        ...StatusToJSON(value),
        'agencies': ((value.agencies as Array<any>).map(AgencyToJSON)),
        'pccs': ((value.pccs as Array<any>).map(PccToJSON)),
        'agents': ((value.agents as Array<any>).map(AgentToJSON)),
    };
}

