/* tslint:disable */
/* eslint-disable */
/**
 * SR AAT specification
 * Description
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface PropertyUpdate
 */
export interface PropertyUpdate {
    /**
     * 
     * @type {number}
     * @memberof PropertyUpdate
     */
    propertyId: number;
    /**
     * 
     * @type {string}
     * @memberof PropertyUpdate
     */
    overrideValue?: string;
    /**
     * 
     * @type {boolean}
     * @memberof PropertyUpdate
     */
    allowUserOverride: boolean;
}

/**
 * Check if a given object implements the PropertyUpdate interface.
 */
export function instanceOfPropertyUpdate(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "propertyId" in value;
    isInstance = isInstance && "allowUserOverride" in value;

    return isInstance;
}

export function PropertyUpdateFromJSON(json: any): PropertyUpdate {
    return PropertyUpdateFromJSONTyped(json, false);
}

export function PropertyUpdateFromJSONTyped(json: any, ignoreDiscriminator: boolean): PropertyUpdate {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'propertyId': json['propertyId'],
        'overrideValue': !exists(json, 'overrideValue') ? undefined : json['overrideValue'],
        'allowUserOverride': json['allowUserOverride'],
    };
}

export function PropertyUpdateToJSON(value?: PropertyUpdate | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'propertyId': value.propertyId,
        'overrideValue': value.overrideValue,
        'allowUserOverride': value.allowUserOverride,
    };
}

