import {HashWizardHelpers} from '../../../../../common/components/wizards/HashWizardHelpers';
import {GroupWizardDetailsStepFormValues} from './GroupWizardDetailsStepFormValues';
import {Controller, useForm} from 'react-hook-form';
import {asyncScheduler} from 'rxjs';
import {TextInput} from '@sabre/spark-react-core';
import {MessageStatus} from '@sabre/spark-react-core/types';
import {default as React, useEffect} from 'react';
import {useI18Next} from '../../../../../../../common/helpers/react/hooks/useI18Next';
import * as yup from 'yup';
import {yupResolver} from '@hookform/resolvers/yup';
import {tAsString} from '../../../../../../../common/helpers/react/text/tAsString';

export type GroupWizardDetailsStepFormProps = {
    wizardHelpers: Pick<HashWizardHelpers, 'stepData' | 'setStepData' | 'isLoading' | 'step' | 'isEditing'>;
    initialData: Partial<GroupWizardDetailsStepFormValues>;
}

export function getYupValidationSchema() {
    return yup.object({
        name: yup
            .string()
            .required(tAsString('PROVIDE_A_GROUP_NAME')),
        description: yup
            .string()
            .nullable()
    });
}

export default function GroupWizardDetailsStepForm(props: GroupWizardDetailsStepFormProps) {
    const {t} = useI18Next();

    const wh = props.wizardHelpers;

    const {control, formState: {errors}, watch} = useForm<GroupWizardDetailsStepFormValues>({
        mode: 'all',
        resolver: yupResolver(getYupValidationSchema())
    });

    useEffect(() => {
        const {unsubscribe} = watch((formData) => {
            const dataChanged = wh.stepData?.name !== formData.name
                || wh.stepData?.description !== formData.description;

            if (dataChanged) {
                wh.setStepData(formData).andWeAreDone();
            }
        });

        return () => unsubscribe();
    }, [watch]);

    return (
        <form>
            <div className={'row'}>
                <div className={'col-lg-5 spark-pad-b-1--lte-md'}>
                    <Controller
                        name="name"
                        control={control}
                        defaultValue={props.initialData.name}
                        render={({field}) => {
                            if (field.value) {
                                asyncScheduler.schedule(() => {
                                    document
                                        .querySelector<HTMLElement>('#qa-group-name-input')
                                        ?.focus?.();
                                }, 30);
                            }

                            return <TextInput
                                name={field.name}
                                value={field.value}
                                onChange={field.onChange}
                                onBlur={field.onBlur}
                                label={t('GROUP_NAME')}
                                status={errors[field.name] ? MessageStatus.ERROR : undefined}
                                statusMessage={errors[field.name]?.message}
                                className={'qa-group-name-input'}
                            />;
                        }}
                    />
                </div>
                <div className={'col-lg-7'}>
                    <Controller
                        name="description"
                        control={control}
                        defaultValue={props.initialData.description}
                        render={({field}) => (
                            <TextInput
                                name={field.name}
                                value={field.value}
                                onChange={field.onChange}
                                onBlur={field.onBlur}
                                className={'qa-group-description-input'}
                                label={t('DESCRIPTION')}
                                optionalLabel={`(${t('OPTIONAL')})`}
                            />
                        )}
                    />
                </div>
            </div>
        </form>
    );
}
