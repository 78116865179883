import {isDefined} from '../../types/guards/isDefined';

export function asArray<T>(o?: T | T[]): T[] {
    if (Array.isArray(o)) {
        return o;
    } else if (isDefined(o)) {
        return [o];
    } else {
        return [];
    }
}
