import {Panel} from '@sabre/spark-react-core';
import LoadingBlock from './LoadingBlock';
import {default as React} from 'react';
import {useI18Next} from '../../../../../common/helpers/react/hooks/useI18Next';
import {useTimeout} from '../../../../../common/helpers/react/hooks/useTimeout';

export type LoadingContentProps = {
    height?: number,
    timeout?: number,
    title?: string
}

export default function LoadingContent(props: LoadingContentProps) {
    const {t} = useI18Next();

    const timeout = useTimeout(props.timeout);

    const title = props.title ?? `${t('LOADING')}...`;
    const header = timeout ? t('TIMEOUT') : title;

    return (
        <>
            <h1>{header}</h1>
            <Panel>
                <LoadingBlock height={props.height ?? 194} timeout={props.timeout}/>
            </Panel>
        </>
    );
}
