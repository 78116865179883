import {Witcher} from '../../../../../../common/witcher/Witcher';
import {RolesWitcherData} from '../../../../../../controllers/roles/RolesWitcherData';
import React from 'react';
import {isInstanceOf} from '../../../../../../common/types/guards/isInstanceOf';
import {TextInput} from '@sabre/spark-react-core';
import {MessageStatus} from '@sabre/spark-react-core/types';
import {tAsString} from '../../../../../../common/helpers/react/text/tAsString';

export type RolesWitcherFormProps = {
    submit: () => void;
    witcher?: Witcher<RolesWitcherData>
}

export default function RolesWitcherForm<TData>(props: RolesWitcherFormProps) {
    const witcher = props.witcher;
    const witcherData = witcher?.getData$().asState();
    const witcherValidation = witcher?.validate$().asState();
    const fieldErrors = witcherValidation?.fieldErrors;

    function onKeydown(e: React.KeyboardEvent<HTMLInputElement>) {
        if (e.key === 'Enter') {
            e.preventDefault();
            e.stopPropagation();
            props.submit();
        }
    }

    function onInput(e: React.FormEvent<HTMLInputElement>) {
        const target = e.target;

        if (isInstanceOf(HTMLInputElement)(target)) {
            witcher?.updateData({
                [target.name]: target.value
            });
        }
    }

    return (
        <form>
            <div className={'row'} style={{justifyContent: 'left'}}>
                <div className={'col-lg-5 spark-pad-b-1--lte-md'}>
                    <TextInput
                        name={'roleName'}
                        value={witcherData?.roleName ?? ''}
                        onInput={onInput}
                        status={fieldErrors?.['roleName'] ? MessageStatus.ERROR : undefined}
                        statusMessage={fieldErrors?.['roleName']}
                        label={tAsString('ROLE_NAME')}
                        className={'qa-role-name-input'}
                        maxLength={100}
                        onKeyDown={onKeydown}
                    />
                </div>
                <div className={'col-lg-7'}>
                    <TextInput
                        name={'notes'}
                        value={witcherData?.notes ?? ''}
                        onInput={onInput}
                        className={'qa-role-notes-input'}
                        label={tAsString('NOTES')}
                        optionalLabel={`(${tAsString('OPTIONAL')})`}
                        maxLength={250}
                    />
                </div>
            </div>
        </form>
    );
}