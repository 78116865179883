/* tslint:disable */
/* eslint-disable */
/**
 * SR AAT specification
 * Description
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  AuthorizationStatus,
  GetRoleResponse,
  RoleRequest,
  RoleResponse,
  SearchRoleRequest,
  SearchRoleResponse,
  Status,
  UpdateRoleRequest,
} from '../models';
import {
    AuthorizationStatusFromJSON,
    AuthorizationStatusToJSON,
    GetRoleResponseFromJSON,
    GetRoleResponseToJSON,
    RoleRequestFromJSON,
    RoleRequestToJSON,
    RoleResponseFromJSON,
    RoleResponseToJSON,
    SearchRoleRequestFromJSON,
    SearchRoleRequestToJSON,
    SearchRoleResponseFromJSON,
    SearchRoleResponseToJSON,
    StatusFromJSON,
    StatusToJSON,
    UpdateRoleRequestFromJSON,
    UpdateRoleRequestToJSON,
} from '../models';

export interface CreateRoleRequest {
    roleRequest: RoleRequest;
    authorization?: string;
    onBehalfOf?: string;
}

export interface DeleteRolesRequest {
    requestBody: Array<number>;
    authorization?: string;
    onBehalfOf?: string;
}

export interface GetRoleRequest {
    id: number;
    authorization?: string;
    onBehalfOf?: string;
}

export interface SearchRolesRequest {
    searchRoleRequest: SearchRoleRequest;
    authorization?: string;
    onBehalfOf?: string;
}

export interface UpdateRoleOperationRequest {
    id: number;
    updateRoleRequest: UpdateRoleRequest;
    authorization?: string;
    onBehalfOf?: string;
}

/**
 * 
 */
export class RolesApi extends runtime.BaseAPI {

    /**
     * Creates a role.
     */
    async createRoleRaw(requestParameters: CreateRoleRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<RoleResponse>> {
        if (requestParameters.roleRequest === null || requestParameters.roleRequest === undefined) {
            throw new runtime.RequiredError('roleRequest','Required parameter requestParameters.roleRequest was null or undefined when calling createRole.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (requestParameters.onBehalfOf !== undefined && requestParameters.onBehalfOf !== null) {
            headerParameters['On-Behalf-Of'] = String(requestParameters.onBehalfOf);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/roles`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: RoleRequestToJSON(requestParameters.roleRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RoleResponseFromJSON(jsonValue));
    }

    /**
     * Creates a role.
     */
    async createRole(requestParameters: CreateRoleRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<RoleResponse> {
        const response = await this.createRoleRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Endpoint for deleting roles
     * Delete Roles Endpoint
     */
    async deleteRolesRaw(requestParameters: DeleteRolesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<Status>> {
        if (requestParameters.requestBody === null || requestParameters.requestBody === undefined) {
            throw new runtime.RequiredError('requestBody','Required parameter requestParameters.requestBody was null or undefined when calling deleteRoles.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (requestParameters.onBehalfOf !== undefined && requestParameters.onBehalfOf !== null) {
            headerParameters['On-Behalf-Of'] = String(requestParameters.onBehalfOf);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/roles:batchDelete`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.requestBody,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => StatusFromJSON(jsonValue));
    }

    /**
     * Endpoint for deleting roles
     * Delete Roles Endpoint
     */
    async deleteRoles(requestParameters: DeleteRolesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<Status> {
        const response = await this.deleteRolesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get a role.
     */
    async getRoleRaw(requestParameters: GetRoleRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<GetRoleResponse>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling getRole.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (requestParameters.onBehalfOf !== undefined && requestParameters.onBehalfOf !== null) {
            headerParameters['On-Behalf-Of'] = String(requestParameters.onBehalfOf);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/roles/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GetRoleResponseFromJSON(jsonValue));
    }

    /**
     * Get a role.
     */
    async getRole(requestParameters: GetRoleRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<GetRoleResponse> {
        const response = await this.getRoleRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Searches for roles.
     */
    async searchRolesRaw(requestParameters: SearchRolesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<SearchRoleResponse>> {
        if (requestParameters.searchRoleRequest === null || requestParameters.searchRoleRequest === undefined) {
            throw new runtime.RequiredError('searchRoleRequest','Required parameter requestParameters.searchRoleRequest was null or undefined when calling searchRoles.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (requestParameters.onBehalfOf !== undefined && requestParameters.onBehalfOf !== null) {
            headerParameters['On-Behalf-Of'] = String(requestParameters.onBehalfOf);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/roles:search`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SearchRoleRequestToJSON(requestParameters.searchRoleRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SearchRoleResponseFromJSON(jsonValue));
    }

    /**
     * Searches for roles.
     */
    async searchRoles(requestParameters: SearchRolesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<SearchRoleResponse> {
        const response = await this.searchRolesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update a role.
     */
    async updateRoleRaw(requestParameters: UpdateRoleOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<RoleResponse>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateRole.');
        }

        if (requestParameters.updateRoleRequest === null || requestParameters.updateRoleRequest === undefined) {
            throw new runtime.RequiredError('updateRoleRequest','Required parameter requestParameters.updateRoleRequest was null or undefined when calling updateRole.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (requestParameters.authorization !== undefined && requestParameters.authorization !== null) {
            headerParameters['Authorization'] = String(requestParameters.authorization);
        }

        if (requestParameters.onBehalfOf !== undefined && requestParameters.onBehalfOf !== null) {
            headerParameters['On-Behalf-Of'] = String(requestParameters.onBehalfOf);
        }

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/roles/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateRoleRequestToJSON(requestParameters.updateRoleRequest),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RoleResponseFromJSON(jsonValue));
    }

    /**
     * Update a role.
     */
    async updateRole(requestParameters: UpdateRoleOperationRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<RoleResponse> {
        const response = await this.updateRoleRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
