import styles from './LoadingBlock.module.css';
import {useTimeout} from '../../../../../common/helpers/react/hooks/useTimeout';
import {ExpandCollapse, Message} from '@sabre/spark-react-core';
import ProgressIndicator from '@sabre/spark-react-core/progress-indicator';
import {MessageRole, MessageType, ProgressIndicatorSize} from '@sabre/spark-react-core/types';
import {KEY} from '../../../../../common/helpers/id/KEY';
import {cssClasses} from '../../../../../common/helpers/browser/cssClasses';
import {tAsString} from '../../../../../common/helpers/react/text/tAsString';

export type LoadingBlockProps = {
    height?: number;
    timeout?: number;
    label?: string;
}

export default function LoadingBlock(props: LoadingBlockProps) {
    const size = (props.height ?? 72) - (2 * 4);

    const style = {
        height: `${size}px`,
        fontSize: `${size / 2}px`,
        lineHeight: `${size}px`,
        minHeight: `144px`
    };

    const timeout = useTimeout(props.timeout);

    if (timeout) {
        const statuses = ['info', 'info', 'info', 'warning', 'error'] as const;

        const logs = console.getLogsAsObjects().map(it => {
            return <Message
                key={KEY()}
                type={MessageType.CONTAINER}
                role={MessageRole.STATUS}
                content={it.message}
                status={statuses[it.level]}
                animate={false}
            />;
        });

        return (
            <div aria-hidden={true} aria-label={tAsString('TIMEOUT')}>
                <p>{tAsString('TIMEOUT_DESCRIPTION')}</p>
                <ExpandCollapse showTrigger={`${tAsString('SHOW_DETAILS')}`}>
                    {logs}
                    <div style={{height: '1rem'}}></div>
                </ExpandCollapse>
            </div>
        );
    }

    return (
        <div className={cssClasses(styles.LoadingBlock, 'qa-loading-block')} aria-live="polite"
             role="contentinfo"
             aria-label={props.label ?? tAsString('LOADING')} style={style}>

            <ProgressIndicator
                className={cssClasses(styles.LoadingProgress, 'qa-loading-progress')}
                type={'indeterminate'}
                size={ProgressIndicatorSize.SMALL}
                progressText={`${props.label ?? tAsString('LOADING')}...`}
            />
        </div>
    );
};
