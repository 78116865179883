import {isFunction} from '../../types/guards/isFunction';

export function mockMethod<T, V extends keyof T>(obj: T, methodName: V, impl?: T[V]): void {
    if (!!Object.getOwnPropertyDescriptor(obj, methodName)?.get) {
        return;
    }

    const value = obj[methodName];

    if (!isFunction(value)) {
        return;
    }

    const implementation = isFunction(impl)
        ? impl.bind(obj)
        : value;

    const mockedMethod: any = jest.fn(implementation);
    mockedMethod[ORIGINAL_FUNCTION] = value;

    obj[methodName] = mockedMethod as T[V];
}

export const ORIGINAL_FUNCTION: unique symbol = Symbol('mockMethod().ORIGINAL_FUNCTION');
