import React, {ReactElement, useRef} from 'react';
import {Button, Panel} from '@sabre/spark-react-core';
import {cssClasses} from '../../../../../common/helpers/browser/cssClasses';
import {useRefByClassName} from '../../../../../common/helpers/react/hooks/useRefByClassName';
import {renderMany} from '../../../../../common/helpers/react/renderMany';
import {ButtonConfig} from '../../../../../controllers/dialog/ButtonConfig';
import styles from './ActionPanel.module.css';
import {tAsString} from '../../../../../common/helpers/react/text/tAsString';

export type ActionPanelProps = {
    buttons?: ButtonConfig[],
    element?: JSX.Element,
    className?: string,
    position?: 'top' | 'bottom'
};

export default function ActionPanel(props: ActionPanelProps): ReactElement {
    const placeholderRef = useRef<HTMLDivElement>(null);
    const panelRef = useRefByClassName<HTMLElement>();

    panelRef.useRef(el => {
        if (placeholderRef.current) {
            placeholderRef.current.style.height = `${el.offsetHeight}px`;
        }
    });

    const ariaLabel = `${tAsString('ACTION_BUTTONS')}: ${props.buttons?.map(it => it.children?.toString()).join(', ')}`;

    const buttonsDiv = props.buttons?.length
        ? <div className={'col-md-7'}>
            <nav aria-label={ariaLabel} className={'spark-btn-group'}>
                {renderMany(Button, props.buttons, {
                    children: undefined,
                    className: cssClasses(
                        'spark-btn-group-secondary spark-btn--block-sm',
                        styles.Button
                    )
                })}
            </nav>
        </div>
        : <></>;

    return (<>
        <div ref={placeholderRef}/>
        <Panel
            className={cssClasses(
                panelRef.className,
                props.position === 'top'
                    ? 'spark-panel--fixed is-fixed-top'
                    : 'spark-panel--fixed is-fixed-bottom',
                styles.Panel,
                props.className
            )}
        >
            <div className={'row'}>
                <div className={props.buttons?.length ? 'col-md-5' : 'col-md-12'}>
                    {props.element}
                </div>
                {buttonsDiv}
            </div>
        </Panel>
    </>);
}
