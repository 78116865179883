import {asArray} from '../converters/asArray';
import {getDbId} from '../db/getDbId';
import {ID} from './ID';

/**
 * Gets string key for React components and/or HTML IDs.
 *
 * @param o Value to be associated with key.
 * @returns React component key
 */
export const KEY = (o?: unknown) => {
    return `K${asArray(o).map(getDbId).join('_') || ID(o)}`;
};
