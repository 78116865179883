import DefaultTable, {DefaultTableProps} from './DefaultTable';
import styles from './DefaultListTable.module.css';
import {cssClasses} from '../../../../../common/helpers/browser/cssClasses';

export default function DefaultListTable<T extends NonNullable<unknown>>(props: DefaultTableProps<T>) {
    return <DefaultTable
        {...props}
        className={cssClasses(
            props.className,
            styles.Table
        )}
    />;
}
