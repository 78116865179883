import {AnyFn} from '../../types/common/functions/AnyFn';
import {THROTTLING_INTERVAL} from '../../CONST';
import {cached, CachingOptions} from './cached';
import {isNumber} from '../../types/guards/isNumber';

export function throttled<T extends AnyFn>(
    fn: T,
    intervalOrOptions: number | CachingOptions = THROTTLING_INTERVAL
): typeof fn {
    if (intervalOrOptions === 0) {
        return fn;
    }

    const isIntervalPassed = isNumber(intervalOrOptions);

    return cached(fn, {
        timeout: isIntervalPassed ? intervalOrOptions : THROTTLING_INTERVAL,
        ...(isIntervalPassed ? {} : intervalOrOptions)
    });
}
