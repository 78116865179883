import {JsonObject} from 'type-fest';
import {asJson} from './asJson';
import {isPlainObject} from '../../types/guards/isPlainObject';

export function asJsonObjectOrNull(o?: unknown): JsonObject | null {
    const json = asJson(o);

    if (isPlainObject(json)) {
        return json;
    }

    return null;
}
