import {DefaultWizardStepDescription} from './DefaultWizardStepDescription';

export function getCurrentStep<T extends Omit<DefaultWizardStepDescription, 'element'> = DefaultWizardStepDescription>(steps: T[], desiredStepName: string): T | null {
    desiredStepName = desiredStepName.toLowerCase();

    const desiredStep = steps.filter(it => it.name.toLowerCase() === desiredStepName)[0];
    const firstStep = steps[0];

    return desiredStep ?? firstStep ?? null;
}
