import {
    DefaultWizardHelpers
} from '../../../../../../common/components/wizards/DefaultWizardHelpers';
import {RxGroupProperty} from '../../../../../../../../common/model/db/types/RxGroupProperty';
import {ensure} from '../../../../../../../../common/types/guards/ensure';
import {isRecordOf} from '../../../../../../../../common/types/guards/isRecordOf';
import {
    isPropertyState
} from '../../../../../../../../common/model/types/properties/isPropertyState';

export function getOverrideValue(row: Pick<RxGroupProperty, 'property_ref' | 'override_value'>, wh: DefaultWizardHelpers): string | undefined {
    const properties = wh.stepData?.properties ?? {};

    ensure(isRecordOf(isPropertyState), properties);
    const stepValue = properties[row.property_ref?.id ?? NaN];

    return isPropertyState(stepValue)
        ? stepValue.overrideValue
        : row.override_value;
}
