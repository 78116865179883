import i18next from 'i18next';
import {initReactI18next} from 'react-i18next';

function addLanguage(lang: string) {
    import((`./translations/${lang}.json`))
        .then(json => {
            i18next.addResourceBundle(lang, 'translation', json, true, true);
        });
}

export async function i18n() {
    await i18next
        .use(initReactI18next)
        .init({
            fallbackLng: 'en',
            interpolation: {
                escapeValue: false // not needed for react as it escapes by default
            }
        });

    addLanguage('en');
    addLanguage('pl');
}
