import {useEffect, useRef, useState} from 'react';
import {MessageProps} from '@sabre/spark-react-core/message/message.types';
import {Button, Message} from '@sabre/spark-react-core';
import styles from './NotificationMessage.module.css';
import {cssClasses} from '../../../../common/helpers/browser/cssClasses';
import {useControllers} from '../../../../common/helpers/react/hooks/useControllers';
import {useI18Next} from '../../../../common/helpers/react/hooks/useI18Next';

export type NotificationMessageProps = Omit<MessageProps, 'ariaLabelledBy' | 'ariaLabel'> & {
    id: string,
    zIndex?: number
}

type NotificationMessageStatus = 'unmounted' | 'starting' | 'started' | 'stopping' | 'dismissed';
const notificationMessageOpacity: { [status in NotificationMessageStatus]: number } = {
    starting: 1,
    stopping: 1,
    unmounted: 1,
    started: 0,
    dismissed: 0
}
const closeNotificationDelay = 7000;
const reduceNotificationTransparencyDelay = 5000;
const dismissNotificationDelay = 500;

const notificationOpacityTransitionDuration: { [status in NotificationMessageStatus]: number } = {
    starting: 0,
    stopping: 0,
    unmounted: 0,
    started: closeNotificationDelay - reduceNotificationTransparencyDelay,
    dismissed: dismissNotificationDelay
}

export default function NotificationMessage(props: NotificationMessageProps) {
    const [status, setStatus] = useState<NotificationMessageStatus>('unmounted');
    const [timeouts, setTimeouts] = useState<[number, number]>();
    const [positionInDom, setPositionInDom] = useState<number>(-1);
    const [animate, setAnimate] = useState<boolean>(false);
    const {t} = useI18Next();
    const dialogController = useControllers().dialog;
    const numberOfNotifications = dialogController.getToastNotifications$().asState()?.length ?? 0;

    const ref = useRef<HTMLDivElement>(null);

    useEffect(() => {
        setStatus('starting');
    }, []);

    useEffect(() => {
        if (props.status !== 'error')
        {
            if (status === 'starting')
            {
                setTimeouts([
                    window.setTimeout(() => {
                        setStatus('started');
                    }, reduceNotificationTransparencyDelay),
                    window.setTimeout(() => {
                        dialogController.closeNotification(props.id);
                    }, closeNotificationDelay)
                ]);
            } else if (status === 'stopping')
            {
                timeouts?.forEach(clearTimeout);
            }
        }
        if (status === 'dismissed')
        {
            timeouts?.forEach(clearTimeout);
            setTimeout(() => {
                dialogController.closeNotification(props.id);
            }, dismissNotificationDelay);
        }
    }, [status]);

    useEffect(() => {
        const notificationMessages = document.querySelectorAll('.qa-notification-message');
        const newPosition = Array.from(notificationMessages).findIndex(it => it === ref.current);
        setAnimate(newPosition < positionInDom);
        setPositionInDom(newPosition)
    }, [numberOfNotifications]);

    return <div
        ref={ref}
        className={cssClasses(
            styles.Envelope,
            styles[`Envelope-${props.status}`],
            `qa-notification-message`,
            `notification-message-${props.id}`,
            animate ? 'notification-animate' : ''
        )}
        style={{
            zIndex: props.zIndex ?? 99999,
            transition: `opacity ${notificationOpacityTransitionDuration[status]}ms ease-in-out`,
            opacity: notificationMessageOpacity[status]
        }}
        onMouseEnter={() => status !== 'dismissed' && setStatus('stopping')}
        onMouseLeave={() => status !== 'dismissed' && setStatus('starting')}
    >
        <Message
            ariaLabel={`${props.title ?? ''} ${props.content ?? ''}`.trim()}
            {...props}
            className={cssClasses(
                styles.Message,
                props.className,
                props.title ? undefined : styles.MessageWithNoTitle
            )}
        />
        <Button
            className={cssClasses(
                styles.Button,
                'spark-message__heading',
                'aat-autokey-escape-first',
                props.animate ? styles.ButtonNew : null,
                props.title ? undefined : styles.ButtonWithNoTitle
            )}
            textOnly={true}
            onClick={() => setStatus('dismissed')}
        >{t('DISMISS')}</Button>
    </div>;
}
