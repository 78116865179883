import {ColumnDescription} from './ColumnDescription';
import {asString} from '../../../../../common/helpers/converters/asString';
import {IMG_1x1_URL} from '../../../../../common/CONST';
import {TableHeaderCell} from '@sabre/spark-react-core';
import React from 'react';
import {getColumnClassName} from './helpers/getColumnClassName';
import {getColumnStyle} from './helpers/getColumnStyle';

export type DefaultTableHeaderCellProps<T> = {
    column: ColumnDescription<T>
}

export default function DefaultTableHeaderCell<T>(props: DefaultTableHeaderCellProps<T>) {
    const c = props.column;

    const ariaLabel = c.ariaLabel
        ?? c.header
        ?? asString(c.name);

    const header = c.renderHeader?.(c)
        ?? c.header
        ?? <img
            aria-hidden={true}
            src={IMG_1x1_URL}
            alt={ariaLabel}
        />;

    const headerCellProps = c.headerCellProps?.(c);

    const newHeaderCellProps = {
        ...headerCellProps,
        className: getColumnClassName(
            c,
            headerCellProps?.className
        ),
        style: getColumnStyle(
            c,
            {
                verticalAlign: 'middle',
                ...headerCellProps?.style
            }
        )
    };

    return <TableHeaderCell
        aria-label={ariaLabel !== header ? ariaLabel : undefined}
        {...newHeaderCellProps}
    >{header}</TableHeaderCell>;
}
