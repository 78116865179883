import React, {ReactElement} from 'react';
import styles from './Splash.module.css';
import {Button, Message} from '@sabre/spark-react-core';
import {ButtonSize, MessageRole, MessageStatus, MessageType} from '@sabre/spark-react-core/types';
import {ANIMATE} from '../../../../common/CONST';
import {cssClasses} from '../../../../common/helpers/browser/cssClasses';

export type SplashProps = {
    header?: string;
    title?: string;
    content?: string;
    buttonLabel?: string;
    status?: MessageStatus;
    action?: () => void;
};

export default function Splash(props: SplashProps): ReactElement {
    const message = props.title || props.content
        ? <Message
            title={props.title}
            content={props.content || ''}
            status={props.status || MessageStatus.INFO}
            type={MessageType.CONTAINER}
            role={MessageRole.ALERT}
            className={cssClasses(styles.Message, 'qa-message')}
            animate={ANIMATE}
        />
        : <></>;

    const button = props.buttonLabel && props.action
        ? <Button
            size={ButtonSize.MEDIUM}
            onClick={props.action}
            className={'qa-button'}
        >{props.buttonLabel}</Button>
        : <></>;

    return (
        <div className={styles.Splash}>
            <article className="spark-splash-screen spark-splash-screen--panel">
                <section className="spark-splash-screen__content">
                    <div
                        className={styles.Panel + ' spark-panel spark-splash-screen__center col-xs-12 col-md-8 col-lg-6'}>
                        <div className="spark-panel__content">
                            <i aria-hidden={true}
                               className="spark-logo spark-logo--sabre spark-logo--md spark-logo--sabre-red">Sabre</i>
                            <h3 aria-hidden={true}
                                className={cssClasses('spark-splash-screen__heading', 'qa-header')}>{props.header || `Agency Admin Tools`}</h3>
                            {message}
                            {button}
                        </div>
                    </div>
                </section>
            </article>
        </div>
    );
}
