import {identity, OperatorFunction, throttleTime} from 'rxjs';
import {THROTTLING_INTERVAL_FOR_DATA} from '../../CONST';

export function throttleLikeABoss<T>(interval: number = THROTTLING_INTERVAL_FOR_DATA): OperatorFunction<T, T> {
    if (interval === 0) {
        return identity;
    }

    return throttleTime<T>(interval, undefined, {
        leading: true,
        trailing: true
    });
}
