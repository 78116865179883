import {Icon} from '@sabre/spark-react-core';
import React from 'react';
import {useControllers} from '../../../../../common/helpers/react/hooks/useControllers';
import DefaultTable, {
    DefaultTableCheckboxModeProps,
    DefaultTableProps
} from '../../../common/components/table/DefaultTable';
import {ColumnDescription} from '../../../common/components/table/ColumnDescription';
import {ParentRole, PartialRole} from '../../../../../core/rest-client/aat';
import {tAsString} from '../../../../../common/helpers/react/text/tAsString';
import {asNumber} from '../../../../../common/helpers/converters/asNumber';
import DefaultToggle, {DefaultToggleProps} from "../../../common/components/toggle/DefaultToggle";
import styles from "./RolesTable.module.css";
import {asBoolean} from "../../../../../common/helpers/converters/asBoolean";
import {asString} from "../../../../../common/helpers/converters/asString";

export type RolesTableProps = Partial<DefaultTableCheckboxModeProps> & {
    addAggregatedRolesMode?: boolean;
    removeRolesMode?: boolean;
    mainTableView?: boolean;
    editRolesMode?: boolean;
    setMainTableView?: (value: boolean) => void;
}

export default function RolesTable (props: RolesTableProps) {
    const roles = useControllers().roles;

    const getData = (): ParentRole[] | undefined => {
        const rolesToAggregate = roles.getSearchedRolesToAggregate$().asState();
        const visibleRoles = roles.getVisibleRoles$().asState();

        if (props.addAggregatedRolesMode) {
            const nestedRoles = roles.getNestedRoles$().asState() || [];
            const role = nestedRoles[nestedRoles.length - 1];

            return (rolesToAggregate || [])
                .filter(roleToAggregate => !(visibleRoles || []).find(parentRole => parentRole.id === roleToAggregate.id))
                .filter(roleToAggregate => !(roleToAggregate.id === role?.id));
        } else {
            return visibleRoles;
        }
    };

    const data = getData();

    const isBusy = roles.isBusy$().asState() !== false && !props.removeRolesMode && !props.editRolesMode;

    const optionalColumns: ColumnDescription<ParentRole>[] = !props.mainTableView
        ? [{
                name: 'enabled',
                header: 'On / Off',
                size: 4,
                render: row => {
                    if (props.addAggregatedRolesMode || props.editRolesMode) {
                        const onChange: DefaultToggleProps['onChange'] = value => {
                            roles.updateParentRoleInDb({
                                id: row.id,
                                name: row.name,
                                enabled: asBoolean(value)
                            }).andWeAreDone();
                        };

                        const name = `role::${row.id}`;

                        return (
                            <>
                                <DefaultToggle
                                    className={styles.Toggle}
                                    onChange={onChange}
                                    name={name}
                                    initialValue={props.editRolesMode
                                                    ? asString(row.enabled)
                                                    : 'true'}
                                    ariaLabel={tAsString('ASSIGNMENT_SWITCH_OF_X', {
                                        name: row.name
                                    })}
                                    options={[
                                        {
                                            value: 'true',
                                            label: 'On',
                                            color: 'green'
                                        },
                                        {
                                            value: 'false',
                                            label: 'Off',
                                            color: 'red'
                                        }
                                    ]}
                                />
                            </>
                        );
                    } else {
                        return (<>{row.enabled ? 'On' : 'Off'}</>);
                    }
                }
            }]
        : [];

    const columns: ColumnDescription<ParentRole>[] = [
        {
            name: 'name',
            header: tAsString('ROLE_NAME'),
            size: 4
        },
        {
            name: 'id',
            header: tAsString('ROLE_ID'),
            size: 4
        },
        ...optionalColumns,
        {
            size: 1,
            align: 'right',
            ariaLabel: tAsString('ROLE_LINK'),
            render: (it: PartialRole) => <a
                aria-label={tAsString('SHOW_X_ROW_DETAILS', {
                    name: it.name
                })}
                href={document.location.hash}
            >
                <Icon name={'arrow-chevron-right'}/>
            </a>,
            hideInCheckboxMode: true
        }
    ];

    const onClick = async (id: string) => {
        if (!isBusy) {
            if (props.setMainTableView)
            {
                props.setMainTableView(false);
            }
            await roles.showParentRolesForGivenRole(asNumber(id), true);
        }
    }

    const getAdditionalTableProps = (): DefaultTableProps<ParentRole> => {
        if (props.addAggregatedRolesMode || props.removeRolesMode)
        {
            return {
                checkedRows: props.checkedRows,
                setCheckedRows: props.setCheckedRows
            };
        } else if (!props.editRolesMode)
        {
            return {
                onClick
            };
        } else {
            return {};
        }
    }

    return <DefaultTable
        columns={columns}
        data={data}
        isBusy={isBusy}
        {...getAdditionalTableProps()}
    />;
}