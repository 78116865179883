import {useHashWizard} from '../../../../../common/components/wizards/useHashWizard';
import {default as React} from 'react';
import GroupWizardStepTemplate from '../GroupWizardStepTemplate';
import GroupWizardDetailsStepFormPresenter from './GroupWizardDetailsStepFormPresenter';
import {useControllers} from "../../../../../../../common/helpers/react/hooks/useControllers";
import {asNumber} from "../../../../../../../common/helpers/converters/asNumber";
import {useParams} from "react-router-dom";

export default function GroupWizardDetailsStepPresenter() {
    const {id} = useParams();

    const wh = useHashWizard();

    const groups = useControllers().groups;
    const groupId = asNumber(id);
    const group = groups.getGroupById$(groupId).asState() ?? undefined;

    return (
        <GroupWizardStepTemplate wizardHelpers={wh} group={group}>
            <GroupWizardDetailsStepFormPresenter wizardHelpers={wh}/>
        </GroupWizardStepTemplate>
    );
}
