import {getSignature} from './getSignature';

export function compareSignatures(a: any, b: any): boolean {
    if (a === b) {
        return true;
    } else if (typeof a !== typeof b) {
        return false;
    } else if (a === null && b !== null) {
        return false;
    } else if (a !== null && b === null) {
        return false;
    } else if (a === undefined && b !== undefined) {
        return false;
    } else if (a !== undefined && b === undefined) {
        return false;
    } else {
        return getSignature(a) === getSignature(b);
    }
}
