import {ColumnDescription} from '../ColumnDescription';
import {CSSProperties} from 'react';

export function getColumnStyle<T>(c: ColumnDescription<T>, additionalStyles?: CSSProperties): CSSProperties {
    return {
        textAlign: c.align ?? 'inherit',
        maxWidth: c.size ? `${8 * c.size}vw` : `inherit`,
        minWidth: c.size ? `${6 * c.size}vw` : `inherit`,
        width: 'unset',
        verticalAlign: 'top',
        paddingTop: 12,
        paddingBottom: 12,
        height: 'unset',
        wordBreak: 'break-word',
        ...additionalStyles
    };
}
