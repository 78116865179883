import {
    isStatus,
    ResponseError,
    Status,
    StatusCodes,
    StatusFromJSONTyped
} from '../../../core/rest-client/aat';

export async function getStatusResponse(e: unknown): Promise<Status> {
    let json;

    try {
        if (e instanceof ResponseError) {
            json = await e.response.json();
        } else if (e instanceof Response) {
            json = await e.json();
        } else if (e instanceof Error) {
            json = {
                status: StatusCodes.Error,
                statusDescription: e.message
            };
        } else {
            throw new Error('Object does not contain Status JSON');
        }
    } catch (e) {
        console.warn(e);
    } finally {
        json ??= {
            status: StatusCodes.Error,
            statusDescription: 'Cannot get Status JSON from given object'
        };
    }

    if (!isStatus(json)) {
        throw new Error('Not a Status response');
    }

    return StatusFromJSONTyped(json, false);
}
