/* tslint:disable */
/* eslint-disable */
/**
 * SR AAT specification
 * Description
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { GroupParticipantType } from './GroupParticipantType';
import {
    GroupParticipantTypeFromJSON,
    GroupParticipantTypeFromJSONTyped,
    GroupParticipantTypeToJSON,
} from './GroupParticipantType';

/**
 * 
 * @export
 * @interface GroupParticipantsSearchCriteria
 */
export interface GroupParticipantsSearchCriteria {
    /**
     * 
     * @type {string}
     * @memberof GroupParticipantsSearchCriteria
     */
    pattern?: string;
    /**
     * 
     * @type {GroupParticipantType}
     * @memberof GroupParticipantsSearchCriteria
     */
    type?: GroupParticipantType;
}

/**
 * Check if a given object implements the GroupParticipantsSearchCriteria interface.
 */
export function instanceOfGroupParticipantsSearchCriteria(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function GroupParticipantsSearchCriteriaFromJSON(json: any): GroupParticipantsSearchCriteria {
    return GroupParticipantsSearchCriteriaFromJSONTyped(json, false);
}

export function GroupParticipantsSearchCriteriaFromJSONTyped(json: any, ignoreDiscriminator: boolean): GroupParticipantsSearchCriteria {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'pattern': !exists(json, 'pattern') ? undefined : json['pattern'],
        'type': !exists(json, 'type') ? undefined : GroupParticipantTypeFromJSON(json['type']),
    };
}

export function GroupParticipantsSearchCriteriaToJSON(value?: GroupParticipantsSearchCriteria | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'pattern': value.pattern,
        'type': GroupParticipantTypeToJSON(value.type),
    };
}

