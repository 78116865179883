/*** Autogenerated. Use 'npm run generate-sources' to regenerate. ***/
import {RxJsonObject} from '../../../types/json/RxJsonObject';
import {JSONSchema4} from 'json-schema';
import {generateConverterToRx} from '../../../helpers/db/converters/generateConverterToRx';
import {generateArrayConverter} from '../../../helpers/db/converters/generateArrayConverter';
import {isDescribedByJsonSchema} from '../../../types/guards/isDescribedByJsonSchema';
import {isPartiallyDescribedByJsonSchema} from '../../../types/guards/isPartiallyDescribedByJsonSchema';
/**
 * Collection `redApps` contains documents of `RxRedapp` type.
 */
export interface RxRedapp {
  name: string;
  /**
   * Primary key.
   */
  iu_name: string;
}
export const RxRedappSchema: JSONSchema4 = {
    title: 'RxRedapp',
    primaryKey: 'iu_name',
    required: [
        'name',
        'iu_name',
    ],
    version: 0,
    type: 'object',
    properties: {
        name: {
            type: 'string',
            description: '',
        },
        iu_name: {
            maxLength: 10000,
            type: 'string',
            description: 'Primary key.',
        },
    },
    additionalProperties: false,
    description: 'Collection `redApps` contains documents of `RxRedapp` type.',
}
export const asRxRedapp = generateConverterToRx<RxRedapp>(RxRedappSchema, 'RxRedappSchema');
export const asRxRedappArray = generateArrayConverter<RxRedapp>(asRxRedapp);
export function instanceOfRxRedapp(o?: any): o is RxRedapp { 
    return isDescribedByJsonSchema(o, RxRedappSchema, false, false); 
}
export function isRxRedapp(o?: any): o is RxRedapp { 
    return isDescribedByJsonSchema(o, RxRedappSchema, false, false); 
}
export function instanceOfPartialRxRedapp(o?: any): o is Partial<RxRedapp> { 
    return isPartiallyDescribedByJsonSchema(o, RxRedappSchema);
}
export function isPartialRxRedapp(o?: any): o is Partial<RxRedapp> { 
    return isPartiallyDescribedByJsonSchema(o, RxRedappSchema); 
}