import Comparators from 'comparators';
import {ObjectEntry} from 'type-fest/source/entry';
import {asString} from '../converters/asString';

const MAX_LEVEL = 5;

const cmp = Comparators.comparing<ObjectEntry<any>>(it => it[0]);
const filter = (it: any) => it?.[1] !== undefined;

export function getSignatureObject(arg: any, visited: any[] = [], level: number = 0): any {
    if (level >= MAX_LEVEL) {
        try {
            return JSON.stringify(JSON.decycle(arg));
        } catch (e) {
            return asString(e);
        }
    }

    let result;
    let typeOfArg: string = typeof arg;

    if (arg instanceof RegExp) {
        typeOfArg = 'regexp';
        result = arg.toString();
    } else if (typeOfArg === 'object' && arg !== null) {
        visited.push(arg);

        if (Array.isArray(arg)) {
            typeOfArg = 'array';
            result = arg.map((it) => getSignatureObject(it, visited, level + 1));
        } else {
            result = getSignatureObject(
                Object.entries(arg).filter(filter).sort(cmp),
                visited,
                level + 1
            );
        }
    } else if (typeOfArg === 'function') {
        result = [arg?.name, arg?.length];
    } else if (typeOfArg === 'symbol') {
        result = [arg?.description];
    } else if (typeOfArg === 'bigint') {
        result = String(arg);
    } else if (typeOfArg === 'number') {
        return arg;
    } else if (typeOfArg === 'string') {
        return arg;
    } else {
        return arg ? 1 : 0;
    }

    return {[typeOfArg[0]!]: result};
}
