import {from, Observable} from 'rxjs';
import {Controller} from '../Controller';
import {DataSource} from '../../common/decorators/rxdb/DataSource';
import {tAsString} from '../../common/helpers/react/text/tAsString';
import {Bound} from '../../common/decorators/methods/Bound';
import {Throttled} from '../../common/decorators/methods/Throttled';
import {RxConfiguration} from '../../common/model/db/types/RxConfiguration';
import {DataSourceKind} from '../../common/decorators/rxdb/DataSourceKind';
import {asString} from '../../common/helpers/converters/asString';

export class ConfigurationsController extends Controller {

    @Bound()
    @Throttled()
    async refreshConfigurations(): Promise<void> {
        await this.getBusyFlag().run(() => this.services.configurations.getConfigurations(), tAsString('REFRESHING_CONFIGURATIONS'));
    };

    async createConfiguration(redappName: string, configurationName: string, value: string, description: string): Promise<void> {
        await this.getBusyFlag().run(async () => this.services.configurations.createConfiguration(redappName, configurationName, value, description), tAsString('CREATING_CONFIGURATION'));
    };

    async updateConfiguration(configurationId: number, configurationName: string, value: string, description: string): Promise<void> {
        await this.getBusyFlag().run(async () => this.services.configurations.updateConfiguration(configurationId, configurationName, value, description), tAsString('UPDATING_CONFIGURATION'));
    }

    @DataSource()
    getConfigurations$(): Observable<RxConfiguration[]> {
        return this.db.configurations.active$;
    };

    @DataSource(DataSourceKind.PARAMETRIZED)
    getConfigurationById$(configurationId: number): Observable<RxConfiguration | null> {
        if (isNaN(configurationId))
        {
            return from([null]);
        }

        return this.db.configurations.getById$(asString(configurationId));
    };

    async deleteConfigurationsByIds(listIds: number[]): Promise<void> {
        try {
            await this.getBusyFlag().run(async () => {
                await this.services.configurations.deleteConfigurationsByIds(listIds);
            }, tAsString('DELETING_CONFIGURATIONS'));
        } finally {
            this.refreshConfigurations().andWeAreDone();
        }
    }
}