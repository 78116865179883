import {asString} from './asString';
import {asInt} from './asInt';
import {asFloat} from './asFloat';
import {asNumber} from './asNumber';
import {asBoolean} from './asBoolean';
import {asJson} from './asJson';
import {TypeNames} from './TypeNames';
import {asArray} from './asArray';
import {asObject} from './asObject';

type TypeNamesMap = Partial<Record<TypeNames, any>>;

/**
 * Converts given value to given type.
 *
 * @param typeName Given type name
 * @param o Object to be converted.
 * @param defaultValue Default value used in conversion
 */
export function as(
    typeName: TypeNames,
    o?: unknown,
    defaultValue?: unknown
): any {
    if (o === undefined && defaultValue !== undefined) {
        return as(typeName, defaultValue);
    }

    switch (typeName) {
        case 'string':
            return asString(o);
        case 'int':
        case 'integer':
            return asInt(o);
        case 'float':
            return asFloat(o);
        case 'number':
            return asNumber(o);
        case 'boolean':
            return asBoolean(o);
        case 'json':
            return asJson(o);
        case 'object':
            return asObject(o);
        case 'array':
            return asArray(o);
        case 'undefined':
            return undefined;
        case 'unknown':
        default:
            return o;
    }
}
