import {useControllers} from '../../../../common/helpers/react/hooks/useControllers';
import DefaultHeader from './DefaultHeader';
import {useOnce} from '../../../../common/helpers/react/hooks/useOnce';
import {RxMenuItem} from '../../../../common/model/db/types/RxMenuItem';
import RightMenuPresenter from './RightMenuPresenter';

export default function HeaderPresenter() {
    const controllers = useControllers();

    const menuItems = controllers.layout.getMenuItems$().asState();
    const title = controllers.settings.get$('title').asState();
    const defaultPage = controllers.settings.get$('default_page').asState();
    const currentPage = controllers.settings.get$('current_page').asState();

    const clickHandler = useOnce(() => (it: RxMenuItem) => {
        controllers.http.navigateTo(it.link_ref?.url);
    });

    return (
        <DefaultHeader
            title={title}
            titleLink={defaultPage ? `#/${defaultPage}` : ''}
            menuItems={menuItems}
            activeHash={currentPage}
            onClick={clickHandler}
            userBlock={useOnce(() => <RightMenuPresenter/>)}
        />
    );
}
