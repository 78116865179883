import {Controller} from '../../controllers/Controller';
import {Status} from '../rest-client/aat';
import {ServerError} from '../../common/errors/ServerError';

export class Service extends Controller {
    protected checkStatus(response: Status): void {
        if (response.status && response.status !== 'SUCCESS') {
            throw new ServerError(response);
        }
    }
}

export interface FriendlyService {
    services: Controller['services'];
    controllers: Controller['controllers'];
    db: Controller['db'];

    checkStatus(response: Status): void;
}
