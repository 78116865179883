import {Message} from '@sabre/spark-react-core';
import {MessageRole, MessageStatus, MessageType} from '@sabre/spark-react-core/types';
import {ANIMATE} from '../../../../../../common/CONST';
import styles from './WildTableRow.module.css';

export type WildTableRowProps = {
    title?: string;
    content?: string;
    className?: string
    colspan?: number;
};

export default function WildTableRow(props: WildTableRowProps) {
    return (
        <tr>
            <td colSpan={props.colspan ?? 1000} className={props.className}>
                <Message
                    type={MessageType.CONTAINER}
                    role={MessageRole.ALERT}
                    status={MessageStatus.INFO}
                    title={props.title}
                    content={props.content || ''}
                    animate={ANIMATE}
                    className={styles.Message}
                />
            </td>
        </tr>
    );
}
