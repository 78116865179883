import {Icon} from '@sabre/spark-react-core';
import React from 'react';
import styles from './Separator.module.css';
import {SeparatorProps} from './SeparatorProps';

export default function VerticalSeparator(props: SeparatorProps) {
    return (
        <span className={styles.VerticalSeparator}>
            <span className={props.className}>
                <Icon name={'math-subtract'}/>
            </span>
        </span>
    );
}
