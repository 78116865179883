import {Observable} from 'rxjs';
import {Controller} from '../Controller';
import {DataSource} from '../../common/decorators/rxdb/DataSource';
import {tAsString} from '../../common/helpers/react/text/tAsString';
import {Bound} from '../../common/decorators/methods/Bound';
import {Throttled} from '../../common/decorators/methods/Throttled';
import {RxRedapp} from '../../common/model/db/types/RxRedapp';

export class RedAppsController extends Controller {

    @Bound()
    @Throttled()
    async refreshRedApps(): Promise<void> {
        await this.getBusyFlag().run(() => this.services.redApps.getAllRedApps(), tAsString('REFRESHING_REDAPPS'));
    };

    @DataSource()
    getRedApps$(): Observable<RxRedapp[]> {
        return this.db.redApps.active$;
    };
}