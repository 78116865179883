import React, {useState} from 'react';
import {Button, Icon, SelectInput, TextInput} from '@sabre/spark-react-core';
import {ButtonSize, MessageStatus} from '@sabre/spark-react-core/types';
import {cssClasses} from '../../../../../common/helpers/browser/cssClasses';
import {tAsString} from '../../../../../common/helpers/react/text/tAsString';
import {SearchType} from '../../../../../common/model/types/assignees/SearchType';
import styles from './RolesToolbar.module.css';
import {asString} from '../../../../../common/helpers/converters/asString';
import Toolbar from '../../../common/components/toolbar/Toolbar';
import {useControllers} from '../../../../../common/helpers/react/hooks/useControllers';
import {printBreadcrumb} from '../../../common/components/breadcrumb/printBreadcrumb';
import {ButtonConfig} from '../../../../../controllers/dialog/ButtonConfig';
import {ModalButtons} from '../../../../../controllers/dialog/ModalButtons';
import {AddAggregatedRoleModal} from './AddAggregatedRoleModal';
import {asArray} from '../../../../../common/helpers/converters/asArray';
import {PartialParentRole, Role} from '../../../../../core/rest-client/aat';
import {DefaultTableCheckboxModeProps} from '../../../common/components/table/DefaultTable';
import {ensure} from '../../../../../common/types/guards/ensure';
import {isNumber} from '../../../../../common/types/guards/isNumber';

export type RolesToolbarProps = Partial<DefaultTableCheckboxModeProps> & {
    addAggregatedRolesMode?: boolean;
    removeRolesMode?: boolean;
    setRemoveRolesMode?: (value: boolean) => void;
    mainTableView?: boolean;
    setMainTableView?: (value: boolean) => void;
    editRolesMode?: boolean;
    setEditRolesMode?: (value: boolean) => void;
}

export const RolesToolbar = (props: RolesToolbarProps) => {
    const [type, setType] = useState(asString(SearchType.ROLE_NAME));
    const [searchValue, setSearchValue] = useState('');

    const controllers = useControllers();
    const roles = controllers.roles;
    const rolesWitcher = controllers.rolesWitcher;
    const http = controllers.http;
    const dialogController = controllers.dialog;
    const data = roles.getVisibleRoles$().asState() ?? [];
    const isBusy = roles.isBusy$().asState() !== false;

    const closeButton = searchValue.length > 0
        ? <Icon
            name={'close'}
            iconStyle={'line'}
            className={cssClasses(
                styles.CloseButton,
                'qa-select-close-button')}
            role={'button'}
            onClick={() => setSearchValue('')}
            aria-label={tAsString('CLEAR_TEXT_FIELD')}
            aria-hidden={false}
        />
        : <></>;

    const executeSearch = () => roles.searchRoles({
        type: type,
        value: searchValue
    }, !!props.addAggregatedRolesMode);

    const showLastSearchedRoles = () => {
        if (props.setRemoveRolesMode) {
            props.setRemoveRolesMode(false);
        }
        if (props.setMainTableView) {
            props.setMainTableView(true);
        }
        roles.showLastSearchedRoles();
    };

    const showParentRolesForGivenRole = (id: number) => {
        if (props.setRemoveRolesMode) {
            props.setRemoveRolesMode(false);
        }
        roles.showParentRolesForGivenRole(id);
    };

    const nestedRoles = roles.getNestedRoles$().asState() || [];
    let lastRole: Role | undefined;
    let currentParentRoles: PartialParentRole[] = [];
    let breadcrumbs: JSX.Element[] = [];
    if (nestedRoles.length > 0 && !props.addAggregatedRolesMode) {
        if (props.setMainTableView) {
            props.setMainTableView(false);
        }
        lastRole = nestedRoles[nestedRoles.length - 1];
        currentParentRoles = lastRole?.parentRoles.map(role => ({
            id: role.id,
            enabled: role.enabled
        }) as PartialParentRole) ?? [];
        breadcrumbs = [
            printBreadcrumb(() => showLastSearchedRoles(), {
                label: tAsString('ROOT')
            }, 0)
        ].concat(nestedRoles.map((it, index) => {
                ensure(isNumber, it.id);

                return printBreadcrumb(() => showParentRolesForGivenRole(it.id!), {
                    label: it.name
                }, it.id!, index === nestedRoles.length - 1);
            }
        ));
    }

    const onAdd = () => {
        roles.clearSearchedRolesToAggregate();
        dialogController.showModal({
            maximized: true,
            title: tAsString('ADD_ROLES'),
            children: <AddAggregatedRoleModal/>,
            buttons: [
                ModalButtons.CANCEL(dialogController), {
                    children: tAsString('ADD'),
                    ariaLabel: tAsString('ADD_ROLES'),
                    className: 'qa-add-button',
                    onClick: async () => {
                        const modal = await dialogController.getCurrentModal$() ?? {};
                        const newToAdd = asArray(modal?.data?.add).map(id => ({
                            id: id,
                            enabled: true
                        }) as PartialParentRole);
                        if (newToAdd.length && lastRole) {
                            newToAdd.map(async (role) => {
                                const roleFromDb = await roles.getParentRoleFromDb(role.id!);
                                role.enabled = roleFromDb
                                    ? roleFromDb?.enabled
                                    : true;
                            });
                            await dialogController.takeNotifiedAction({
                                action: async () => {
                                    const parentRoles: PartialParentRole[] = currentParentRoles.concat(newToAdd
                                        .filter(newRole => !currentParentRoles.find(existingRole => existingRole.id === newRole.id!)));
                                    await roles.updateParentRoles(lastRole?.id as number, parentRoles);
                                    await roles.showParentRolesForGivenRole(lastRole?.id as number, true);
                                    dialogController.closeModal().andWeAreDone();
                                },
                                successMessage: tAsString('ROLE_ADDITION_SUCCESS', {
                                    count: newToAdd.length
                                })
                            });
                        } else {
                            await dialogController.showNotification({
                                status: MessageStatus.WARNING,
                                content: tAsString('SELECT_SOME_OPTIONS')
                            });
                        }
                    }
                }
            ]
        });
    };

    const onRemove = async () => {
        if (props.removeRolesMode && lastRole) {
            const parentRolesToRemove = props.checkedRows?.map(id => Number(id)) ?? [];
            if (parentRolesToRemove.length) {
                await dialogController.takeNotifiedAction({
                    action: async () => {
                        const parentRoles: PartialParentRole[] = currentParentRoles.filter(role => !parentRolesToRemove.includes(role.id!));
                        await roles.updateParentRoles(lastRole?.id as number, parentRoles);
                        await roles.showParentRolesForGivenRole(lastRole?.id as number, true);
                        props.setRemoveRolesMode?.(false);
                    },
                    successMessage: tAsString('ROLE_REMOVING_SUCCESS', {
                        count: parentRolesToRemove.length
                    })
                });
            } else {
                await dialogController.showNotification({
                    status: MessageStatus.WARNING,
                    content: tAsString('SELECT_SOME_OPTIONS')
                });
            }
        } else {
            props.setRemoveRolesMode?.(true);
        }
    };

    const onEdit = async () => {
        if (props.editRolesMode && lastRole) {
            await dialogController.takeNotifiedAction({
                action: async () => {
                    currentParentRoles.map(async (role) => {
                        const roleFromDb = await roles.getParentRoleFromDb(role.id!);
                        role.enabled = roleFromDb
                            ? roleFromDb?.enabled
                            : true;
                    });
                    await roles.updateParentRoles(lastRole?.id as number, currentParentRoles);
                    await roles.showParentRolesForGivenRole(lastRole?.id as number, true);
                    props.setEditRolesMode?.(false);
                },
                successMessage: tAsString('ROLE_EDITING_SUCCESS')
            });
        } else {
            props.setEditRolesMode?.(true);
        }
    };

    let toolbarButtons: ButtonConfig[] = [];

    if (breadcrumbs.length) {
        toolbarButtons = [
            ((props.removeRolesMode && props.setRemoveRolesMode)
            || (props.editRolesMode && props.setEditRolesMode) ? {
                id: 'qa-cancel-button',
                onClick: () => {
                    props.setRemoveRolesMode?.(false);
                    props.setEditRolesMode?.(false);
                },
                size: ButtonSize.SMALL,
                secondary: true,
                children: tAsString('CANCEL')
            } : {
                id: 'qa-add-button',
                onClick: onAdd,
                children: tAsString('ADD'),
                ariaLabel: tAsString('ADD_AGGREGATED_ROLES'),
                textOnly: true
            })
        ];
        if ((data.length || isBusy) && !props.removeRolesMode) {
            toolbarButtons.push({
                id: props.editRolesMode
                    ? 'qa-save-button'
                    : 'qa-edit-button',
                onClick: onEdit,
                children: props.editRolesMode
                    ? tAsString('SAVE')
                    : tAsString('EDIT'),
                size: ButtonSize.SMALL,
                ariaLabel: props.editRolesMode
                    ? tAsString('SAVE_CHANGES')
                    : tAsString('EDIT_AGGREGATED_ROLES'),
                textOnly: !props.editRolesMode
            });
        }
        if ((data.length || isBusy) && !props.editRolesMode) {
            toolbarButtons.push({
                id: 'qa-remove-button',
                onClick: onRemove,
                children: tAsString('REMOVE'),
                size: ButtonSize.SMALL,
                ariaLabel: tAsString('REMOVE_AGGREGATED_ROLES'),
                textOnly: !props.removeRolesMode
            });
        }
    } else if (!props.addAggregatedRolesMode) {
        toolbarButtons = [
            {
                id: 'qa-create-button',
                onClick: () => {
                    rolesWitcher.resetCreateWitcher();
                    http.navigateTo('roles/create').andWeAreDone();
                },
                children: tAsString('CREATE'),
                ariaLabel: tAsString('CREATE_ROLE'),
                textOnly: true
            }
        ];
    }

    const searchFormElement = (
        <div className={'row'}>
            <div className={styles.InputsWrapper}>
                <SelectInput
                    className={cssClasses(styles.SelectInput, 'qa-search-select-input')}
                    label={tAsString('TYPE')}
                    name={'search_select_input'}
                    options={[
                        {
                            label: tAsString('ROLE_NAME'),
                            value: SearchType.ROLE_NAME
                        },
                        {
                            label: tAsString('ROLE_ID'),
                            value: SearchType.ROLE_ID
                        }
                    ]}
                    value={type}
                    onChange={(e, value): void => setType(value)}
                />
                <TextInput
                    className={cssClasses(styles.TextInput, 'qa-search-text-input')}
                    name={'search_text_input'}
                    label={tAsString('SEARCH')}
                    value={searchValue}
                    onChange={(e, value): void => setSearchValue(value)}
                    onKeyDown={(e) => e.key === 'Enter' && executeSearch()}
                    maxLength={100}
                >
                    {closeButton}
                </TextInput>
            </div>
            <div className={styles.SearchButtonWrapper}>
                <Button
                    className={cssClasses(styles.SearchButton, 'qa-search-button')}
                    textOnly={true}
                    onClick={executeSearch}
                >
                    {tAsString('SEARCH')}
                </Button>
            </div>
        </div>
    );

    const breadcrumbsElement = breadcrumbs.length
        ? <span className={styles.Breadcrumbs}>{breadcrumbs}</span>
        : <>{tAsString('ROOT')}</>;

    return (<>
        {searchFormElement}
        <Toolbar className={styles.Toolbar} contentClassName={'row'} content={breadcrumbsElement}
                 buttons={toolbarButtons}/>
    </>);
};