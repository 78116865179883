import React from 'react';
import {DefaultWizardHelpers} from '../../../../../../common/components/wizards/DefaultWizardHelpers';
import {getRedAppPlacementValue} from './getRedAppPlacementValue';
import {isDefined} from '../../../../../../../../common/types/guards/isDefined';
import {RxGroupProduct} from '../../../../../../../../common/model/db/types/RxGroupProduct';
import {asInt} from '../../../../../../../../common/helpers/converters/asInt';
import {ensure} from '../../../../../../../../common/types/guards/ensure';
import {isRecordOf} from '../../../../../../../../common/types/guards/isRecordOf';
import {isProductState} from '../../../../../../../../common/model/types/products/isProductState';

export function getRedAppPlacementInputCellHelpers(row: RxGroupProduct, wh: DefaultWizardHelpers) {
    const productId = asInt(row.product_ref?.id);
    const initialValue = getRedAppPlacementValue(row, wh);
    const products = wh.stepData?.products ?? {};
    ensure(isRecordOf(isProductState), products);

    const updateWizardData = async (value: string, setName: string = 'products') => {

        if (isDefined(productId)) {
            let data = {
                id: productId,
                type: row.product_ref?.type,
                redAppPlacement: value,
                redAppConfiguration: products[productId]?.redAppConfiguration ?? row.redAppConfiguration,
                productEnablement: products[productId]?.productEnablement ?? row.product_enablement
            };

            await wh.setStepMember([setName, productId], data);
        }
    };

    const onChange = async (e: React.ChangeEvent<HTMLElement>, v: string) => {
        await updateWizardData(v);
    };

    return {
        productId,
        initialValue,
        onChange,
        updateWizardData
    };
}
