import {Step, StepIndicator} from '@sabre/spark-react-core';
import {StepIndicatorType} from '@sabre/spark-react-core/types';
import {default as React} from 'react';
import {KEY} from '../../../../../common/helpers/id/KEY';
import {HashWizardHelpers} from './HashWizardHelpers';
import LoadingBlock from '../../placeholders/loading/LoadingBlock';
import {cssClasses} from '../../../../../common/helpers/browser/cssClasses';
import {tAsString} from '../../../../../common/helpers/react/text/tAsString';

export type HashWizardStepIndicatorProps = {
    wizardHelpers: Pick<HashWizardHelpers, 'wizardData' | 'stepIndex' | 'goToStep' | 'isLoading' | 'isEditing' | 'stepName'>
}

export default function HashWizardStepIndicator(props: HashWizardStepIndicatorProps) {
    const wizardHelpers = props.wizardHelpers;
    const isLoading = wizardHelpers.isLoading;

    if (isLoading) {
        return <LoadingBlock height={134}/>;
    }

    const steps = wizardHelpers.wizardData?.steps;

    if (!steps || steps.length < 3) {
        // StepIndicator requires at least 3 steps... (do not know why)
        console.error(`StepIndicator requires at least 3 steps!`);
        return <></>;
    }

    const completedSteps = steps
        .map((it, idx) => {
            return props.wizardHelpers.isEditing || idx < wizardHelpers.stepIndex
                ? idx
                : -1;
        })
        .map(it => it + 1); // for StepIndicator component

    return (
        <>
            <StepIndicator
                activeStep={props.wizardHelpers.stepIndex + 1}
                type={StepIndicatorType.STANDARD_DROPDOWN}
                completedSteps={completedSteps}
                className={cssClasses(
                    'spark-mar-b-2 spark-pad-b-1 spark-pad-t-1'
                )}
                onChange={(e, step) => wizardHelpers.goToStep(step - 1)}
            >
                {
                    steps.map(it => {
                        return <Step
                            key={KEY(it)}
                            label={tAsString(it.label)}
                            disabled={it.disabled}
                        />;
                    })
                }
            </StepIndicator>
        </>
    );
}
