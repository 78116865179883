import {QxDbOptions} from './QxDbOptions';
import {QxCollection} from './QxCollection';
import {mapEntries} from '../../../objects/mapEntries';
import {Once} from '../../../../decorators/methods/Once';
import {asString} from '../../../converters/asString';
import {QxCollections} from './QxCollections';

export class QxDb<TCollections extends QxCollections<TCollections>> {
    private constructor(private readonly options: QxDbOptions) {
        const collectionProvider = this as any;

        this.#forEachCollection(it => {
            collectionProvider[it.name] ??= it;
        });
    }

    get collections(): TCollections {
        return this.getCollections();
    }

    static create<TCollections extends QxCollections<TCollections>>(
        options: QxDbOptions
    ): QxDb<TCollections> & TCollections {
        return new QxDb(options) as any;
    }

    async destroy(): Promise<boolean> {
        this.#destroyCollections();
        return true;
    }

    @Once()
    private getCollections(): TCollections {
        return mapEntries(this.options.collections, pair => [
            pair[0],
            new QxCollection({
                name: asString(pair[0]),
                jsonSchema: pair[1].schema,
                db: this,
                dataThrottling: this.options.dataThrottling
            })
        ]) as TCollections;
    }

    #destroyCollections(): void {
        this.options.collections = {};

        this.#forEachCollection(it => {
            it.overwrite([]);
        });
    }

    #forEachCollection(forEachFn: (it: QxCollection<unknown>) => void): void {
        const collections = this.collections;

        for (let i in collections) {
            forEachFn(collections[i]!);
        }
    }
}
