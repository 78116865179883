import React, {useState} from 'react';
import {useSubtitle} from '../../../../../../common/helpers/react/hooks/useSubtitle';
import DeleteGroupsTable from './DeleteGroupsTable';
import {tAsString} from '../../../../../../common/helpers/react/text/tAsString';
import {useControllers} from '../../../../../../common/helpers/react/hooks/useControllers';
import {useOnce} from '../../../../../../common/helpers/react/hooks/useOnce';
import {DeleteToolbar, DeleteToolbarType} from '../../../../common/components/toolbar/DeleteToolbar';

export default function DeleteGroupsPresenter() {
    useSubtitle(tAsString('MANAGE_GROUPS'));

    const [checkedRows, setCheckedRows] = useState<string[]>([]);
    const groups = useControllers().groups;

    useOnce(() => groups.refreshAllGroups()).andWeAreDone();

    return (
        <>
            <h1>{tAsString('MANAGE_GROUPS')}</h1>
            <DeleteToolbar
                setCheckedRows={setCheckedRows}
                checkedRows={checkedRows}
                getData={() => groups.getAllGroups$().asState() ?? []}
                deleteByIds={listIds => groups.deleteGroupsByIds(listIds)}
                refresh={() => groups.refreshAllGroups()}
                type={DeleteToolbarType.GROUPS}
            />
            <DeleteGroupsTable
                checkedRows={checkedRows}
                setCheckedRows={setCheckedRows}
            />
        </>
    );
}
