import {useSubtitle} from '../../../../../../common/helpers/react/hooks/useSubtitle';
import GroupsToolbar from './GroupsToolbar';
import GroupsTable from './GroupsTable';
import {useControllers} from '../../../../../../common/helpers/react/hooks/useControllers';
import {useOnce} from '../../../../../../common/helpers/react/hooks/useOnce';
import {tAsString} from '../../../../../../common/helpers/react/text/tAsString';

export default function GroupsPresenter() {
    useSubtitle(tAsString('MANAGE_GROUPS'));

    const groups = useControllers().groups;

    useOnce(() => groups.refreshAllGroups())
        .andWeAreDone();

    return (
        <>
            <h1>{tAsString('MANAGE_GROUPS')}</h1>
            <GroupsToolbar/>
            <GroupsTable/>
        </>
    );
}
