import {tWithHtml} from '../../../../../../common/helpers/react/text/tWithHtml';
import {asGroup, Group} from '../../../../../../core/rest-client/aat';
import PopoverMenu from '../../../../common/components/popover-menu/PopoverMenu';
import {useControllers} from '../../../../../../common/helpers/react/hooks/useControllers';
import {RxGroup} from '../../../../../../common/model/db/types/RxGroup';
import styles from './GroupsTableRowMenu.module.css';
import {useI18Next} from '../../../../../../common/helpers/react/hooks/useI18Next';
import {isDefined} from '../../../../../../common/types/guards/isDefined';
import {ensure} from '../../../../../../common/types/guards/ensure';
import {ModalButtons} from '../../../../../../controllers/dialog/ModalButtons';

export type GroupsTableRowMenuPresenterProps = {
    row: RxGroup
}

export default function GroupsTableRowMenu(props: GroupsTableRowMenuPresenterProps) {
    const {t} = useI18Next();

    const controllers = useControllers();

    const dialogController = controllers.dialog;
    const groupsController = controllers.groups;
    const httpController = controllers.http;

    dialogController.useForceUpdate();

    const row = props.row;

    const showDeleteGroupModal = async () => {
        await dialogController.showModal({
            title: t('DELETE_GROUP'),
            children: tWithHtml('GROUP_X_WILL_BE_DELETED', {name: row.name}),
            buttons: [
                ModalButtons.CANCEL(dialogController),
                {
                    children: t('DELETE'),
                    ariaLabel: t('DELETE_X_GROUP', {name: row.name}),
                    className: 'qa-modal-delete-button',
                    onClick: () => {
                        const group = asGroup(row);
                        ensure<Group>(isDefined, group);

                        dialogController.takeNotifiedAction({
                            action: async () => {
                                await groupsController.deleteGroupsByIds([group.id]);
                            },
                            successMessage: t('THE_X_GROUP_HAS_BEEN_DELETED', {
                                name: group.name
                            }),
                            errorMessage: t('THE_X_GROUP_DELETION_FAILED', {
                                name: group.name
                            }),
                            finalizer: async () => {
                                await dialogController.closeModal();
                                await groupsController.refreshAllGroups();
                            }
                        });
                    }
                }
            ]
        });
    };

    return <PopoverMenu
        className={styles.GroupsTableRowMenu}
        icon={'menu-ellipsis-vertical'}
        options={[
            [
                {
                    className: 'qa-popover-edit-details-link',
                    caption: t('EDIT_DETAILS'),
                    ariaLabel: t('EDIT_X_GROUP_DETAILS', {name: row.name}),
                    onClick: () => httpController.navigateTo(`groups/edit/${row.id}/details.new`)
                },
                {
                    className: 'qa-popover-edit-agents-link',
                    caption: t('EDIT_ASSIGNEES'),
                    ariaLabel: t('EDIT_X_GROUP_ASSIGNEES', {name: row.name}),
                    onClick: () => httpController.navigateTo(`groups/edit/${row.id}/agents.new`)
                },
                {
                    className: 'qa-popover-edit-settings-link',
                    caption: t('EDIT_SETTINGS'),
                    ariaLabel: t('EDIT_X_GROUP_SETTINGS', {name: row.name}),
                    onClick: () => httpController.navigateTo(`groups/edit/${row.id}/settings.new`)
                }
            ],
            [
                {
                    className: 'qa-popover-delete-link',
                    caption: t('DELETE'),
                    ariaLabel: t('DELETE_X_GROUP', {name: row.name}),
                    onClick: showDeleteGroupModal
                }
            ]
        ]}
    />;
}
