/* tslint:disable */
/* eslint-disable */
/**
 * SR AAT specification
 * Description
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { GroupInfoUsingAgentsDetailsInner } from './GroupInfoUsingAgentsDetailsInner';
import {
    GroupInfoUsingAgentsDetailsInnerFromJSON,
    GroupInfoUsingAgentsDetailsInnerFromJSONTyped,
    GroupInfoUsingAgentsDetailsInnerToJSON,
} from './GroupInfoUsingAgentsDetailsInner';

/**
 * 
 * @export
 * @interface GroupInfo
 */
export interface GroupInfo {
    /**
     * 
     * @type {Array<GroupInfoUsingAgentsDetailsInner>}
     * @memberof GroupInfo
     */
    usingAgentsDetails?: Array<GroupInfoUsingAgentsDetailsInner>;
}

/**
 * Check if a given object implements the GroupInfo interface.
 */
export function instanceOfGroupInfo(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function GroupInfoFromJSON(json: any): GroupInfo {
    return GroupInfoFromJSONTyped(json, false);
}

export function GroupInfoFromJSONTyped(json: any, ignoreDiscriminator: boolean): GroupInfo {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'usingAgentsDetails': !exists(json, 'usingAgentsDetails') ? undefined : ((json['usingAgentsDetails'] as Array<any>).map(GroupInfoUsingAgentsDetailsInnerFromJSON)),
    };
}

export function GroupInfoToJSON(value?: GroupInfo | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'usingAgentsDetails': value.usingAgentsDetails === undefined ? undefined : ((value.usingAgentsDetails as Array<any>).map(GroupInfoUsingAgentsDetailsInnerToJSON)),
    };
}

