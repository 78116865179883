import {Header} from '@sabre/spark-react-core';
import {RxMenuItem} from '../../../../common/model/db/types/RxMenuItem';
import {HeaderProps} from '@sabre/spark-react-core/header/header.types';
import {useMemo} from 'react';
import {useForceUpdateUntil} from '../../../../common/helpers/react/hooks/useForceUpdateUntil';
import {useRefByClassName} from '../../../../common/helpers/react/hooks/useRefByClassName';
import {asString} from '../../../../common/helpers/converters/asString';
import {asJson} from '../../../../common/helpers/converters/asJson';
import {JsonObject} from 'type-fest';
import {isCurrentHashBase} from '../../../../common/helpers/browser/isCurrentHashBase';
import {useI18Next} from '../../../../common/helpers/react/hooks/useI18Next';
import styles from './DefaultHeader.module.css';
import {cssClasses} from '../../../../common/helpers/browser/cssClasses';

export type DefaultHeaderProps = {
    title?: string;
    titleLink?: string;
    menuItems?: RxMenuItem[];
    activeHash?: string;
    userBlock?: HeaderProps['userBlock'];
    onClick?: (item: RxMenuItem) => void
};

function useSpaceHandler(props: DefaultHeaderProps) {
    const headerRef = useRefByClassName<HTMLElement>();

    headerRef.useRef((el) => {
        el.addEventListener('keydown', (e: KeyboardEvent) => {
            if (e.code === 'Space') {
                if (e.target instanceof HTMLElement) {
                    e.preventDefault();
                    e.stopPropagation();

                    const menuItem = JSON.parse(e.target.id);
                    props.onClick?.(menuItem);
                }
            }
        }, true);
    });

    return headerRef;
}

export default function DefaultHeader(props: DefaultHeaderProps) {
    const {t, lang} = useI18Next();

    const tick = useForceUpdateUntil(!!props.menuItems?.[0]?.link_ref);

    const navItems = useMemo(() => {
        return props.menuItems?.map((it) => (
            <Header.NavItem
                // id for passing data to space handler..
                id={asString({...(asJson(it) as JsonObject), link_ref: asJson(it.link_ref)})}
                key={it.label}
                label={t(it.label)}
                onClick={() => props.onClick?.(it)}
                aria-label={t(it.label)}
                role={'menuitem'}
                aria-current={isCurrentHashBase(it.link_ref?.url, props.activeHash) ? 'page' : undefined}
                isActive={isCurrentHashBase(it.link_ref?.url, props.activeHash)}
                className={cssClasses(
                    styles.HeaderItem,
                    `qa-menu-item`,
                    `qa-menu-item--${it.label?.toLowerCase()}`
                )}
            />
        ));
    }, [tick, t, props.onClick, props.menuItems, props.activeHash, lang]);

    const headerRef = useSpaceHandler(props);

    return (
        <Header
            title={props.title}
            titleLink={props.titleLink}
            condensed={true}
            fixed={true}
            userBlock={props.userBlock}
            navAriaLabel={'Main navigation bar'}
            className={headerRef.className}
        >
            <Header.Nav>
                {navItems}
            </Header.Nav>
        </Header>
    );
}
