import {isDefined} from '../../types/guards/isDefined';

export function asBooleanWithDefaultValue(defaultValue?: boolean): (o?: unknown) => boolean {
    return (o?: unknown): boolean => {
        if (typeof o === 'string') {
            switch (o.toLowerCase()) {
                case 'true':
                case 't':
                case 'yes':
                case 'y':
                case '1':
                    o = true;
                    break;
                case 'false':
                case 'f':
                case 'no':
                case 'n':
                case '0':
                    o = false;
                    break;
                default:
                    o = undefined;
            }
        }

        if (typeof o === 'boolean') {
            return o;
        }

        return isDefined(o)
            ? !!o
            : typeof defaultValue === 'boolean'
                ? defaultValue
                : false;
    };
}
