import {asString} from '../converters/asString';
import {getAssigneeId} from '../../model/types/assignees/getAssigneeId';

export function getDbId(o?: unknown): string {
    const json: any = o ?? {};

    const candidate = json.id
        ?? json.name
        ?? json.label
        ?? getAssigneeId(json);

    // Operator || is here to pacify empty strings
    return asString(candidate || o);
}
