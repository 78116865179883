/* tslint:disable */
/* eslint-disable */
/**
 * SR AAT specification
 * Description
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const RedAppPlacementType = {
    AppsPanel: 'apps-panel',
    Sidebar: 'sidebar'
} as const;
export type RedAppPlacementType = typeof RedAppPlacementType[keyof typeof RedAppPlacementType];


export function RedAppPlacementTypeFromJSON(json: any): RedAppPlacementType {
    return RedAppPlacementTypeFromJSONTyped(json, false);
}

export function RedAppPlacementTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): RedAppPlacementType {
    return json as RedAppPlacementType;
}

export function RedAppPlacementTypeToJSON(value?: RedAppPlacementType | null): any {
    return value as any;
}

