import {Service} from '../Service';
import {upsertInto} from '../../../common/helpers/db/upsertInto';
import {asRxConfigurationArray} from '../../../common/model/db/types/RxConfiguration';
import {asNumber} from '../../../common/helpers/converters/asNumber';
import {QxAction} from '../../../common/helpers/rxjs/qx/db/QxAction';

export class ConfigurationsService extends Service {

    async deleteConfigurationsByIds(listIds: Array<number>) {
        const configurationsApi = await this.services.aat.Configurations;
        const response = await configurationsApi.deleteConfigurations({
            requestBody: listIds
        });

        this.checkStatus(response);

        this.db.configurations.modify(it => {
            return listIds.includes(asNumber(it.id)) ? QxAction.DELETE : QxAction.NOTHING;
        });
    }

    async getConfigurations(): Promise<void> {
        const configurationsApi = await this.services.aat.Configurations;
        const configurations = await configurationsApi.getConfigurations();

        this.checkStatus(configurations);

        await upsertInto(this.db.configurations, asRxConfigurationArray(configurations.configurations));
    }

    async createConfiguration(redappName: string, configurationName: string, value: string, description: string): Promise<void> {
        const configurationsApi = await this.services.aat.Configurations;
        const configuration = await configurationsApi.createConfiguration({
            createConfigurationRequest: {
                redappName,
                configurationName,
                value,
                description
            }
        });

        this.checkStatus(configuration);
    }

    async updateConfiguration(id: number, configurationName: string, value: string, description: string): Promise<void> {
        const configurationsApi = await this.services.aat.Configurations;
        const configuration = await configurationsApi.updateConfiguration({
            id,
            updateConfigurationRequest: {
                configurationName,
                value,
                description
            }
        });

        this.checkStatus(configuration);
    }
}