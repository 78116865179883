import {getCookie, removeCookie} from 'typescript-cookie';
import {CookiesConfig} from './CookiesConfig';
import {Service} from '../Service';
import {Once} from '../../../common/decorators/methods/Once';
import {filter, map, Observable} from 'rxjs';
import {distinctLikeABoss} from '../../../common/helpers/rxjs/distinctLikeABoss';
import {Cookies} from './Cookies';
import {ObservableWithValue} from '../../../common/types/rxjs/ObservableWithValue';

export class CookiesService extends Service {
    @Once()
    getCookiesConfig$(): ObservableWithValue<CookiesConfig> {
        return this.controllers.settings.getAllSettings$()
            .pipe(
                filter((settings): settings is CookiesConfig => {
                    return !!settings.cookie_wam
                        && !!settings.cookie_atk;
                }),
                map(it => {
                    return {
                        cookie_wam: it.cookie_wam,
                        cookie_atk: it.cookie_atk
                    };
                }),
                distinctLikeABoss()
            )
            .asObservableWithValue({
                cookie_wam: '',
                cookie_atk: ''
            });
    }

    @Once()
    getCookies$(): Observable<Cookies> {
        return this.getCookiesConfig$()
            .pipe(
                // for external changes
                // tickingLikeABoss(SECOND),
                map(config => {
                    return {
                        WAM: getCookie(config.cookie_wam) ?? '',
                        ATK: getCookie(config.cookie_atk) ?? ''
                    };
                }),
                distinctLikeABoss()
            );
    }

    removeATKCookie(): void {
        this.#removeCookie(this.getCookiesConfig$().value.cookie_atk);
    }

    removeWAMCookie(): void {
        this.#removeCookie(this.getCookiesConfig$().value.cookie_wam);
    }

    removeSMSESSIONCookie(): void {
        this.#removeCookie('SMSESSION');
    }

    removeSessionCookies(): void {
        this.removeATKCookie();
        this.removeSMSESSIONCookie();
    }

    protected override init(): void {
        super.init();
        this.removeSMSESSIONCookie();
    }

    #removeCookie(cookieName: string): void {
        removeCookie(cookieName, {domain: '.sabre.com', path: '/'});
        removeCookie(cookieName, {domain: document.location.hostname, path: '/'});
    }
}
