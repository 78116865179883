/* tslint:disable */
/* eslint-disable */
/**
 * SR AAT specification
 * Description
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * 
 * @export
 */
export const GroupType = {
    User: 'USER',
    Pcc: 'PCC',
    Agency: 'AGENCY'
} as const;
export type GroupType = typeof GroupType[keyof typeof GroupType];


export function GroupTypeFromJSON(json: any): GroupType {
    return GroupTypeFromJSONTyped(json, false);
}

export function GroupTypeFromJSONTyped(json: any, ignoreDiscriminator: boolean): GroupType {
    return json as GroupType;
}

export function GroupTypeToJSON(value?: GroupType | null): any {
    return value as any;
}

