import {Service} from '../Service';
import {upsertInto} from '../../../common/helpers/db/upsertInto';
import {asRxRedappArray} from '../../../common/model/db/types/RxRedapp';

export class RedAppsService extends Service {

    async getAllRedApps(): Promise<void> {
        const redAppsApi = await this.services.aat.RedApps;
        const redApps = await redAppsApi.getAllRedAppsNames();

        this.checkStatus(redApps);

        await upsertInto(this.db.redApps, asRxRedappArray(redApps.redApps));
    }
}