import {HashWizardHelpers} from '../../../../common/components/wizards/HashWizardHelpers';
import {Panel} from '@sabre/spark-react-core';
import HashWizardStepIndicator from '../../../../common/components/wizards/HashWizardStepIndicator';
import GroupWizardActionPanel from './GroupWizardActionPanel';
import {default as React, PropsWithChildren} from 'react';
import {useSubtitle} from '../../../../../../common/helpers/react/hooks/useSubtitle';
import {useGroupWizardTitle} from './useGroupWizardTitle';
import {RxGroup} from "../../../../../../common/model/db/types/RxGroup";

export type GroupWizardStepTemplateProps = PropsWithChildren & {
    wizardHelpers: HashWizardHelpers
    group?: RxGroup;
}

export default function GroupWizardStepTemplate(props: GroupWizardStepTemplateProps) {
    const title = useGroupWizardTitle(props.wizardHelpers.isEditing);

    useSubtitle(title);

    return (
        <>
            <h1>{title}</h1>
            <Panel>
                {<HashWizardStepIndicator wizardHelpers={props.wizardHelpers}/>}
                {props.children}
            </Panel>
            <GroupWizardActionPanel wizardHelpers={props.wizardHelpers} group={props.group}/>
        </>
    );
}
