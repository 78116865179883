import {LayoutController} from '../controllers/layout/LayoutController';
import {SettingsController} from '../controllers/settings/SettingsController';
import {AuthController} from '../controllers/auth/AuthController';
import {GroupsController} from '../controllers/groups/GroupsController';
import {HttpController} from '../core/services/http/HttpController';
import {Config_common} from '../common/core/entry-point/Config_common';
import {DialogController} from '../controllers/dialog/DialogController';
import {ConfigurationsController} from '../controllers/configurations/ConfigurationsController';
import {RedAppsController} from '../controllers/redApps/RedAppsController';
import {RolesController} from "../controllers/roles/RolesController";
import {RolesWitcherController} from "../controllers/roles/RolesWitcherController";

export const controllers: Partial<Config_common['controllers']> = {
    dialog: new DialogController(),
    http: new HttpController(),
    layout: new LayoutController(),
    settings: new SettingsController(),
    auth: new AuthController(),
    groups: new GroupsController(),
    configurations: new ConfigurationsController(),
    redApps: new RedAppsController(),
    roles: new RolesController(),
    rolesWitcher: new RolesWitcherController()
};
