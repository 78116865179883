import {useEffect} from 'react';
import {stopEvent} from '../../../browser/stopEvent';

export type AutokeyConfig = {
    key?: string,
    code?: string,
    ctrlKey?: boolean,
    altKey?: boolean,
    shiftKey?: boolean,
    metaKey?: boolean,
    modKey?: boolean
}

export type AutokeyHandler = (e: KeyboardEvent) => void;

const enterPreSelector = getSelector({key: 'Enter'}, true);
const spacePreSelector = getSelector({code: 'Space'}, true);

const cancelPreSelector = getSelector({key: 'Escape'}, true);
const submitPreSelector = getSelector({key: 'Enter', modKey: true}, true);
const goBackPreSelector = getSelector({key: 'Enter', modKey: true, shiftKey: true}, true);
const isMac = window.navigator.userAgent.includes('(Mac');

const allHandlers: Record<string, AutokeyHandler[]> = {};

export function useAutokey(key: AutokeyConfig = {}, handler?: AutokeyHandler) {
    const selector = getSelector(key);

    if (!allHandlers[selector]) {
        allHandlers[selector] = [];
    }

    useEffect(() => {
        if (handler) {
            allHandlers[selector]!.push(handler);
        }

        return () => {
            if (handler) {
                allHandlers[selector]!.pop();
            }
        };
    });
}

function getSelector(key: AutokeyConfig, preselector: boolean = false): string {
    let prefix = '.aat-autokey-';

    if (key.ctrlKey) prefix += 'ctrl-';
    if (key.altKey) prefix += 'alt-';
    if (key.shiftKey) prefix += 'shift-';
    if (key.metaKey) prefix += 'meta-';
    if (key.modKey) prefix += 'mod-';

    const selectors = [];

    if (key.key?.trim().length) {
        selectors.push(`${prefix}${key.key?.toLowerCase()}${preselector ? '-first' : ''}`);
    }

    if (key.code?.trim().length && key.code !== key.key) {
        selectors.push(`${prefix}${key.code?.toLowerCase()}${preselector ? '-first' : ''}`);
    }

    const selectorsAsString = selectors
        .map(it => it.replace(/[^a-z0-9]+/gi, '-'))
        .map(it => it.replace(/^-/, '.'))
        .join(',')
        .trim();

    return selectorsAsString || `${prefix}autocomplete${preselector ? '-first' : ''}`;
}

let handlerSet = false;

if (!handlerSet) {
    handlerSet = true;

    document.addEventListener('keydown', e => {
        const autokeyConfig: AutokeyConfig = {
            key: e.key,
            code: e.code,
            ctrlKey: isMac ? e.ctrlKey : false,
            altKey: e.altKey,
            shiftKey: e.shiftKey,
            metaKey: isMac ? false : e.metaKey,
            modKey: isMac ? e.metaKey : e.ctrlKey
        };

        const preSelector = getSelector(autokeyConfig, true);

        if (preSelector === enterPreSelector || preSelector === spacePreSelector) {
            const targetAsHtmlElement = e.target as HTMLElement;

            if (!['INPUT'].includes(targetAsHtmlElement.tagName)
                && !['TEXTAREA'].includes(targetAsHtmlElement.tagName)) {
                if (clicked(e, targetAsHtmlElement)) return;
            }
        }

        if (preSelector === cancelPreSelector) {
            // if Escape, let Popovers handle event
            const activePopoverSelector = '.spark-popover__content.active';
            const activePopover = document.querySelector(activePopoverSelector);

            if (activePopover) {
                return;
            }
        }

        if (clicked(e, preSelector)) return;

        const selector = getSelector(autokeyConfig);

        if (allHandlers[selector]?.length) {
            // if handler registered, execute it
            allHandlers[selector]![0]!(e);
            return;
        }

        if (clicked(e, selector)) return;

        if (preSelector === goBackPreSelector) {
            // if Ctrl/Cmd+Escape, go back by default
            stopEvent(e);
            window.history.back();
            return;
        }

        if (preSelector === cancelPreSelector) {
            // if Escape, close modal by default
            if (clicked(e, '.spark-modal__close.spark-icon-close')) return;

            // if Escape, click cancel button by default
            if (clicked(e, `.qa-cancel-button`)) return;
        }

        if (preSelector === submitPreSelector) {
            // if Ctrl/Cmd+Enter, click primary button by default
            if (clicked(e, `.qa-submit-button,.spark-btn:not(.spark-btn--secondary):not(.spark-btn--text)`)) return;
        }
    }, true);
}

function clicked(e: Event, element: Element | null | undefined | string): boolean {
    if (typeof element === 'string') {
        element = document.querySelector(element);
    }

    if (element instanceof HTMLElement) {
        stopEvent(e);
        element.focus();
        element.click();
        return true;
    }

    return false;
}
