/* tslint:disable */
/* eslint-disable */
/**
 * SR AAT specification
 * Description
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { PartialRole } from './PartialRole';
import {
    PartialRoleFromJSON,
    PartialRoleFromJSONTyped,
    PartialRoleToJSON,
} from './PartialRole';
import type { Status } from './Status';
import {
    StatusFromJSON,
    StatusFromJSONTyped,
    StatusToJSON,
} from './Status';
import type { StatusCodes } from './StatusCodes';
import {
    StatusCodesFromJSON,
    StatusCodesFromJSONTyped,
    StatusCodesToJSON,
} from './StatusCodes';

/**
 * 
 * @export
 * @interface SearchRoleResponse
 */
export interface SearchRoleResponse extends Status {
    /**
     * 
     * @type {Array<PartialRole>}
     * @memberof SearchRoleResponse
     */
    roles: Array<PartialRole>;
}

/**
 * Check if a given object implements the SearchRoleResponse interface.
 */
export function instanceOfSearchRoleResponse(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "roles" in value;

    return isInstance;
}

export function SearchRoleResponseFromJSON(json: any): SearchRoleResponse {
    return SearchRoleResponseFromJSONTyped(json, false);
}

export function SearchRoleResponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): SearchRoleResponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        ...StatusFromJSONTyped(json, ignoreDiscriminator),
        'roles': ((json['roles'] as Array<any>).map(PartialRoleFromJSON)),
    };
}

export function SearchRoleResponseToJSON(value?: SearchRoleResponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        ...StatusToJSON(value),
        'roles': ((value.roles as Array<any>).map(PartialRoleToJSON)),
    };
}

