import {PartialToLevel2} from '../../types/utility/partial/PartialToLevel2';
import {Config_common} from './Config_common';
import {DatabaseService} from '../../../core/services/database/DatabaseService';
import {HttpService} from '../../../core/services/http/HttpService';
import {GroupsService} from '../../../core/services/groups/GroupsService';
import {AatApiService} from '../../../core/rest-client/aat';
import {CookiesService} from '../../../core/services/cookies/CookiesService';
import {SessionStorageService} from '../../../core/services/sessionStorage/SessionStorageService';
import {getMockedService} from '../../helpers/mocks/getMockedService';
import {ConfigurationsService} from '../../../core/services/configurations/ConfigurationsService';
import {RedAppsService} from '../../../core/services/redApps/RedAppsService';
import {RolesService} from "../../../core/services/roles/RolesService";

export class Config_core extends Config_common {
    readonly services = {
        http: getMockedService<HttpService>(),
        groups: getMockedService<GroupsService>(),
        configurations: getMockedService<ConfigurationsService>(),
        redApps: getMockedService<RedAppsService>(),
        aat: getMockedService<AatApiService>(),
        cookies: getMockedService<CookiesService>(),
        database: getMockedService<DatabaseService>(),
        sessionStorage: getMockedService<SessionStorageService>(),
        roles: getMockedService<RolesService>()
    } as const;

    static override create(...cfg: PartialToLevel2<Config_core>[]): Config_core {
        return cfg.reduce<Config_core>((result, next) => {
            return {
                controllers: {
                    ...result.controllers,
                    ...next.controllers
                },
                services: {
                    ...result.services,
                    ...next.services
                }
            };
        }, new Config_core());
    }
}
