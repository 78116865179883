import {useMemo} from 'react';
import Toolbar from '../../../common/components/toolbar/Toolbar';
import {tAsString} from '../../../../../common/helpers/react/text/tAsString';
import {useControllers} from '../../../../../common/helpers/react/hooks/useControllers';
import {ButtonConfig} from '../../../../../controllers/dialog/ButtonConfig';
import {useI18Next} from '../../../../../common/helpers/react/hooks/useI18Next';

export const ConfigurationToolbar = () => {
    const {lang} = useI18Next();
    const controllers = useControllers();
    const configurationsController = controllers.configurations;
    const http = controllers.http;

    const toolbarButtons: ButtonConfig[] = useMemo(() => [
        {
            id: 'qa-create-button',
            onClick: () => http.navigateTo('configurations/create'),
            children: tAsString('CREATE'),
            textOnly: true
        },
        {
            id: 'qa-delete-button',
            onClick: () => http.navigateTo('configurations/delete'),
            children: tAsString('DELETE'),
            textOnly: true
        }
    ], [lang]);

    const configurationsCount = configurationsController.getConfigurations$().asState()?.length ?? 0;
    const toolbarContent = `${tAsString('TOTAL_CONFIGURATIONS')}: ${configurationsCount}`;

    return <Toolbar
        content={toolbarContent}
        buttons={toolbarButtons}
    />;
};