/* tslint:disable */
/* eslint-disable */
/**
 * SR AAT specification
 * Description
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { GroupInfo } from './GroupInfo';
import {
    GroupInfoFromJSON,
    GroupInfoFromJSONTyped,
    GroupInfoToJSON,
} from './GroupInfo';
import type { Status } from './Status';
import {
    StatusFromJSON,
    StatusFromJSONTyped,
    StatusToJSON,
} from './Status';
import type { StatusCodes } from './StatusCodes';
import {
    StatusCodesFromJSON,
    StatusCodesFromJSONTyped,
    StatusCodesToJSON,
} from './StatusCodes';

/**
 * 
 * @export
 * @interface GroupSettingsIdsReponse
 */
export interface GroupSettingsIdsReponse extends Status {
    /**
     * 
     * @type {GroupInfo}
     * @memberof GroupSettingsIdsReponse
     */
    workspace?: GroupInfo;
}

/**
 * Check if a given object implements the GroupSettingsIdsReponse interface.
 */
export function instanceOfGroupSettingsIdsReponse(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function GroupSettingsIdsReponseFromJSON(json: any): GroupSettingsIdsReponse {
    return GroupSettingsIdsReponseFromJSONTyped(json, false);
}

export function GroupSettingsIdsReponseFromJSONTyped(json: any, ignoreDiscriminator: boolean): GroupSettingsIdsReponse {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        ...StatusFromJSONTyped(json, ignoreDiscriminator),
        'workspace': !exists(json, 'workspace') ? undefined : GroupInfoFromJSON(json['workspace']),
    };
}

export function GroupSettingsIdsReponseToJSON(value?: GroupSettingsIdsReponse | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        ...StatusToJSON(value),
        'workspace': GroupInfoToJSON(value.workspace),
    };
}

