import {useControllers} from '../../../../../common/helpers/react/hooks/useControllers';
import {useConfigurationsTableColumns} from './useConfigurationsTableColumns';
import DefaultTable from '../../../common/components/table/DefaultTable';

export const ConfigurationsTable = () => {
    const configurations = useControllers().configurations;
    const redApps = useControllers().redApps;
    const columns = useConfigurationsTableColumns();
    const redappsData = redApps.getRedApps$().asState() ?? [];
    const configurationsData = configurations.getConfigurations$().asState() ?? [];
    const data = configurationsData.map(configuration => ({
        ...configuration,
        redapp_name: redappsData.find(redapp => redapp.iu_name === configuration.redapp_name)?.name ?? configuration.redapp_name
    }));
    const isBusy = configurations.isBusy$().asState() !== false;

    return <DefaultTable
        columns={columns}
        data={data}
        isBusy={isBusy}
    />
};