import {AppContext} from './AppContext';
import HtmlHeadPresenter from './app/HtmlHeadPresenter';
import {StrictMode} from 'react';
import {HashRouter} from 'react-router-dom';
import HtmlBodyPresenter from './app/HtmlBodyPresenter';
import {Config_common} from '../../common/core/entry-point/Config_common';

export type AppPresenterProps = {
    cfg: Config_common
}

export default function AppPresenter(props: AppPresenterProps) {
    return (
        <AppContext.Provider value={props.cfg}>
            <HtmlHeadPresenter/>
            <StrictMode>
                <HashRouter>
                    <HtmlBodyPresenter/>
                </HashRouter>
            </StrictMode>
        </AppContext.Provider>
    );
}
