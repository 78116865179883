/* tslint:disable */
/* eslint-disable */
/**
 * SR AAT specification
 * Description
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { Group } from './Group';
import {
    GroupFromJSON,
    GroupFromJSONTyped,
    GroupToJSON,
} from './Group';
import type { Pcc } from './Pcc';
import {
    PccFromJSON,
    PccFromJSONTyped,
    PccToJSON,
} from './Pcc';

/**
 * 
 * @export
 * @interface Agent
 */
export interface Agent {
    /**
     * 
     * @type {number}
     * @memberof Agent
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof Agent
     */
    sabreId: string;
    /**
     * 
     * @type {string}
     * @memberof Agent
     */
    name: string;
    /**
     * 
     * @type {Pcc}
     * @memberof Agent
     */
    pcc?: Pcc;
    /**
     * 
     * @type {Array<Group>}
     * @memberof Agent
     */
    groups?: Array<Group>;
}

/**
 * Check if a given object implements the Agent interface.
 */
export function instanceOfAgent(value: object): boolean {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "sabreId" in value;
    isInstance = isInstance && "name" in value;

    return isInstance;
}

export function AgentFromJSON(json: any): Agent {
    return AgentFromJSONTyped(json, false);
}

export function AgentFromJSONTyped(json: any, ignoreDiscriminator: boolean): Agent {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': json['id'],
        'sabreId': json['sabreId'],
        'name': json['name'],
        'pcc': !exists(json, 'pcc') ? undefined : PccFromJSON(json['pcc']),
        'groups': !exists(json, 'groups') ? undefined : ((json['groups'] as Array<any>).map(GroupFromJSON)),
    };
}

export function AgentToJSON(value?: Agent | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'sabreId': value.sabreId,
        'name': value.name,
        'pcc': PccToJSON(value.pcc),
        'groups': value.groups === undefined ? undefined : ((value.groups as Array<any>).map(GroupToJSON)),
    };
}

